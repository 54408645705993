import React, { useContext, useEffect, useState } from 'react';
import Icon from '@ant-design/icons';
import { useTransactionImports } from 'pages/TransactionImports/services/useTransactionImports';
import { DropMenu } from 'components/DropMenu/DropMenu';
import { useFileUploadModal } from 'elements/Modals/FileUploadModal/useFileUploadModal';
import FileUploadModal from 'elements/Modals/FileUploadModal/FileUploadModal';
import Button from '../../../../elements/Buttons/Button/Button';
import { ICONS } from '../../../../components/icons';
import BankConnectionUpdateModal from './components/BankConnectionUpdateModal';
import './BankConnectionActions.scss';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { translations } from '../../translations';
import BankConnectionImportModal from './components/BankConnectionImportModal';

export const BankConnectionActions = ({ finapiEnabled }: { finapiEnabled: boolean }) => {
  const { tl } = useContext(LanguageContext);
  const [bankConnectionUpdateModalVisible, setBankConnectionUpdateModalVisible] = useState(false);
  const [bankConnectionImportModalVisible, setImportConnectionModalVisible] = useState(false);
  const { upload, loading } = useTransactionImports();

  const importTransactionsModal = useFileUploadModal({
    onUpload: upload,
    accept: 'text/xml',
    name: 'import-transactions',
    body: tl(translations.fileUploadModal.body),
    title: tl(translations.fileUploadModal.title),
    upload: tl(translations.fileUploadModal.upload),
    multiple: true,
  });

  if (finapiEnabled) {
    return (
      <div className="BankConnectionActions">
        <DropMenu
          options={[{
            label: `${tl(translations.upload)} xml`,
            onClick: () => importTransactionsModal.showModal(),
          }]}
          disabled={false}
        >
          <Icon component={ICONS.menuDots} />
        </DropMenu>
        <Button
          className="header-element"
          onClick={() => setImportConnectionModalVisible(true)}
        >
          {tl(translations.bankConnectionActions.addConnection)}
        </Button>
        <Button
          className="header-element"
          onClick={() => setBankConnectionUpdateModalVisible(true)}
          type="primary"
        >
          {tl(translations.bankConnectionActions.synchronize)}
          <Icon component={ICONS.refresh} />
        </Button>
        <BankConnectionImportModal
          visible={bankConnectionImportModalVisible}
          onClose={() => setImportConnectionModalVisible(false)}
        />

        <BankConnectionUpdateModal
          visible={bankConnectionUpdateModalVisible}
          onClose={() => setBankConnectionUpdateModalVisible(false)}
        />
        <FileUploadModal {...importTransactionsModal} />
      </div>
    );
  }

  return (
    <div className="BankConnectionActions">
      <Button
        type="primary"
        onClick={importTransactionsModal.showModal}
        loading={loading}
      >
        {tl(translations.upload)}
      </Button>
      <FileUploadModal {...importTransactionsModal} />
    </div>
  );
};
