import { AccountDistributionKeyControllerApi, AccountDistributionKeyDto, PropertyLegacyDtoAdministrationTypeEnum } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { PropertyDistributionKeyListContext } from 'contexts/PropertyDistributionKeyListContext';
import { useContext, useMemo } from 'react';
import { showNotification } from 'lib/Notification';
import { compareAccountCodes } from 'lib/Utils';
import { PropertyListContext } from 'contexts/PropertyListContext';
import { DistributionKeyContext } from 'contexts/DistributionKeyContext';
import { useLocation, useParams } from 'react-router';
import { DISTRIBUTION_FOR_OPS_COST } from 'pages/DistributionKeys/AccountDistributionEditor/services/utils';
import { isEmpty } from 'lodash';
import { translations } from '../../../elements/Translation/translations';

const sortRecursivelyByAccountCode = (data: any[]) => {
  data?.sort((a, b) => compareAccountCodes(a.accountCode, b.accountCode));

  data?.forEach((item) => {
    if (item.children) {
      sortRecursivelyByAccountCode(item.children);
    }
  });

  return data;
};


export const usePropertyDistributionKeysTable = () => {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const accountDistributionKeyControllerApi = new AccountDistributionKeyControllerApi(apiConfiguration('accounting'));
  const { setPropertyDistributionKeyListState, propertyDistributionKeyListState, selectedEconomicYear }:any = useContext(PropertyDistributionKeyListContext);
  const { selectedProperty }: any = useContext(PropertyListContext);


  const { setSelectedEconomicYear }: any = useContext(DistributionKeyContext);

  const { economicYear } = useParams<{ economicYear: string }>();
  const economicYearValue = economicYear ? parseInt(economicYear, 2) : selectedEconomicYear;

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);

  const isForOpsCost = urlSearchParams.get('distributionFor') === DISTRIBUTION_FOR_OPS_COST;

  const isWeg = selectedProperty?.data?.administrationType === 'WEG';
  const getAccountDistributionKey = (accountCode:string, year: string, propertyIdParam: number, wkaRelevantValue: boolean) => accountDistributionKeyControllerApi.getAccountDistributionKeyByYearUsingGET({ accountCode, year, propertyId: propertyIdParam })
    .then(accountDistributionKey => Promise.resolve({ accountDistributionKey, wkaRelevantValue }));

  const upadteDistributionKey = ({ accountDistributionKey, wkaRelevantValue } :{accountDistributionKey: AccountDistributionKeyDto, wkaRelevantValue:boolean}) => accountDistributionKeyControllerApi.updateAccountDistributionKeyUsingPUT({ dto: { ...accountDistributionKey, wkaRelevant: wkaRelevantValue } })
    .then(updatedAdk => Promise.resolve(updatedAdk));

  const changeWkaRelevantFlagInList = (accountCode: string, tableKey: string, economicYearVal: number, wkaRelevantPrevValue: boolean, propertyId:number) => {
    setPropertyDistributionKeyListState(prev => prev.startLoading());
    getAccountDistributionKey(accountCode, economicYearVal.toString(), propertyId, !wkaRelevantPrevValue)
      .then(upadteDistributionKey)
      .then((adk) => {
        setPropertyDistributionKeyListState((oldPropertyDistributionKeyListState) => {
          const newTableKeyListnewTableKeyList = oldPropertyDistributionKeyListState.data[tableKey].map((oldAdk) => {
            if (oldAdk.accountCode === adk.accountCode) {
              return { ...oldAdk, wkaRelevant: adk.wkaRelevant };
            }
            return oldAdk;
          });

          return oldPropertyDistributionKeyListState.load({ ...oldPropertyDistributionKeyListState.data, [tableKey]: newTableKeyListnewTableKeyList });
        });
      })
      .catch((err) => {
        console.error(err);
        setPropertyDistributionKeyListState(prev => prev?.finishLoading());
        showNotification({
          key: 'saveAccountDistributionKeyError',
          message: tl(translations.notifications.distributionKeyContext.saveError.message),
          type: 'error',
        });
      });
  };

  const isAccountWkaRelevant = (data: any) => data.wkaRelevant ?? false;

  const toTableData = (administrationType: PropertyLegacyDtoAdministrationTypeEnum, data: any): any => {
    const newData = {
      ...data,
      economicPlan: data.economicPlan ? tl(translations.enums.distributionMode[data.economicPlan]) : '',
      houseMoneySettlement: data.houseMoneySettlement ? tl(translations.enums.distributionMode[data.houseMoneySettlement]) : '',
      wkaRelevant: isAccountWkaRelevant(data),
      economicYear: economicYearValue,
    };

    if (newData.children) {
      newData.wkaRelevant = undefined; // do not show for parents
      newData.children = newData.children.map(c => toTableData(administrationType, c));
    }

    return newData;
  };


  const incomeTableData = useMemo(() => (
    !isEmpty(propertyDistributionKeyListState.data)
      ? sortRecursivelyByAccountCode(propertyDistributionKeyListState.data?.income?.map(data => toTableData(selectedProperty.data?.administrationType, data)))
      : []
  ), [propertyDistributionKeyListState.data?.income]);


  const expensesTableData = useMemo(() => (
    !isEmpty(propertyDistributionKeyListState.data)
      ? sortRecursivelyByAccountCode(propertyDistributionKeyListState.data?.expenses?.map(data => toTableData(selectedProperty.data?.administrationType, data)))
      : []
  ), [propertyDistributionKeyListState.data?.expenses]);

  const reserveFundsTableData = useMemo(() => (
    !isEmpty(propertyDistributionKeyListState.data)
      ? sortRecursivelyByAccountCode(propertyDistributionKeyListState.data?.reserveFunds?.map(data => toTableData(selectedProperty.data?.administrationType, data)))
      : []
  ), [propertyDistributionKeyListState.data?.reserveFunds]);


  return {
    changeWkaRelevantFlagInList,
    incomeTableData,
    expensesTableData,
    reserveFundsTableData,
    isWeg,
    isForOpsCost,
    selectedEconomicYear: economicYearValue,
    propertyDistributionKeyListState,
    setSelectedEconomicYear,
  };
};
