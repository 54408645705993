import { ReportDocumentCover } from 'storybook-components/ReportDocumentCover/ReportDocumentCover';
import { useOpsCostReportDocumentCover as hook } from './services/useOpsCostReportDocumentCover';


type ReturnTypeOfHook = ReturnType<typeof hook>;
type ReturnTypeOfReportDocumentCover = ReturnType<ReturnTypeOfHook['getReportDocumnetCoverProps']>

interface OpsCostReportDocumentCoverProps {
  useReportDocumentCover: ReturnTypeOfReportDocumentCover
}


export const OpsCostReportDocumentCover = ({
  useReportDocumentCover,
}: OpsCostReportDocumentCoverProps) => {
  const {
    managementCompanyName,
    managementCompanyAddress,
    recipientAddressLines,
    additionalData,
    title,
    reportInfo,
    introductionText,
  } = useReportDocumentCover;
  return (
    <ReportDocumentCover
      managementCompanyName={managementCompanyName}
      managementCompanyAddress={managementCompanyAddress}
      recipientAddressLines={recipientAddressLines}
      additionalData={additionalData}
      title={title}
      reportInfo={reportInfo}
      introductionText={introductionText}
    />
  );
};
