import React, {
  useContext,
} from 'react';
import './DKCopyModal.scss';
import { Button } from 'antd';
import { ICONS } from 'components/icons';
import Icon from '@ant-design/icons';
import EconomicYearPicker from 'elements/Inputs/EconomicYearPicker/EconomicYearPicker';
import { LanguageContext } from 'contexts/LanguageContext';
import { useModal } from '../../../../elements/Modals/useModal/useModal';
import { DKCopyModal } from './DKCopyModal';
import { PropertyListContext } from '../../../../contexts/PropertyListContext';
import { PropertyDistributionKeyListContext } from '../../../../contexts/PropertyDistributionKeyListContext';
import { formatDate } from '../../../../lib/Utils';
import { translations } from '../../../../elements/Translation/translations';
import './EconomicYearPickerWithModalOption.scss';

interface EconomicYearPickerWithModalOptionProps {
    selectedEconomicYear: number,
    setSelectedEconomicYear: Function,
    yearsWithDistributionKeys: number[],
}

export function EconomicYearPickerWithModalOption({ selectedEconomicYear, setSelectedEconomicYear, yearsWithDistributionKeys }: EconomicYearPickerWithModalOptionProps) {
  const { tl } = useContext(LanguageContext);
  const { selectedProperty }: any = useContext(PropertyListContext);
  const { copyDKs }: any = useContext(PropertyDistributionKeyListContext);

  const economicYearStart = selectedProperty.data ? selectedProperty.data.economicYearStart : null;
  const economicYearEnd = selectedProperty.data ? selectedProperty.data.economicYearEnd : null;

  const copyDKModal = useModal({
    onOk: copyDKs,
  });

  function generateEconomicYearRange(year: number, yearStart: Date, yearEnd: Date) {
    // TODO refactor to remove this in future stories for EP/ADK
    const fullYearStart = new Date(yearStart);
    const fullYearEnd = new Date(yearEnd);
    fullYearStart.setFullYear(year);
    fullYearEnd.setFullYear(year);
    if (fullYearEnd < fullYearStart) {
      fullYearEnd.setFullYear(year + 1);
    }
    return {
      value: year,
      label: `${formatDate(fullYearStart)} — ${formatDate(fullYearEnd)}`,
    };
  }

  const formattedYearValues = yearsWithDistributionKeys!.map((yearValue: any) => generateEconomicYearRange(yearValue, economicYearStart, economicYearEnd));


  const dropdownRender = (item: any) => (
    <div className="EconomicYearSelectorDropdownRender">
      <div className="economic-year-selector-dropdown-buttons">
        <Button
          size="middle"
          icon={<Icon component={ICONS.plus} />}
          onClick={copyDKModal.showModal}
        >
          {tl(translations.elements.economicYearPicker.addNew)}
        </Button>
      </div>
      {item}
    </div>
  );

  return (
    <div className="button-container">
      <EconomicYearPicker
        type="label"
        value={selectedEconomicYear!}
        valuesList={formattedYearValues}
        onChange={setSelectedEconomicYear as (value: number) => void}
        economicYearStart={economicYearStart}
        economicYearEnd={economicYearEnd}
        dropdownRender={dropdownRender}
      />
      <div>
        <DKCopyModal
          {...copyDKModal}
          onClickOk={copyDKs}
          sourceYears={formattedYearValues}
          selectedProperty={selectedProperty}
          selectedSourceYear={selectedEconomicYear}
          economicYearStart={economicYearStart}
          economicYearEnd={economicYearEnd}
        />
      </div>
    </div>
  );
}
