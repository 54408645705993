/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DomainCredentials,
  DomainDto,
  DomainEpo,
  DomainRegisterDto,
} from '../models';
import {
    DomainCredentialsFromJSON,
    DomainCredentialsToJSON,
    DomainDtoFromJSON,
    DomainDtoToJSON,
    DomainEpoFromJSON,
    DomainEpoToJSON,
    DomainRegisterDtoFromJSON,
    DomainRegisterDtoToJSON,
} from '../models';

export interface CreateDomainUsingPOSTRequest {
    domainEpo: DomainEpo;
}

export interface RegisterDomainUsingPOSTRequest {
    domainRegisterDto: DomainRegisterDto;
}

export interface UpdateDomainNameUsingPUTRequest {
    oldDomainName: string;
    newDomainEpo: DomainEpo;
}

/**
 * 
 */
export class DomainControllerApi extends runtime.BaseAPI {

    /**
     * Allow an Impower internal user to create a new domain with support/demo/admin users.Please use the ADMIN OPERATIONS token from the password manager
     */
    async createDomainUsingPOSTRaw(requestParameters: CreateDomainUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DomainCredentials>> {
        if (requestParameters.domainEpo === null || requestParameters.domainEpo === undefined) {
            throw new runtime.RequiredError('domainEpo','Required parameter requestParameters.domainEpo was null or undefined when calling createDomainUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/domains`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DomainEpoToJSON(requestParameters.domainEpo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DomainCredentialsFromJSON(jsonValue));
    }

    /**
     * Allow an Impower internal user to create a new domain with support/demo/admin users.Please use the ADMIN OPERATIONS token from the password manager
     */
    async createDomainUsingPOST(requestParameters: CreateDomainUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DomainCredentials> {
        const response = await this.createDomainUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Allow an unregistered user to register a new domain and create a user within it.The domain will be created with additional support/demo/admin users.All of this will be reported to impower slack.
     */
    async registerDomainUsingPOSTRaw(requestParameters: RegisterDomainUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.domainRegisterDto === null || requestParameters.domainRegisterDto === undefined) {
            throw new runtime.RequiredError('domainRegisterDto','Required parameter requestParameters.domainRegisterDto was null or undefined when calling registerDomainUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/domains/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DomainRegisterDtoToJSON(requestParameters.domainRegisterDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Allow an unregistered user to register a new domain and create a user within it.The domain will be created with additional support/demo/admin users.All of this will be reported to impower slack.
     */
    async registerDomainUsingPOST(requestParameters: RegisterDomainUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.registerDomainUsingPOSTRaw(requestParameters, initOverrides);
    }

    /**
     * updateDomainName
     */
    async updateDomainNameUsingPUTRaw(requestParameters: UpdateDomainNameUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DomainDto>> {
        if (requestParameters.oldDomainName === null || requestParameters.oldDomainName === undefined) {
            throw new runtime.RequiredError('oldDomainName','Required parameter requestParameters.oldDomainName was null or undefined when calling updateDomainNameUsingPUT.');
        }

        if (requestParameters.newDomainEpo === null || requestParameters.newDomainEpo === undefined) {
            throw new runtime.RequiredError('newDomainEpo','Required parameter requestParameters.newDomainEpo was null or undefined when calling updateDomainNameUsingPUT.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/domains/{oldDomainName}`.replace(`{${"oldDomainName"}}`, encodeURIComponent(String(requestParameters.oldDomainName))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DomainEpoToJSON(requestParameters.newDomainEpo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DomainDtoFromJSON(jsonValue));
    }

    /**
     * updateDomainName
     */
    async updateDomainNameUsingPUT(requestParameters: UpdateDomainNameUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DomainDto> {
        const response = await this.updateDomainNameUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
