import React, { useContext } from 'react';

import { Alert } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import {
  ProgressBar,
} from 'storybook-components/feedback/ProgressBar/ProgressBar';
import {
  SectionWrapper,
} from 'storybook-components/sections/SectionWrapper/SectionWrapper';

import {
  FileUploadButton,
} from '../components/FileUploadButton/FileUploadButton';
import { translations } from './translations';
import { useRentPlansImportCsv } from './useRentPlansImportCsv';

export const RentPlansImportSection = () => {
  const { tl } = useContext(LanguageContext);
  const {
    percentage, dataValidationIssues, loadCsvFile, propertiesLoaded, importRPs, rpProgress, unitsRPData, alertDetails,
  } = useRentPlansImportCsv();
  const showImport = dataValidationIssues.length < 1 && unitsRPData.length > 0 && propertiesLoaded;

  return (
    <SectionWrapper
      sectionNumber={6}
      sectionTitle={tl(translations.title)}
      sectionId="rp-import"
    >
      {!rpProgress.started ? (
        <>
          <FileUploadButton
            label={tl(translations.upload)}
            accept="text/csv"
            onChange={loadCsvFile}
          />
          {/* @ts-ignore */}
          <Alert message={alertDetails.message} type={alertDetails.type} />
          {
            dataValidationIssues.length > 0 && (
              <Alert
                message={tl(translations.validations.errors)}
                type="error"
                description={(
                  <ul>
                    {
                      dataValidationIssues.map(issue => (
                        <li key={issue.row + issue.message}>
                          {`${issue.row + 1}: ${issue.message}`}
                        </li>
                      ))
                    }
                  </ul>
                )}
              />
            ) }
          {showImport && (<Button onClick={() => importRPs()} type="primary">{tl(translations.import.button)}</Button>)}
        </>
      ) : (
        <ProgressBar
          title={tl(translations.import.title)}
          description={tl(translations.import.description)}
          data={[{
            label: tl(translations.title),
            percentage,
          }]}
        />
      )}
    </SectionWrapper>

  );
};
