import {
  AccountDistributionKeyControllerApi, AccountDistributionKeyTreeDto, AccountTypeDtoAccountTypeEnum, DistributionSetControllerApi,
  DistributionSetDto,
  DistributionSetDtoDistributionModeEnum,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { PropertyDistributionKeyListContext } from 'contexts/PropertyDistributionKeyListContext';
import { showNotification } from 'lib/Notification';
import { renameKeyRecursively } from 'lib/Utils';
import { useContext, useRef } from 'react';
import { isEmpty, uniq } from 'lodash';
import { useLocation } from 'react-router';
import { DISTRIBUTION_FOR_OPS_COST } from 'pages/DistributionKeys/AccountDistributionEditor/services/utils';
import { translations } from '../translations';


export const useLoadPropertyDistributionKeyList = () => {
  const { tl } = useContext(LanguageContext);
  const { apiConfiguration } = useContext(AuthContext);
  const propertyDistributionKeyListContext: any = useContext(PropertyDistributionKeyListContext);
  const {
    selectedEconomicYear, setPropertyId, propertyId, setPropertyDistributionKeyListState, propertyDistributionKeyListState,
  } = propertyDistributionKeyListContext;
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const distributionFor = urlSearchParams.get('distributionFor');


  const distributionSetControllerApi = new DistributionSetControllerApi(apiConfiguration('accounting'));
  const accountDistributionKeyControllerApi = new AccountDistributionKeyControllerApi(apiConfiguration('accounting'));
  const accountDistributionKeyAbortController = useRef<AbortController | undefined>(undefined);

  const onGetAccountDistributionKeys = (selectedPropertyId) => {
    // if params changed since last initiated fetch then abort the in-progress fetch
    accountDistributionKeyAbortController.current?.abort();
    // create new abort controller
    accountDistributionKeyAbortController.current = new AbortController();
    const { signal } = accountDistributionKeyAbortController.current;

    return accountDistributionKeyControllerApi.getAccountDistributionsForPropertyByEconomicYearInTreeStructureUsingGET({
      economicYear: selectedEconomicYear.toString(),
      propertyId: selectedPropertyId!,
    }, { signal })
      .then(response => Promise.resolve({ distributionKeys: response, signal }))
      .catch(((err) => {
        if (signal?.aborted) return;
        throw err;
      }));
  };

  const onGetDistributionKeyNameForOpsCost = (distributionSet: DistributionSetDto, adk: AccountDistributionKeyTreeDto) => {
    if (distributionSet && distributionSet.distributionMode === DistributionSetDtoDistributionModeEnum.DIRECT_COST) {
      return tl(translations.enums.distributionMode.DIRECT_COST);
    }

    if (distributionSet) {
      return distributionSet.name;
    }

    if (!adk.houseMoneySettlement) {
      return '';
    }

    const validDistributionKeyForOpsCost = [tl(translations.enums.distributionMode.AREA), tl(translations.enums.distributionMode.HEATING_AREA), tl(translations.enums.distributionMode.MEA), tl(translations.enums.distributionMode.PERSONS), tl(translations.enums.distributionMode.UNITS)];
    if (validDistributionKeyForOpsCost.includes(tl(translations.enums.distributionMode[adk.houseMoneySettlement])) && !adk.wkaRelevant) {
      if (adk.apply) {
        return tl(translations.enums.distributionMode[adk.houseMoneySettlement]);
      }

      return '—';
    }

    return tl(translations.enums.distributionMode.UNDEFINED);
  };

  const onGetDistributionSets = (distributionKeys: AccountDistributionKeyTreeDto[], distributionSetIds:number[], signal?: AbortSignal) => distributionSetControllerApi.getDistributionSetsUsingGET({ distributionSetIds }, { signal })
    .then(distributionSets => Promise.resolve({ accountDistributionKeys: distributionKeys, distributionSets }))
    .catch(((err) => {
      if (signal?.aborted) return;
      throw err;
    }));


  const getDitstributionSetIds = (adks: AccountDistributionKeyTreeDto[]) => adks?.map((distributionKey) => {
    const distributionSetIds = [];
    if (distributionKey.distributionSetId) {
      distributionSetIds.push(distributionKey.distributionSetId);
    }
    if (!isEmpty(distributionKey.subAccounts)) {
      return distributionSetIds.concat(getDitstributionSetIds(distributionKey.subAccounts)).flat();
    }
    return distributionSetIds;
  }).flat();

  const setOpsCostValueForADKs = (adks: AccountDistributionKeyTreeDto[], distributionSets:DistributionSetDto[]) => adks?.map((distributionKey) => {
    const distributionSet = distributionSets.find(ds => ds.id === distributionKey?.distributionSetId);
    let { subAccounts } = distributionKey;
    if (!isEmpty(subAccounts)) {
      subAccounts = setOpsCostValueForADKs(distributionKey.subAccounts, distributionSets);
    }

    return {
      ...distributionKey,
      subAccounts,
      unitsExcluded: distributionKey.unitSetId ? true : distributionKey.unitsExcluded,
      opsCost: onGetDistributionKeyNameForOpsCost(distributionSet, distributionKey),
      fromHga: !distributionKey.wkaRelevant && distributionKey.apply && distributionKey.distributionSetId === undefined && isEmpty(subAccounts),
    };
  });


  const onGetPropertyDistibutionKeysForOpsCost = (selectedPropertyId) => {
    onGetAccountDistributionKeys(selectedPropertyId)
      .then((resp:{distributionKeys:AccountDistributionKeyTreeDto[], signal:AbortSignal}) => {
        if (resp === undefined || isEmpty(resp?.distributionKeys)) {
          setPropertyDistributionKeyListState(state => state.load([]));
          return;
        }

        const distributionSetIds:number[] = uniq(getDitstributionSetIds(resp.distributionKeys));
        if (!isEmpty(distributionSetIds)) {
          onGetDistributionSets(resp.distributionKeys, distributionSetIds, resp.signal)
            .then((response:{accountDistributionKeys:AccountDistributionKeyTreeDto[], distributionSets:DistributionSetDto[]}) => {
              if (isEmpty(response.accountDistributionKeys) && isEmpty(response.distributionSets)) {
                setPropertyDistributionKeyListState(state => state.load([]));
                return;
              }

              const distributionKeysWithDistributionSetValue = setOpsCostValueForADKs(response?.accountDistributionKeys, response.distributionSets);
              setPropertyDistributionKeyListState(state => state.load(mapToPropertyAccountDistributionKeyListState(distributionKeysWithDistributionSetValue)));
            });
        } else {
          const distributionKeysWithOpsCost = setOpsCostValueForADKs(resp.distributionKeys, []);

          setPropertyDistributionKeyListState(state => state.load(mapToPropertyAccountDistributionKeyListState(distributionKeysWithOpsCost)));
        }
      }).catch(err => getErrorNotification(err));
  };


  const mapToPropertyAccountDistributionKeyListState = (distributionKeys: AccountDistributionKeyTreeDto[]) => {
    const income: any = distributionKeys?.filter((acc: any) => acc.accountType.accountType === AccountTypeDtoAccountTypeEnum.REVENUE);
    const expenses: any = distributionKeys?.filter((acc: any) => acc.accountType.accountType === AccountTypeDtoAccountTypeEnum.EXPENSE);
    const reserveFunds: any = distributionKeys?.filter((acc: any) => acc.accountType.accountType === AccountTypeDtoAccountTypeEnum.CAPITAL);
    return {
      income: renameKeyRecursively(income, 'subAccounts', 'children'),
      expenses: renameKeyRecursively(expenses, 'subAccounts', 'children'),
      reserveFunds: renameKeyRecursively(reserveFunds, 'subAccounts', 'children'),
    };
  };


  const getErrorNotification = (err) => {
    console.error(err);
    setPropertyDistributionKeyListState(state => state.failed());
    showNotification({
      key: 'loadDistributionKeyListError',
      message: tl(translations.propertyDistributionKeyListContext.loadError),
      type: 'error',
    });
  };

  const onLoadPropertyDistributionKeys = (id?: number) => {
    if (!selectedEconomicYear) {
      return;
    }
    let selectedPropertyId: number | null;
    if (id) {
      setPropertyId(id);
      selectedPropertyId = id;
    } else {
      selectedPropertyId = propertyId;
    }

    setPropertyDistributionKeyListState(propertyDistributionKeyListState.startLoading());

    if (distributionFor === DISTRIBUTION_FOR_OPS_COST) {
      onGetPropertyDistibutionKeysForOpsCost(selectedPropertyId);
      return;
    }

    onGetAccountDistributionKeys(selectedPropertyId)
      .then((response:{distributionKeys:AccountDistributionKeyTreeDto[], signal:AbortSignal}) => {
        if (isEmpty(response?.distributionKeys)) {
          setPropertyDistributionKeyListState(state => state.load([]));
          return;
        }
        setPropertyDistributionKeyListState(state => state.load(mapToPropertyAccountDistributionKeyListState(response.distributionKeys)));
      })
      .catch(err => getErrorNotification(err));
  };

  return { onLoadPropertyDistributionKeys };
};
