import { ColumnsType } from 'antd/lib/table';
import {
  AccountDistributionKeyDto, AccountDistributionKeyDtoHouseMoneySettlementEnum, ExtendedAccountBalanceDto, PropertyDisplayDtoVatRateCountryCodeEnum, PropertyDisplayDtoVatRelevanceEnum,
} from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { compareAccountCodes, formatCurrency } from 'lib/Utils';
import { PnlAccountBalances, ProfitAndLossReportContext } from 'pages/ProfitAndLossReport/ProfitAndLossReportEditing/services/ProfitAndLossReportContext';
import { useContext, useMemo } from 'react';
import { translations } from 'pages/ProfitAndLossReport/translations';
import { DefaultDataInterface } from 'lib/data';
import Amount from 'storybook-components/typography/Amount/Amount';
import _ from 'lodash';
import Big from 'big.js';
import CellWithTwoLines from 'storybook-components/table/CellWithTwoLines/CellWithTwoLines';


const COLUMN1_WIDTH = 200;
const COLUMN2_WIDTH = 400;

const sanitizeValue = (amt?: number) => ((!_.isNil(amt) && !Number.isNaN(amt)) ? amt : 0);

const calculateVatEligibilityPercentage = (vatAmount: number, vatEligibilityAmount: number) => {
  const vatAmountBig = new Big(sanitizeValue(vatAmount));
  const vatEligibilityAmountBig = new Big(sanitizeValue(vatEligibilityAmount));
  return vatAmountBig.toNumber() !== 0
    ? parseFloat(vatEligibilityAmountBig.times(new Big(100)).div(vatAmountBig).toFixed(2))
    : 0;
};

const getExpenseTableData = (accountBalances: DefaultDataInterface<PnlAccountBalances>, distributionKeys: DefaultDataInterface<AccountDistributionKeyDto[]>, condition: (dk: AccountDistributionKeyDto) => boolean) => {
  const getExpenseDataSource = () => {
    if (!accountBalances.loaded) return [];

    const matchingAccountCodes = distributionKeys.data?.filter(dk => (
      dk.accountCode.match((/^(?!895)8.*$/)) && condition(dk)
    )).map(dk => dk.accountCode);

    const ds = matchingAccountCodes.map(code => accountBalances.data[code])
      .filter(acc => acc?.leaf && acc?.normalizedBalanceWithinDateRange !== 0)
      .sort((a, b) => a.accountCode.localeCompare(b.accountCode));

    return ds.sort((a, b) => compareAccountCodes(a.accountCode, b.accountCode));
  };

  const expenseDataSource = getExpenseDataSource();
  const grossSum = expenseDataSource.reduce((acc, curr) => (acc + curr.normalizedBalanceWithinDateRange), 0);
  const vatSum = expenseDataSource.reduce((acc, curr) => (acc + curr.normalizedBalanceForVatAmountWithinDateRange), 0);
  const vatEligibilitySum = expenseDataSource.reduce((acc, curr) => (acc + curr.normalizedBalanceForVatEligibilityAmountWithinDateRange), 0);
  const netSum = grossSum - vatSum;
  const vatEligibilityPercentageTotal = calculateVatEligibilityPercentage(vatSum, vatEligibilitySum);

  return {
    expenseDataSource,
    grossSum,
    vatSum,
    netSum,
    vatEligibilitySum,
    vatEligibilityPercentageTotal,
  };
};

const applicableExpenseWkaCondition = dk => dk.wkaRelevant;
const otherApplicableExpenseCondition = dk => dk.apply && !dk.wkaRelevant;
const notApplicableExpenseCondition = dk => !dk.apply && !dk.wkaRelevant;

export const useExpenseTable = () => {
  const profitAndLossReportContext = useContext(ProfitAndLossReportContext);
  const { tl } = useContext(LanguageContext);

  if (profitAndLossReportContext === undefined) {
    throw new Error('useExpenseTable must be used within a ProfitAndLossReportContextProvider');
  }

  const { accountBalances, distributionKeys, reportData } = profitAndLossReportContext;

  const {
    expenseDataSource: applicableExpenseWkaDataSource,
    grossSum: applicableExpenseWkaSum,
    vatSum: applicableExpenseWkaVatSum,
    netSum: applicableExpenseWkaNetSum,
    vatEligibilitySum: applicableExpenseWkaVatEligibilitySum,
    vatEligibilityPercentageTotal: applicableExpenseWkaVatEligibilityPercentageTotal,
  } = useMemo(() => getExpenseTableData(accountBalances, distributionKeys, applicableExpenseWkaCondition), [accountBalances.data, distributionKeys.data]);

  const {
    expenseDataSource: otherApplicableExpenseDataSource,
    grossSum: otherApplicableExpenseSum,
    vatSum: otherApplicableExpenseVatSum,
    netSum: otherApplicableExpenseNetSum,
    vatEligibilitySum: otherApplicableExpenseVatEligibilitySum,
    vatEligibilityPercentageTotal: otherApplicableExpenseVatEligibilityPercentageTotal,
  } = useMemo(() => getExpenseTableData(accountBalances, distributionKeys, otherApplicableExpenseCondition), [accountBalances.data, distributionKeys.data]);

  const {
    expenseDataSource: notApplicableExpenseDataSource,
    grossSum: notApplicableExpenseSum,
    vatSum: notApplicableExpenseVatSum,
    netSum: notApplicableExpenseNetSum,
    vatEligibilitySum: notApplicableExpenseVatEligibilitySum,
    vatEligibilityPercentageTotal: notApplicableExpenseVatEligibilityPercentageTotal,
  } = useMemo(() => getExpenseTableData(accountBalances, distributionKeys, notApplicableExpenseCondition), [accountBalances.data, distributionKeys.data]);

  const vatRelevantGermanProperty = [PropertyDisplayDtoVatRelevanceEnum.FULLY_RELEVANT, PropertyDisplayDtoVatRelevanceEnum.PARTIALLY_RELEVANT]
    .includes(reportData.property?.vatRelevance) && reportData.property?.vatRateCountryCode === PropertyDisplayDtoVatRateCountryCodeEnum.DE;


  const columns: ColumnsType<ExtendedAccountBalanceDto> = [
    {
      title: tl(translations.report.sections.expenseSection.columns.accountCode),
      dataIndex: 'accountCode',
      width: COLUMN1_WIDTH,
    },
    {
      title: tl(translations.report.sections.expenseSection.columns.accountName),
      dataIndex: 'accountName',
      width: COLUMN2_WIDTH,
    },
    {
      title: tl(translations.report.sections.expenseSection.columns.currentPeriod),
      dataIndex: 'normalizedBalanceWithinDateRange',
      className: 'column-align-right no-wrap',
      render: (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>,
    },
  ];

  if (vatRelevantGermanProperty) {
    columns.splice(2, 0, {
      title: tl(translations.report.sections.expenseSection.columns.netAmount),
      dataIndex: 'normalizedNetBalanceWithinDateRange',
      className: 'column-align-right no-wrap',
      render: (_, record) => <Amount>{formatCurrency((record.normalizedBalanceWithinDateRange - record.normalizedBalanceForVatAmountWithinDateRange), '-', false)}</Amount>,
    });
    columns.splice(3, 0, {
      title: tl(translations.report.sections.expenseSection.columns.vat),
      dataIndex: 'normalizedBalanceForVatAmountWithinDateRange',
      className: 'column-align-right no-wrap',
      render: (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>,
    });
    columns.splice(4, 0, {
      title: tl(translations.report.sections.expenseSection.columns.eligibleVat),
      dataIndex: 'normalizedBalanceForEligibleVatAmountWithinDateRange',
      className: 'column-align-right no-wrap',
      render: (_, r: ExtendedAccountBalanceDto) => (
        <CellWithTwoLines
          firstElement={<Amount>{formatCurrency(r.normalizedBalanceForVatEligibilityAmountWithinDateRange, '-', false)}</Amount>}
          secondElement={`${calculateVatEligibilityPercentage(r.normalizedBalanceForVatAmountWithinDateRange, r.normalizedBalanceForVatEligibilityAmountWithinDateRange)}%`}
        />
      ),
    });
  }

  const totalDataSource = useMemo(() => {
    if (!accountBalances.loaded) return [];

    return [
      {
        label: tl(translations.report.sections.expenseSection.applicableExpensesWka),
        totalGross: applicableExpenseWkaSum,
        totalNet: applicableExpenseWkaNetSum,
        totalVat: applicableExpenseWkaVatSum,
        totalVatEligibility: applicableExpenseWkaVatEligibilitySum,
        totalVatEligibilityPercentage: applicableExpenseWkaVatEligibilityPercentageTotal,
      },
      {
        label: tl(translations.report.sections.expenseSection.otherApplicableExpenses),
        totalGross: otherApplicableExpenseSum,
        totalNet: otherApplicableExpenseNetSum,
        totalVat: otherApplicableExpenseVatSum,
        totalVatEligibility: otherApplicableExpenseVatEligibilitySum,
        totalVatEligibilityPercentage: otherApplicableExpenseVatEligibilityPercentageTotal,
      },
      {
        label: tl(translations.report.sections.expenseSection.notApplicableExpenses),
        totalGross: notApplicableExpenseSum,
        totalNet: notApplicableExpenseNetSum,
        totalVat: notApplicableExpenseVatSum,
        totalVatEligibility: notApplicableExpenseVatEligibilitySum,
        totalVatEligibilityPercentage: notApplicableExpenseVatEligibilityPercentageTotal,
      },
    ];
  }, [accountBalances.data]);

  const totalColumns: ColumnsType<(typeof totalDataSource)[number]> = [
    {
      title: tl(translations.report.sections.expenseSection.totalColumns.position),
      dataIndex: 'label',
      width: COLUMN1_WIDTH + COLUMN2_WIDTH,
    },
    {
      title: tl(translations.report.sections.expenseSection.totalColumns.totalAmount),
      dataIndex: 'totalGross',
      className: 'column-align-right no-wrap',
      render: (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>,
    },
  ];

  if (vatRelevantGermanProperty) {
    totalColumns.splice(1, 0, {
      title: tl(translations.report.sections.expenseSection.columns.netAmount),
      dataIndex: 'totalNet',
      className: 'column-align-right no-wrap',
      render: (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>,
    });
    totalColumns.splice(2, 0, {
      title: tl(translations.report.sections.expenseSection.columns.vat),
      dataIndex: 'totalVat',
      className: 'column-align-right no-wrap',
      render: (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>,
    });
    totalColumns.splice(3, 0, {
      title: tl(translations.report.sections.expenseSection.columns.eligibleVat),
      dataIndex: 'totalVatEligibility',
      className: 'column-align-right no-wrap',
      render: (_, r: (typeof totalDataSource)[number]) => (
        <CellWithTwoLines
          firstElement={<Amount>{formatCurrency(r.totalVatEligibility, '-', false)}</Amount>}
          secondElement={`${r.totalVatEligibilityPercentage}%`}
        />
      ),
    });
  }

  const totalSum = applicableExpenseWkaSum + otherApplicableExpenseSum + notApplicableExpenseSum;
  const totalNet = applicableExpenseWkaNetSum + otherApplicableExpenseNetSum + notApplicableExpenseNetSum;
  const totalVat = applicableExpenseWkaVatSum + otherApplicableExpenseVatSum + notApplicableExpenseVatSum;
  const totalVatEligibility = applicableExpenseWkaVatEligibilitySum + otherApplicableExpenseVatEligibilitySum + notApplicableExpenseVatEligibilitySum;
  const totalVateligibilityPercentage = calculateVatEligibilityPercentage(totalVat, totalVatEligibility);

  return {
    columns,
    applicableExpenseWkaDataSource,
    applicableExpenseWkaSum,
    applicableExpenseWkaNetSum,
    applicableExpenseWkaVatSum,
    applicableExpenseWkaVatEligibilitySum,
    applicableExpenseWkaVatEligibilityPercentageTotal,
    otherApplicableExpenseDataSource,
    otherApplicableExpenseSum,
    otherApplicableExpenseNetSum,
    otherApplicableExpenseVatSum,
    otherApplicableExpenseVatEligibilitySum,
    otherApplicableExpenseVatEligibilityPercentageTotal,
    notApplicableExpenseDataSource,
    notApplicableExpenseSum,
    notApplicableExpenseNetSum,
    notApplicableExpenseVatSum,
    notApplicableExpenseVatEligibilitySum,
    notApplicableExpenseVatEligibilityPercentageTotal,
    totalColumns,
    totalDataSource,
    totalSum,
    totalNet,
    totalVat,
    totalVatEligibility,
    totalVateligibilityPercentage,
    vatRelevantGermanProperty,
  };
};
