import {
  useContext,
  useMemo,
} from 'react';

import { ColumnsType } from 'antd/lib/table';
import { LanguageContext } from 'contexts/LanguageContext';
import { formatCurrency } from 'lib/Utils';
import {
  ProfitAndLossReportContext,
} from 'pages/ProfitAndLossReport/ProfitAndLossReportEditing/services/ProfitAndLossReportContext';
import { translations } from 'pages/ProfitAndLossReport/translations';
import Amount from 'storybook-components/typography/Amount/Amount';

import {
  useExpenseTable,
} from '../../ExpenseSection/components/useExpenseTable';
import {
  useOpenTenantAccountsTable,
} from '../../OpenTenantAccountSection/components/useOpenTenantAccountsTable';
import {
  usePropertyOwnerPaymentsTable,
} from '../../PropertyOwnerPaymentsSection/components/usePropertyOwnerPaymentsTable';
import {
  useIncomeTable,
} from '../../RentIncomeSection/components/IncomeTable/useIncomeTable';
import {
  useOtherIncomeTable,
} from '../../RentIncomeSection/components/OtherIncomeTable/useOtherIncomeTable';

export const useResultTable = () => {
  const profitAndLossReportContext = useContext(ProfitAndLossReportContext);
  const { tl } = useContext(LanguageContext);

  if (profitAndLossReportContext === undefined) {
    throw new Error('useResultTable must be used within a ProfitAndLossReportContextProvider');
  }

  const { accountBalances } = profitAndLossReportContext;

  // for now let's only memoize where we have to iterate through accountBalances.data;
  // and memoize the columns/sums only if we run into performance issues

  const { sum: rentIncomeTotal } = useIncomeTable();
  const { sum: otherIncomeTotal } = useOtherIncomeTable();
  const { totalSum: expenseTotal } = useExpenseTable();

  const firstDataSource = useMemo(() => {
    if (!accountBalances.loaded) return [];

    return [
      { label: tl(translations.report.sections.resultSection.rentIncomeTotal), value: rentIncomeTotal },
      { label: tl(translations.report.sections.resultSection.otherIncomeTotal), value: otherIncomeTotal },
      { label: tl(translations.report.sections.resultSection.expenseTotal), value: -expenseTotal },
    ];
  }, [accountBalances.data]);


  const firstSum = firstDataSource.reduce((acc, curr) => (acc + curr.value), 0);

  const { firstBalanceWithinDateRangeSum: balanceTenantWithinDateRange } = useOpenTenantAccountsTable();

  const secondDataSource = useMemo(() => {
    if (!accountBalances.loaded) return [];

    return [{ label: tl(translations.report.sections.resultSection.openTenantAmount), value: -balanceTenantWithinDateRange }];
  }, [accountBalances.data]);

  const secondPartSumValue = secondDataSource.reduce((acc, curr) => (acc + curr.value), 0);
  const secondSum = firstSum + secondPartSumValue;

  const { ownerPaymentsFromSum, ownerPaymentsToSum } = usePropertyOwnerPaymentsTable();
  const thirdDataSource = useMemo(() => {
    if (!accountBalances.loaded) return [];

    return [
      { label: tl(translations.report.sections.resultSection.deposits), value: ownerPaymentsFromSum },
      { label: tl(translations.report.sections.resultSection.withdrawals), value: ownerPaymentsToSum },
    ];
  }, [accountBalances.data]);

  const thirdPartSumValue = thirdDataSource.reduce((acc, curr) => (acc + curr.value), 0);

  const thirdSum = firstSum + secondPartSumValue + thirdPartSumValue;

  const fourthDataSource = useMemo(() => {
    if (!accountBalances.loaded) return [];
    let balances = Object.entries(accountBalances.data)
    // show all balance sheet accounts
      .filter(([accountCode, balance]) => /^(29|33|34|35|39|41|49)$/.test(accountCode) && balance.normalizedBalanceWithinDateRange !== 0)
      .map(([accountCode, balance]) => ({ label: balance.accountName, value: balance.normalizedBalanceWithinDateRange }));
    if (balances.length === 0) {
      balances = [{ label: tl(translations.report.sections.resultSection.noBalanceAccoutnBookings), value: 0 }];
    }
    return balances;
  }, [accountBalances.data]);

  const fourthPartSumValue = fourthDataSource.reduce((acc, curr) => (acc + curr.value), 0);

  const fourthSum = thirdSum + fourthPartSumValue;


  const columns: ColumnsType<{ label: string, value: number }> = [
    {
      title: tl(translations.report.sections.resultSection.columns.position),
      dataIndex: 'label',
      // width given otherwise the 3 tables below each other have different width
      // and the columns don't line up in the printed view
      width: '70%',
    },
    {
      title: tl(translations.report.sections.resultSection.columns.totalAmount),
      dataIndex: 'value',
      className: 'column-align-right no-wrap',
      render: (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>,
    },
  ];

  return {
    columns,
    firstDataSource,
    firstSum,
    secondDataSource,
    secondSum,
    thirdDataSource,
    thirdSum,
    fourthDataSource,
    fourthSum,
  };
};
