
import {
  PropertyCreationDto, PropertyCreationDtoAdministrationTypeEnum, PropertyLegacyDtoPropertyStateEnum,
} from 'api/accounting';
import backend, { endpointUrls } from 'backend_api';
import { CompanyEditingContext } from 'contexts/CompanyEditingContext';
import { convertToBEModel, convertToFEModel, processChanges } from 'contexts/functions/PropertyEditingFunctions';
import { LanguageContext } from 'contexts/LanguageContext';
import { PersonEditingContext } from 'contexts/PersonEditingContext';
import { PropertyListContext } from 'contexts/PropertyListContext';
import { getSectionErrors } from 'contexts/util/PropertySectionFieldsConfiguration';
import DEFAULT_DATA from 'lib/data';
import { NotificationObject, showNotification } from 'lib/Notification';
import { setValue } from 'lib/Utils';
import _ from 'lodash';
import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { translations } from '../../../../../elements/Translation/translations';
import { DEFAULT_PROPERTY_EDITING_CONTEXT_PROPERTY_DATA, PropertyEditingContext } from './PropertyEditingContext';

export const PROPERTY_EDITING_ROUTE_REGEX = new RegExp('/properties/(WEG|MV|SEV)\\d+/edit');

export const usePropertyEditing = () => {
  const propertyEditingContext = useContext(PropertyEditingContext);
  const companyEditingContext: any = useContext(CompanyEditingContext);
  const contactEditingContext: any = useContext(PersonEditingContext);

  if (propertyEditingContext === undefined) {
    throw new Error('usePropertyEditing must be used within a PropertyEditingProvider');
  }

  if (companyEditingContext === undefined) {
    throw new Error('usePropertyEditing must be used within a CompanyEditingProvider');
  }

  if (contactEditingContext === undefined) {
    throw new Error('usePropertyEditing must be used within a ContactEditingProvider');
  }


  const history = useHistory();
  const { tl } = useContext(LanguageContext);
  const { goBack } = useContext(OverlayContext);
  const { removeDeletedPropertyFromList, onUpdatePropertyInList } = useContext(PropertyListContext);

  const { search } = useLocation();
  const administrationType = new URLSearchParams(search).get('type') || 'WEG';

  const {
    setPropertyState,
    setIsValid,
    setDirty,
    data: { property: propertyState },
    loaded,
    ...rest
  } = propertyEditingContext;


  const validationWarningNotification: NotificationObject = {
    key: 'savePropertyValidationError',
    message: tl(translations.notifications.propertyEditingContext.saveValidationError.message),
    description: tl(translations.notifications.propertyEditingContext.saveValidationError.description),
    type: 'warning',
  };

  const updatePropertyState = (data: any, validationErrors?: any, saved?: boolean) => {
    setPropertyState((state: any) => {
      const tempProperty: any = processChanges(data.property, _.cloneDeep(state.data.property));
      return state.load({
        ...state.data,
        property: tempProperty,
      }, validationErrors || state.validationErrors, saved || false);
    });
  };

  const clearProperty = () => {
    setPropertyState(DEFAULT_DATA<any>(DEFAULT_PROPERTY_EDITING_CONTEXT_PROPERTY_DATA));
    setDirty(false);
  };

  const onLoadProperty = (propertyHrId: string): void => {
    setPropertyState(state => state.startLoading());
    backend.get(`${endpointUrls.PROPERTY}/${propertyHrId}`, {})
      .then((response: any) => {
        // Don't call updatePropertyState because we don't want `processChanges` to be called. Every needed change should be done by convertToFeModel
        setPropertyState((state: any) => state.load({
          ...state.data,
          property: convertToFEModel(response),
        }, {}, false));
      })
      .catch(() => {
        setPropertyState(state => state.failed());
        showNotification({
          key: 'loadPropertyError',
          message: tl(translations.notifications.propertyEditingContext.loadError.message),
          description: tl(translations.notifications.propertyEditingContext.loadError.description),
          type: 'error',
        });
      });
  };

  const onLoadPropertyById = (propertyId: string): void => {
    setPropertyState(state => state.startLoading());
    backend.get(`${endpointUrls.PROPERTY}`, { propertyId })
      .then((response: any) => {
        updatePropertyState({ property: convertToFEModel(response) }, {});
      })
      .catch(() => {
        setPropertyState(state => state.failed());
        showNotification({
          key: 'loadPropertyError',
          message: tl(translations.notifications.propertyEditingContext.loadError.message),
          description: tl(translations.notifications.propertyEditingContext.loadError.description),
          type: 'error',
        });
      });
  };

  const onValidateProperty = (propertyHrId: string) => backend.get(`${endpointUrls.PROPERTY}/${propertyHrId}/validate`, {})
    .then((r: any) => {
      setIsValid(r.valid);
      setPropertyState(prev => prev.load(prev.data, r.validationErrors));

      return r;
    })
    .catch(() => {
      showNotification({
        key: 'loadPropertyError',
        message: tl(translations.notifications.propertyEditingContext.loadError.message),
        description: tl(translations.notifications.propertyEditingContext.loadError.description),
        type: 'error',
      });
    });

  // when first subdividing an account show a warning that all its bookings will be moved to its subaccount
  function warnAccountSubdivision(property: any) {
    if (property.propertyState === PropertyLegacyDtoPropertyStateEnum.READY) {
      property.accounts.forEach((account: any) => {
        if (account.divisions.length > 0 && account.divisions.filter((acc: any) => !!acc.code).length === 0) {
          showNotification({
            message: tl(translations.notifications.propertyEditingContext.accountSubdivisionWarning.part1)
              + account.code
              + tl(translations.notifications.propertyEditingContext.accountSubdivisionWarning.part2),
            type: 'warning',
          });
        }
      });
    }
  }

  const onSaveProperty = (propertyHrId: string | undefined, savingSectionNumber: number, propertyData?: any, callback?: () => void, customSectionSubmit?: (id?: number) => void): Promise<void> => {
    if (propertyHrId) {
      if (customSectionSubmit !== undefined) {
        customSectionSubmit();
      } else {
        return onUpdateProperty(propertyHrId, propertyData, callback);
      }
    } else {
      return onCreateProperty(savingSectionNumber, callback, customSectionSubmit);
    }
    return Promise.resolve();
  };

  const onCreateProperty = (savingSectionNumber: number, callback?: (sectionErrors: any) => void, customSectionSubmit?: (id?: number) => void): Promise<void> => {
    setPropertyState(state => state.startLoading(true));

    const creationData: PropertyCreationDto = {
      administrationType: administrationType as PropertyCreationDtoAdministrationTypeEnum,
      name: propertyState.name,
      propertyIdInternal: propertyState.propertyIdInternal,
      city: propertyState.city,
      country: propertyState.country,
      number: propertyState.number,
      postalCode: propertyState.postalCode,
      state: propertyState.state,
      street: propertyState.street,
    };

    const errorNotification: NotificationObject = {
      key: 'savePropertyError',
      message: tl(translations.notifications.propertyEditingContext.saveError.message),
      description: tl(translations.notifications.propertyEditingContext.saveError.description),
      type: 'error',
    };

    const duplicateNameErrorNotification = (name: string) => ({
      key: 'duplicateNameError',
      message: tl(translations.notifications.propertyEditingContext.saveError.message),
      description: tl(translations.notifications.propertyEditingContext.duplicateNameError)(name),
      type: 'error',
    }) as NotificationObject;

    return backend.post(`${endpointUrls.PROPERTY}`, { ...creationData })
      .then((response) => {
        const property = convertToFEModel(response);
        history.replace(`/properties/${property.propertyHrId}/edit`, { openSectionNumber: savingSectionNumber });
        onUpdateProperty(property.propertyHrId, {
          ...propertyState,
          id: property.id,
          propertyHrId: property.propertyHrId,
          administrationType: property.administrationType,
          propertyState: property.propertyState,
        }, callback);

        if (customSectionSubmit !== undefined) {
          customSectionSubmit(property.id);
        }
      }).catch((e) => {
        const duplicateNameError = e.title?.includes('Property name must be unique');
        showNotification(duplicateNameError ? duplicateNameErrorNotification(e.detail) : errorNotification);
        setPropertyState(state => state.failed());
      });
  };

  const onUpdateProperty = (propertyHrId: string, propertyData?: any, callback?: (sectionErrors: any) => void): Promise<void> => {
    if (!propertyState.loading) setPropertyState(state => state.startLoading(true));

    let requestData = {
      propertyHrId,
      ...convertToBEModel(propertyState, tl),
    };
    if (propertyData) {
      requestData = {
        ...convertToBEModel(propertyData, tl),
      };
    }
    warnAccountSubdivision(requestData);
    const successNotification: NotificationObject = {
      message: tl(translations.notifications.propertyEditingContext.saveSuccess.message),
      type: 'success',
    };
    const errorNotification: NotificationObject = {
      key: 'savePropertyError',
      message: tl(translations.notifications.propertyEditingContext.saveError.message),
      description: tl(translations.notifications.propertyEditingContext.saveError.description),
      type: 'error',
    };

    const duplicateNameErrorNotification = (name: string) => ({
      key: 'duplicateNameError',
      message: tl(translations.notifications.propertyEditingContext.saveError.message),
      description: tl(translations.notifications.propertyEditingContext.duplicateNameError)(name),
      type: 'error',
    }) as NotificationObject;

    let currentSectionErrors;
    return backend.put(`${endpointUrls.PROPERTY}`, requestData).then((response) => {
      updatePropertyState({ property: convertToFEModel(response) }, {}, true);
      onUpdatePropertyInList(response);
      showNotification(successNotification);
      setDirty(false);
    }).catch((res) => {
      if (res.title === 'Validation error') {
        currentSectionErrors = getSectionErrors(propertyState, res);
        onUpdatePropertyInList({
          ...res.savedEntity,
          propertyAddress: {
            street: res.savedEntity.street,
            number: res.savedEntity.number,
            city: res.savedEntity.city,
            postalCode: res.savedEntity.postalCode,
            country: res.savedEntity.country,
          },
        });
        setDirty(false);
        updatePropertyState({ property: convertToFEModel(res.savedEntity) }, null, true);
        if (propertyState.propertyState === PropertyLegacyDtoPropertyStateEnum.DRAFT) {
          // BE logic is: If property is DRAFT we save even if there are validation errors. Otherwise we don't save
          showNotification(successNotification);
        } else {
          showNotification(errorNotification);
        }
      } else {
        setPropertyState(state => state.failed());
        const duplicateNameError = res.title?.includes('Property name must be unique');
        const parentAccountUpdateError = res.title?.includes('Cannot update parent account code of already existing account');
        if (duplicateNameError) {
          showNotification(duplicateNameErrorNotification(res.detail));
        }
        if (parentAccountUpdateError) {
          const idx = requestData.accounts.findIndex(a => a.code === res.newParentAccountCode);
          const errorKey = `accounts[${idx}].code`;
          currentSectionErrors = { [errorKey]: 'invalid' };
        }
        if (!duplicateNameError) {
          showNotification(errorNotification);
        }
      }
    })
      .finally(() => {
        setPropertyState(state => state.finishLoading());

        if (callback) {
          callback(currentSectionErrors);
        }
      });
  };


  /**
   * PMP-671 Temporary function for marking a property as invoice ready
   * TODO: Remove after implementing validation
   * @param propertyHrId
   */
  const onInvoiceReady = (): void => {
    setPropertyState(state => state.startLoading());
    const successNotification: NotificationObject = {
      key: 'savePropertySuccess',
      message: tl(translations.notifications.propertyEditingContext.saveSuccess.message),
      type: 'success',
    };
    const errorNotification: NotificationObject = {
      key: 'savePropertyError',
      message: tl(translations.notifications.propertyEditingContext.saveError.message),
      description: tl(translations.notifications.propertyEditingContext.saveError.description),
      type: 'error',
    };

    backend.post(`${endpointUrls.PROPERTY}/invoiceReady/${propertyState.id}`, {})
      .then(() => {
        onUpdatePropertyInList({ ...propertyState, propertyState: PropertyLegacyDtoPropertyStateEnum.READY });
        goBack();
        showNotification(successNotification);
      })
      .catch((res) => {
        if (res.title === 'Validation error') {
          setPropertyState(state => state.failed(res));
          showNotification(validationWarningNotification);
        } else {
          setPropertyState(state => state.failed());
          showNotification(errorNotification);
        }
      });
  };
  const onOffboardProperty = async () => {
    const successNotification: NotificationObject = {
      key: 'oofboardPropertySuccess',
      message: tl(translations.notifications.propertyEditingContext.offBoardSuccess.message),
      description: tl(translations.notifications.propertyEditingContext.offBoardSuccess.description),
      type: 'success',
    };
    const errorNotification: NotificationObject = {
      key: 'offboardPropertyError',
      message: tl(translations.notifications.propertyEditingContext.offBoardError.message),
      description: tl(translations.notifications.propertyEditingContext.offBoardError.description),
      type: 'error',
    };
    try {
      await backend.post(`${endpointUrls.PROPERTY}/${propertyState.id}/offboard`, {});
      removeDeletedPropertyFromList(propertyState.id);
      history.push('/properties');
      showNotification(successNotification);
    } catch (e) {
      showNotification(errorNotification);
    }
  };

  const onChangeSection = (value: any) => {
    updatePropertyState({ property: value }, rest.validationErrors);
  };


  const autoSelectContact = (contact: any, key: string) => {
    const newProperty = _.cloneDeep(propertyState);
    if (key.includes('Id')) {
      setValue(newProperty, key, contact.id);
    } else {
      setValue(newProperty, key, contact);
    }
    setDirty(true);
    updatePropertyState({ property: newProperty });
  };

  const setAutoSelectCompany = (key: string) => {
    companyEditingContext.setAutoSelectCompanyAfterCreation(() => (contact: any) => autoSelectContact(contact, key));
  };

  const setAutoSelectPerson = (key: string) => {
    contactEditingContext.setAutoSelectContactAfterCreation(() => (contact: any) => autoSelectContact(contact, key));
  };

  const initializeAdministrationType = () => {
    setPropertyState(prev => prev.load({
      ...prev.data,
      property: {
        ...prev.data.property,
        administrationType,
      },
    }));
  };

  return {
    property: propertyState,
    loaded,
    updatePropertyState,
    setPropertyState,
    onLoadProperty,
    onLoadPropertyById,
    onSaveProperty,
    clearProperty,
    onInvoiceReady,
    onOffboardProperty,
    onValidateProperty,
    onChangeSection,
    setDirty,
    setAutoSelectCompany,
    setAutoSelectPerson,
    initializeAdministrationType,
    onUpdateProperty,
    onUpdatePropertyInList,
    ...rest,
  };
};
