export const translations: any = {
  upload: {
    en: 'Upload',
    de: 'Hochladen',
  },
  columns: {
    status: {
      en: 'Status',
      de: 'Status',
    },
    file: {
      en: 'Files',
      de: 'Dokumente',
    },
    numberOfTransactions: {
      en: 'Number of transactions',
      de: 'Anzahl der Transaktionen',
    },
    totalAmount: {
      en: 'Total amount',
      de: 'Gesamtsumme',
    },
    created: {
      en: 'Uploaded date',
      de: 'Hochgeladenes Datum',
    },
  },
  status: {
    PROCESSED: {
      en: 'Processed',
      de: 'Verarbeitet',
    },
    FAILED: {
      en: 'Failed',
      de: 'Gescheitert',
    },
    IN_PROGRESS: {
      en: 'Loading...',
      de: 'Lädt...',
    },
    WARNING: {
      en: 'Warning',
      de: 'Warnung',
    },
  },
  fileUploadModal: {
    title: {
      en: 'Import transactions',
      de: 'Transaktionen importieren',
    },
    upload: {
      en: 'Upload xml',
      de: 'Hochladen',
    },
    cancel: {
      en: 'Cancel',
      de: 'Abbrechen',
    },
    body: {
      en: 'By uploading the file, the transactions will be matched. Are you sure you want to continue?',
      de: 'Durch das Hochladen der Datei werden die Transaktionen importiert und automatisch abgeglichen. Dies kann nicht rückgängig gemacht werden. Bitte stellen Sie sicher, dass die korrekten Dateien importiert werden.',
    },
  },
  notifications: {
    transactionImportLoadError: {
      message: {
        en: 'Failed loading transaction imports',
        de: 'Fehler beim Laden der Transaktionsimporte',
      },
    },
    bankListImportLoadError: {
      message: {
        en: 'Failed loading bank list',
        de: 'Fehler beim Laden der Bankliste',
      },
    },
    finApiAuthError: {
      message: {
        en: 'Authentication failed',
        de: 'Authentifizierung fehlgeschlagen',
      },
    },
    uploadError: {
      message: {
        en: 'Upload failed',
        de: 'Upload fehlgeschlagen',
      },
    },
    uploadSuccess: {
      message: {
        en: 'files were uploaded, they are currently processed.',
        de: 'Dateien wurden hochgeladen, sie werden derzeit verarbeitet',
      },
    },
    downloadError: {
      message: {
        en: 'Download failed',
        de: 'Download fehlgeschlagen',
      },
    },
  },
  bankConnectionActions: {
    synchronize: {
      en: 'Synchronize',
      de: 'Synchronisieren',
    },
    addConnection: {
      en: 'Add connection',
      de: 'Bankverbindung hinzufügen',
    },
    bankConnectionImportModal: {
      title: {
        en: 'Add new bank connection',
        de: 'Neue Bankverbindung hinzufügen',
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      import: {
        en: 'Import',
        de: 'Hinzufügen',
      },
      managementCompany: {
        en: 'Management company',
        de: 'Verwalterfirma',
      },
      bankingInterface: {
        en: 'The protocol FinAPI shall connect to the bank',
        de: 'Bankverbindungs Protokoll',
      },
      bank: {
        en: 'Bank',
        de: 'Bank',
      },
      selectBank: {
        en: 'Select Bank',
        de: 'Bank auswählen',
      },
      selectCompany: {
        en: 'Choose managing company',
        de: 'Verwaltungsfirma auswählen',
      },
      connectionName: {
        en: 'Bank connection name',
        de: 'Bankverbindungsname',
      },
    },
    useBankConnections: {
      loadError: {
        message: {
          en: 'Resource loading error',
          de: 'Fehler beim Laden der Ressource',
        },
        description: {
          en: 'Cannot load bank connections.',
          de: 'Fehler beim Laden von Bankverbindungen.',
        },
      },
      updateSuccess: {
        message: {
          en: 'Synchronization successfully started but will take some time to process.',
          de: 'Die Synchronisation wurde erfolgreich gestartet, wird aber einige Zeit in Anspruch nehmen.',
        },
      },
      updateError: {
        message: {
          en: 'Could not update the connection',
          de: 'Fehler beim Aktualisieren der Bankverbindung',
        },
      },
      addError: {
        message: {
          en: 'Could not add connection. Ensure all fields are filled correctly.',
          de: 'Fehler beim hinzufügen der Bankverbindung. Bitte stellen Sie sicher, dass alle Felder ausgefüllt sind.',
        },
      },
    },
    updateModal: {
      title: {
        en: 'Import Bankaccounts & Transactions',
        de: 'Umsätze und Bankkonten importieren',
      },
      body: {
        p1: {
          en: 'Importing the accounts can take some time.',
          de: 'Das importieren der Konten oder Umsätze kann einige Zeit in Anspruch nehmen.',
        },
        p2: {
          en: 'The process is executed in the background, so the page can be left after successful execution.',
          de: 'Der Prozess wird im Hintergrund ausgeführt, deswegen darf die Seite nach erfolgreicher Ausführung verlassen werden.',
        },
      },
      cancel: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
      syncAndImport: {
        en: 'Synchronize bankconnection',
        de: 'Bankverbindung neu synchronisieren',
      },
      import: {
        en: 'Import transactions',
        de: 'Umsätze importieren',
      },
    },
  },
};
