import React, { useContext, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useCheckPropertyValidity } from 'services/Property/useCheckPropertyValidity';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { useHistory, useLocation } from 'react-router';
import DEFAULT_DATA from '../lib/data';
import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import { LanguageContext } from './LanguageContext';
import { PropertyDistributionKeyListContext } from './PropertyDistributionKeyListContext';
import { showNotification } from '../lib/Notification';
import {
  AccountTypeDtoAccountTypeEnum,
  BuildingProjection, UnitContractProjectionDto, UnitProjectionDto,
} from '../api/accounting';

export const DistributionKeyContext: any = React.createContext({});

export default function DistributionKeyProvider({ children }: any): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const overlayContext = useContext(OverlayContext);
  const { goBack, overlays } = overlayContext as any;
  const propertyDistributionKeyContext: any = useContext(PropertyDistributionKeyListContext);

  const [accountDistributionKey, setAccountDistributionKey] = useState(DEFAULT_DATA<any>([]));
  const [selectedEconomicYear, setSelectedEconomicYear] = useState(moment().get('year'));
  const [editMode, setEditMode] = useState(false);
  const { checkPropertyValidity } = useCheckPropertyValidity();
  const history = useHistory();
  const location = useLocation();


  const onCheckPropertyValidityThenLoadAccountDK = (accountCode: string, propertyId: string, propertyHrId: string, economicYear: string) => {
    // this onCancel implementation is needed because when this function is called the overlay is not yet added
    // in the OverlayContext, so in the closure in which overlayContext.goBack is it still only has 2 overlays
    // so the goBack closes 2 overlays instead of 1.
    const onCancel = () => {
      if (overlays.map(({ url }: any) => url).includes(location.pathname)) {
        goBack();
      } else {
        history.goBack();
      }
    };
    onLoadAccountDistributionKey(accountCode, propertyId, propertyHrId, economicYear);
    checkPropertyValidity({
      propertyHrId,
      onCancel,
    });
  };

  const onLoadAccountDistributionKey = (accountCode: string, propertyId: string, propertyHrId: string, economicYear: string) => {
    propertyDistributionKeyContext.setPropertyHrId(propertyHrId);
    setAccountDistributionKey(prev => prev.startLoading());
    backend.get(`${endpointUrls.DISTRIBUTION_KEY}/account`,
      {
        year: economicYear,
        propertyId,
        accountCode,
      })
      .then(async (response: any) => {
        try {
          setAccountDistributionKey(prev => prev.startLoading());
          const [account, units, buildings, unitContractsOfProperty] = await Promise.all([
            propertyDistributionKeyContext.getAccountName(accountCode, propertyHrId),
            propertyDistributionKeyContext.onLoadUnitsOfProperty(propertyId),
            propertyDistributionKeyContext.onLoadBuildingsOfProperty(propertyId),
            propertyDistributionKeyContext.onLoadActiveUnitContractsOfProperty(propertyId),
          ]);
          const distributionKey = {
            ...response,
            accountName: account.name,
            accountId: account.id,
            accountType: account.accountType,
            unitDistributions: response.unitDistributions.map((unitDistribution: any) => {
              const unit = units.find((u: UnitProjectionDto) => u.id === unitDistribution.unitId);
              const building = buildings.find((b: BuildingProjection) => b.id === unit.buildingId);
              return ({
                ...unitDistribution,
                unit,
                building,
                unitContract: unitContractsOfProperty.find((u: UnitContractProjectionDto) => u.unitId === unitDistribution.unitId),
              });
            })
              .filter(ud => !ud.unit.isOwnedByWeg)
              .sort((a: any, b: any) => (a.unit.unitRank - b.unit.unitRank)),
          };
          setAccountDistributionKey(prev => prev.load(distributionKey));
        } catch (e) {
          setAccountDistributionKey(prev => prev.failed());
          showNotification({
            key: 'loadAccountDistributionKeyError',
            message: tl(translations.notifications.distributionKeyContext.loadError.message),
            description: tl(translations.notifications.distributionKeyContext.loadError.description),
            type: 'error',
          });
        }
      })
      .catch(() => {
        setAccountDistributionKey(prev => prev.failed());
        showNotification({
          key: 'loadAccountDistributionKeyError',
          message: tl(translations.notifications.distributionKeyContext.loadError.message),
          description: tl(translations.notifications.distributionKeyContext.loadError.description),
          type: 'error',
        });
      });
  };

  const onSaveAccountDistributionKey = () => {
    setAccountDistributionKey(prev => prev.startLoading());
    saveDistributionKey();
  };

  const saveDistributionKey = () => {
    backend.put(`${endpointUrls.DISTRIBUTION_KEY}/account`, accountDistributionKey.data)
      .then(() => {
        showNotification({
          key: 'saveAccountDistributionKeySuccess',
          message: tl(translations.notifications.distributionKeyContext.updateSuccess.message),
          type: 'success',
        });
        setAccountDistributionKey(adk => adk.finishLoading());
      })
      .catch((res) => {
        if (res.title === 'Validation error') {
          setAccountDistributionKey(accountDistributionKey.failed(res));
          const houseMoneySettlementPercentageSum: number = accountDistributionKey.data.unitDistributions ? accountDistributionKey.data.unitDistributions.reduce((accumulator: number, unitDistribution: any) => accumulator + unitDistribution.houseMoneySettlementPercentage, 0) : 0;
          const economicPlanPercentageSum: number = accountDistributionKey.data.unitDistributions ? accountDistributionKey.data.unitDistributions.reduce((accumulator: number, unitDistribution: any) => accumulator + unitDistribution.economicPlanPercentage, 0) : 0;
          let validationErrorMessageDescription = tl(translations.notifications.distributionKeyContext.validationError.checkboxLogic);
          if (res.unitDistributions) {
            validationErrorMessageDescription = tl(translations.validations[res.unitDistributions]);
            validationErrorMessageDescription += `${tl(translations.notifications.distributionKeyContext.validationError.description1)} ${houseMoneySettlementPercentageSum}.`;
            validationErrorMessageDescription += `${tl(translations.notifications.distributionKeyContext.validationError.description2)} ${economicPlanPercentageSum || 0}.`;
          }
          showNotification({
            key: 'saveAccountDistributionKeyValidationError',
            message: tl(translations.notifications.distributionKeyContext.validationError.message),
            description: validationErrorMessageDescription,
            type: 'error',
          });
        } else {
          setAccountDistributionKey(accountDistributionKey.failed());
          showNotification({
            key: 'saveAccountDistributionKeyError',
            message: tl(translations.notifications.distributionKeyContext.saveError.message),
            description: tl(translations.notifications.distributionKeyContext.saveError.description),
            type: 'error',
          });
        }
      });
  };

  const getTableKeyByAccountType = () => {
    let tableKey = '';
    switch (accountDistributionKey.data.accountType.accountType) {
    case AccountTypeDtoAccountTypeEnum.REVENUE:
      tableKey = 'income';
      break;
    case AccountTypeDtoAccountTypeEnum.EXPENSE:
      tableKey = 'expenses';
      break;
    case AccountTypeDtoAccountTypeEnum.CAPITAL:
      tableKey = 'reserveFunds';
      break;
    default:
      tableKey = '';
    }
    return tableKey;
  };
  const onSetUnitDistributionKey = (unitId: number, key: string, value: any) => {
    setAccountDistributionKey((prev) => {
      const { unitDistributions } = prev.data;
      const unitDistribution = unitDistributions.find(unitDk => unitDk.unitId === unitId);

      const newUnitDistributions = unitDistributions.map((unitDk) => {
        if (unitDk.unitId === unitId) {
          return {
            ...unitDistribution,
            [key]: value,
          };
        }
        return unitDk;
      });

      return prev.load({
        ...prev.data,
        unitDistributions: newUnitDistributions,
      });
    });
  };

  const onClearAccountDistributionKey = () => {
    setAccountDistributionKey(currentState => currentState.load({}));
  };

  return (
    <DistributionKeyContext.Provider
      value={{
        onLoadAccountDistributionKey: onCheckPropertyValidityThenLoadAccountDK,
        overlays,
        onSaveAccountDistributionKey,
        accountDistributionKey,
        setAccountDistributionKey,
        onSetUnitDistributionKey,
        onClearAccountDistributionKey,
        selectedEconomicYear,
        setSelectedEconomicYear,
        editMode,
        setEditMode,
      }}
    >
      {children}
    </DistributionKeyContext.Provider>
  );
}
