export const translations = {
  notifications: {
    loadPropertyError: {
      en: 'Failed to load property.',
      de: 'Fehler beim Laden des Objektes.',
    },
    loadUnitsError: {
      en: 'Failed to load units.',
      de: 'Fehler beim Laden der Einheiten.',
    },
    loadContractsError: {
      en: 'Failed to load contracts.',
      de: 'Fehler beim Laden der Verträge.',
    },
    loadAssignmentsError: {
      en: 'Failed to load assignments.',
      de: 'Zuordnungen konnten nicht geladen werden.',
    },
    deleteDocumentsError: {
      en: 'Failed to delete documents.',
      de: 'Dokumente konnten nicht gelöscht werden.',
    },
    deleteDocumentsSuccess: {
      en: 'Documents were deleted successfully.',
      de: 'Dokumente wurden erfolgreich gelöscht.',
    },
    renderPdfPageError: {
      en: 'Failed to render pdf page',
      de: 'Datei kann nicht angezeigt werden.',
    },
    uploadSuccess: {
      en: 'Files were uploaded successfully.',
      de: 'Dateien wurden erfolgreich hochgeladen.',
    },
    submitError: {
      canRetry: {
        en: 'Failed to submit. Please try again.',
        de: 'Übertragen fehlgeschlagen. Bitte versuchen Sie es erneut.',
      },
      splitFailed: {
        en: 'The submission failed partially. You are seeing the latest changes...',
        de: 'Die Übertragung ist teilweise fehlgeschlagen. Sie sehen die neuesten Veränderungen...',
      },
      noAssigned: {
        en: 'The selected pages are not assigned to any contract.',
        de: 'Die ausgewählten Seiten sind keinem Vertrag zugeordnet.',
      },
    },
    submitSuccess: {
      en: 'Documents were submitted successfully.',
      de: 'Dokumente wurden erfolgreich übertragen.',
    },
    uploadError: {
      specific: {
        en: (fileNames: string) => `The following files failed to upload: ${fileNames}`,
        de: (fileNames: string) => `Die folgenden Dateien konnten nicht hochgeladen werden: ${fileNames}`,
      },
      generic: {
        en: 'Some files failed to upload.',
        de: 'Einige Dateien konnten nicht hochgeladen werden.',
      },
    },
  },
  modals: {
    deleteModal: {
      title: {
        en: 'Delete PDF file',
        de: 'PDF Datei löschen',
      },
      content: {
        en: 'The file will be deleted and therefore the assignments you made to the file will be lost. Are you sure?',
        de: 'Die Datei wird gelöscht und damit auch die Zuweisungen, die Sie vorgenommen haben. Sind Sie sicher, dass Sie fortfahren möchten?',
      },
      okText: {
        en: 'Delete file',
        de: 'Datei löschen',
      },
      cancelText: {
        en: 'Cancel',
        de: 'Abbrechen',
      },
    },
  },
  actionBar: {
    header: {
      property: {
        en: 'Property',
        de: 'Objekt',
      },
      unitId: {
        en: 'Unit-id',
        de: 'Nr. - Eigentümer',
      },
      contract: {
        en: 'Contract',
        de: 'Vertrag',
      },
      usagePeriod: {
        en: 'Usage period',
        de: 'Nutzungsdauer',
      },
    },
    contractType: {
      OWNER: {
        en: 'Owner',
        de: 'Eigentümer',
      },
      TENANT: {
        en: 'Tenant',
        de: 'Mieter',
      },
    },
    noData: {
      noSearchData: {
        title: {
          en: 'No search results...',
          de: 'Keine Suchergebnisse...',
        },
        subtitle: {
          en: 'Please search for another contract',
          de: 'Bitte suchen Sie nach einem anderen Vertrag',
        },
      },
      noDataAvailable: {
        title: {
          en: 'No data',
          de: 'Keine Daten',
        },
      },
    },
    searchPlaceHolder: {
      en: 'Search for contract to assign',
      de: 'Vertrag suchen',
    },
    missingContract: {
      en: 'Missing contract',
      de: 'Fehlender Vertrag',
    },
  },
  pageHeader: {
    labels: {
      assignedPages: {
        en: 'Assigned pages',
        de: 'Zugeordnete Seiten',
      },
    },
    buttons: {
      addFiles: {
        en: 'Add files',
        de: 'Dateien hinzufügen',
      },
      save: {
        en: 'Save',
        de: 'Speichern',
      },
      saveAndClose: {
        en: 'Save & Close',
        de: 'Speichern & Schließen',
      },
    },
  },
  pagePreview: {
    noActiveContractTooltip: {
      en: "This unit doesn't have an active contract. Please create one via the unit contract history.",
      de: 'Diese Einheit hat keinen aktiven Vertrag. Bitte erstellen Sie einen über die Vertragsübersicht der Einheit.',
    },
    dateRangeErrorTooltip: {
      en: 'This contract is not anymore in the specified date range. Make sure the assignment is correct.',
      de: 'Dieser Vertrag befindet sich nicht mehr innerhalb des angegebenen Zeitraums. Bitte stellen Sie sicher, dass die Zuweisung korrekt ist.',
    },
    vacancy: {
      en: 'Vacancy',
      de: 'Leerstand',
    },
  },
};
