/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RoleDto } from './RoleDto';
import {
    RoleDtoFromJSON,
    RoleDtoFromJSONTyped,
    RoleDtoToJSON,
} from './RoleDto';

/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    createdBy?: string;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    domainId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    id?: number;
    /**
     * 
     * @type {RoleDto}
     * @memberof UserDto
     */
    role?: RoleDto;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    updated?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    updatedBy?: string;
}

/**
 * Check if a given object implements the UserDto interface.
 */
export function instanceOfUserDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserDtoFromJSON(json: any): UserDto {
    return UserDtoFromJSONTyped(json, false);
}

export function UserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'domainId': !exists(json, 'domainId') ? undefined : json['domainId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'role': !exists(json, 'role') ? undefined : RoleDtoFromJSON(json['role']),
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function UserDtoToJSON(value?: UserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'createdBy': value.createdBy,
        'domainId': value.domainId,
        'email': value.email,
        'id': value.id,
        'role': RoleDtoToJSON(value.role),
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'updatedBy': value.updatedBy,
    };
}

