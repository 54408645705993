import { ContactLegacyDtoTypeEnum } from 'api/accounting';
import { getViewCompanyRoute, getViewPersonRoute } from 'pages/Contacts/routes';
import { RecipientDatasource } from 'pages/MessageSendingPage/interfaces';
import { getContractEditingRoute, getPropertyOwnerContractEditingRoute } from 'pages/Property/routes';

export const useContractContactCell = (record: RecipientDatasource) => {
  const urlParams = {
    propertyHrId: record?.propertyHrId,
    unitDbId: record?.unitId,
    contractDbId: record?.unitContractId,
  };

  const getUrl = () => {
    if (record.children) {
      if (record.unitId) {
        return getContractEditingRoute(urlParams);
      }
      // property owner
      return getPropertyOwnerContractEditingRoute(urlParams);
    }

    if (record?.contactType === ContactLegacyDtoTypeEnum.PERSON) {
      return getViewPersonRoute({ personId: record?.contactId });
    }

    if (record?.contactType === ContactLegacyDtoTypeEnum.COMPANY) {
      return getViewCompanyRoute({ companyId: record?.contactId });
    }
    // fallback to proeprty editing
    return `/properties/${record?.propertyHrId}/edit`;
  };

  const url = getUrl();

  return {
    url,
  };
};
