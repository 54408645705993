import React, {
  CSSProperties, useContext, useRef, useState,
} from 'react';
import { ConfigProvider, Form, Select as SelectV4 } from 'antd';
import Icon from '@ant-design/icons';
import './SelectInput.scss';
import { LoadingIndicator } from 'elements/LoadingIndicator/LoadingIndicator';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../../Translation/translations';
import InputProps from '../InputProps';
import { InfoIcon } from '../../../storybook-components/InfoIcon/InfoIcon';
import { getParentScrollElement } from '../../../lib/Utils';
import { ICONS } from '../../../components/icons';
import 'antd/lib/select/style/index.css';

export interface SelectOption {
  value?: any,
  label: string,
  customBody?: JSX.Element,
  disabled?: boolean,
  title?: string,
  className?: string,
  searchValue?: string,
}

export interface SelectInputProps extends Omit<InputProps<any>, 'value'> {
  mode?: 'default' | 'multiple' | 'tags' | 'combobox'
  options: SelectOption[],
  allowClear?: boolean,
  notFoundContent?: any,
  showSearch?: boolean,
  size?: 'default' | 'large' | 'small'
  filterOption?: (input: any, option: any) => boolean
  dropdownMatchSelectWidth?: boolean,
  dropdownClassName?: string,
  dropdownStyle?: CSSProperties,
  className?: string,
  inputClassName?: string,
  value?: any,
  /**
   * Allow the user to specify a new option, besides the already available ones
   * The value will have the value of the label typed by the user
   * Selection may be made by using Enter  or clicking the option in the dropdown
   * this option auto-enables showSearch
   */
  allowCustomOption?: boolean,
  loading?: boolean,
  getPopupContainer?: () => HTMLElement,
  tagRender?: any,
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight',
  onSearch?: (searchValue: string) => void,
  tooltipMinWidth?: number,
}

const LOADING_INDICATOR_OPTION_VALUE = 'loading-indicator-option';

const defaultFilterOption = (input: any, option: any) => {
  if (option.value === LOADING_INDICATOR_OPTION_VALUE) {
    // if they filter while it's loading, show the loading indicator
    return true;
  }

  return option.props.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export default function SelectInput(props: SelectInputProps): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const {
    disabled, showPlaceholderWhenDisabled, className, label, required, validationState, validationMessage, inputClassName, mode, options, allowClear,
    notFoundContent, showSearch, size, filterOption, autoFocus, infoText, dropdownMatchSelectWidth, dropdownClassName, dropdownStyle, defaultValue, loading,
    onChange: onChangeProp,
    allowCustomOption,
    tooltipMinWidth,
  } = props;

  let { value, placeholder } = props;
  const {
    getPopupContainer, tagRender, placement, onSearch: onSearchProp,
  } = props;

  const Select = SelectV4;
  const { Option } = SelectV4;

  const getSize = (s?: 'default' | 'large' | 'small') => (s === 'default' ? 'middle' : s);
  const getMode = (m?: 'default' | 'multiple' | 'tags' | 'combobox') => (m === 'default' || m === 'combobox' ? undefined : m);

  const [customOption, setCustomOption] = useState('');
  let localOptions = options;
  let onSearch;
  if (allowCustomOption) {
    // only define onSearch if allowCustomOption is true
    // otherwise antd will warn you of wrong onSearch usage
    onSearch = (e) => {
      setCustomOption(e);
    };
    const customOptionExistsAndIsNotYetPartOfTheList = customOption && !options.find(e => e.value === customOption);
    if (customOptionExistsAndIsNotYetPartOfTheList) {
      localOptions = [{ value: customOption, label: customOption }, ...options];
    }
  } else if (showSearch && onSearchProp && !filterOption) {
    onSearch = (e) => {
      onSearchProp(e);
    };
  }

  const optionElements = localOptions.map(option => (
    <Option
      key={option.value ?? option.label}
      value={option.value}
      title={option.title || option.label}
      disabled={option.disabled}
      className={option.className}
    >
      {option.customBody ? option.customBody : <div className="select-menu-item">{option.label}</div>}
    </Option>
  ));

  const loadingElement = (
    <Option
      key="loading-indicator-option"
      value={LOADING_INDICATOR_OPTION_VALUE}
      className="loading-indicator-option"
      disabled
    >
      <LoadingIndicator />
    </Option>
  );
  const onChange = (val: any): void => {
    onChangeProp(val);
    if (selectRef.current) {
      // @ts-ignore
      selectRef.current.blur();
    }
  };

  if (mode === 'multiple') {
    value = (!!value && value.length > 0) ? value : [];
  }


  if (!placeholder) {
    placeholder = tl(translations.elements.selectInput.placeholder);
  }
  if (disabled && !showPlaceholderWhenDisabled) {
    placeholder = '';
  }

  const selectInputWrapperElement = useRef(null);
  const selectRef = useRef(null);
  return (
    <div className={`SelectInput ${className}`} ref={selectInputWrapperElement}>
      <Form.Item
        label={label ? (
          <span>
            <span>{`${label} ${required ? ' *' : ''}`}</span>
            {infoText && <InfoIcon popupText={infoText} size="small" tooltipMinWidth={tooltipMinWidth} />}
          </span>
        ) : null}
        validateStatus={validationState}
        help={disabled ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        <ConfigProvider renderEmpty={() => <span className="select-search-no-match">{tl(translations.elements.selectInput.noMatch)}</span>}>
          <Select
            allowClear={allowClear}
            autoFocus={autoFocus}
            className={`Select ${inputClassName} ${disabled ? 'read-only' : ''}`}
            defaultValue={defaultValue}
            disabled={disabled}
            dropdownClassName={dropdownClassName}
            dropdownMatchSelectWidth={dropdownMatchSelectWidth}
            dropdownStyle={dropdownStyle}
            filterOption={filterOption || defaultFilterOption}
            getPopupContainer={getPopupContainer ?? (() => getParentScrollElement(selectInputWrapperElement.current))}
            mode={getMode(mode)}
            notFoundContent={notFoundContent}
            onChange={onChange}
            onSearch={onSearch}
            optionLabelProp="title"
            placeholder={placeholder}
            placement={placement}
            ref={selectRef}
            removeIcon={<Icon component={ICONS.cross} />}
            showAction={['focus']}
            showSearch={showSearch || allowCustomOption}
            size={getSize(size)}
            suffixIcon={<Icon component={ICONS.triangleDown} />}
            tagRender={tagRender}
            value={value}
          >
            {loading ? loadingElement : optionElements}
          </Select>
        </ConfigProvider>
      </Form.Item>
    </div>
  );
}

SelectInput.defaultProps = {
  className: '',
  inputClassName: '',
  mode: 'default',
  size: 'default',
  dropdownMatchSelectWidth: true,
  dropdownStyle: { zIndex: 2000 },
  allowClear: undefined,
  getPopupContainer: undefined,
  notFoundContent: undefined,
  showSearch: undefined,
  filterOption: undefined,
  dropdownClassName: '',
  value: undefined,
  loading: undefined,
  tagRender: undefined,
  allowCustomOption: false,
  placement: 'bottomRight',
  onSearch: undefined,
  tooltipMinWidth: undefined,
};
