import { ContactLegacyDtoTypeEnum } from 'api/accounting';
import { getViewCompanyRoute, getViewPersonRoute } from 'pages/Contacts';
import { useGetContactById } from 'services/useGetContactById';

export const useContactLink = (contactId: number) => {
  const { contact } = useGetContactById(contactId);

  const url = contact?.type === ContactLegacyDtoTypeEnum.PERSON
    ? getViewPersonRoute({ personId: contact?.id })
    : getViewCompanyRoute({ companyId: contact?.id });

  const name = contact?.type === ContactLegacyDtoTypeEnum.PERSON
    ? `${contact?.firstName || ''} ${contact?.lastName || ''}`
    : `${contact?.companyName || ''}`;

  return {
    name,
    url,
  };
};
