import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'antd';
import SelectInput, { SelectOption } from 'elements/Inputs/SelectInput/SelectInput';
import { LanguageContext } from '../../../../../contexts/LanguageContext';
import Button from '../../../../../elements/Buttons/Button/Button';
import { translations } from '../../../translations';
import useBankConnections from '../services/useBankConnections';

interface BankConnectionUpdateModalProps {
  visible: boolean,
  onClose: Function,
}

export default function BankConnectionUpdateModal(props: BankConnectionUpdateModalProps): JSX.Element {
  const {
    visible, onClose,
  } = props;
  const { tl } = useContext(LanguageContext);
  const {
    bankConnections, loadBankConnections, updateBankConnection, updatingBankConnection,
  } = useBankConnections();
  const [selectedBankConnection, setSelectedBankConnection] = useState<number>(-1);
  const [connectionOptions, setConnectionOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    loadBankConnections();
  }, []);

  useEffect(() => {
    if (bankConnections?.data?.length) {
      const connOptions = bankConnections.data.map(bc => ({ value: bc.id || 0, label: bc.name || '' }));
      setConnectionOptions(connOptions);
      if (bankConnections.data && bankConnections.data[0]) {
        setSelectedBankConnection(bankConnections.data[0].id!);
      }
    }
  }, [bankConnections]);


  return (
    <Modal
      width="50%"
      title={tl(translations.bankConnectionActions.updateModal.title)}
      className="BankConnectionUpdateModal"
      visible={visible}
      onCancel={() => onClose()}
      footer={[
        <Button onClick={() => onClose()} type="text" key="cancel">
          {tl(translations.bankConnectionActions.updateModal.cancel)}
        </Button>,
        <Button onClick={async () => { await updateBankConnection(selectedBankConnection, true); onClose(); }} type="default" key="syncimport" loading={updatingBankConnection}>
          {tl(translations.bankConnectionActions.updateModal.syncAndImport)}
        </Button>,
        <Button onClick={async () => { await updateBankConnection(selectedBankConnection, false); onClose(); }} type="primary" key="sync" loading={updatingBankConnection}>
          {tl(translations.bankConnectionActions.updateModal.import)}
        </Button>,
      ]}
    >
      <SelectInput
        onChange={e => setSelectedBankConnection(e)}
        defaultValue={selectedBankConnection}
        options={connectionOptions}
      />

      <p>{tl(translations.bankConnectionActions.updateModal.body.p1)}</p>
      <p>{tl(translations.bankConnectionActions.updateModal.body.p2)}</p>
    </Modal>
  );
}
