import React, { useContext, useEffect } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { Alert } from 'antd';
import { SectionWrapper } from 'storybook-components/sections/SectionWrapper/SectionWrapper';
import { translations } from './translations';
import { loadCsv } from '../services/loadCsv';
import { useImportPropertiesCsv } from './usePropertiesImportCsv';
import { FileUploadButton } from '../components/FileUploadButton/FileUploadButton';
import { showNotification } from '../../../../lib/Notification';
import { PropertyImportDto } from './interfaces';


export const PropertiesImportSection = ({ onLoaded }: { onLoaded: (data: PropertyImportDto[]) => void }) => {
  const { tl } = useContext(LanguageContext);
  const { loadPropertiesFromCsvRows, properties, propertiesValidationIssues } = useImportPropertiesCsv();
  useEffect(() => { onLoaded(properties); }, [properties]);
  return (

    <SectionWrapper
      sectionNumber={2}
      sectionTitle={tl(translations.title)}
      sectionId="hms-close-year-account-selection"
    >
      <div className="row">
        <div className="col-xs-12">
          <FileUploadButton
            label={tl(translations.upload)}
            accept="text/csv"
            onChange={event => loadCsv(event.target.files, 0)
              .then(res => loadPropertiesFromCsvRows(res))
              .catch((err) => { showNotification({ message: tl(translations.validations.errors) }); console.error('Error while loading csv', err); })}
          />
        </div>
      </div>
      {properties.length > 0
        ? <Alert message={`${properties.length} ${tl(translations.validations.success)}`} type="success" />
        : <Alert message={tl(translations.description)} />
      }
      {propertiesValidationIssues.length > 0 && (
        <Alert
          message={tl(translations.validations.errors)}
          type="error"
          description={(
            <ul>
              {' '}
              {
                propertiesValidationIssues.map(issue => (
                  <li key={issue.row + issue.message}>
                    {`${issue.row + 1}: ${issue.message}`}
                  </li>
                ))
              }
            </ul>
          )}
        />

      )}

    </SectionWrapper>
  );
};
