import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { Form, Select, SelectProps } from 'antd';
import { translations } from '../../Translation/translations';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { formatDate } from '../../../lib/Utils';
import './EconomicYearPicker.scss';
import InputProps from '../InputProps';
import { SelectOption } from '../SelectInput/SelectInput';

interface EconomicYearPickerProps extends InputProps<number> {
  economicYearStart: Date,
  economicYearEnd: Date,
  fromYear?: number,
  toYear?: number,
  type?: 'label' | 'input',
  valuesList?: SelectOption[],
  dropdownRender?: SelectProps['dropdownRender'],
  getPopupContainer?: SelectProps['getPopupContainer']
}

export default function EconomicYearPicker(props: EconomicYearPickerProps): JSX.Element {
  let { placeholder } = props;
  const {
    value, valuesList, disabled, showPlaceholderWhenDisabled, className, validationState, validationMessage, label, required, inputClassName, economicYearStart, economicYearEnd, type,
    fromYear, toYear, dropdownRender, getPopupContainer,
  } = props;
  const { tl } = useContext(LanguageContext);
  const [optionElements, setOptionElements]: any = useState([]);

  useEffect(() => {
    if (!_.isEmpty(valuesList)) {
      setOptionElements(valuesList);
    } else if (economicYearStart && economicYearEnd && fromYear && toYear) {
      // TODO refactor to remove this in future stories
      setOptionElements(iterateAndGenerateYearValues(fromYear, toYear, economicYearStart, economicYearEnd));
    }
  }, [economicYearStart, economicYearEnd, fromYear, toYear, valuesList]);

  function iterateAndGenerateYearValues(from: number, to: number, yearStart: Date, yearEnd: Date) {
    // TODO refactor to remove this in future stories
    const tempOptionElements: any[] = [];
    for (let i = from; i < to + 1; i += 1) {
      const tempEconomicYearStart = new Date(yearStart);
      const tempEconomicYearEnd = new Date(yearEnd);
      tempEconomicYearStart.setFullYear(i);
      tempEconomicYearEnd.setFullYear(i);
      if (tempEconomicYearEnd < tempEconomicYearStart) {
        tempEconomicYearEnd.setFullYear(i + 1);
      }
      tempOptionElements.push({ label: `${formatDate(tempEconomicYearStart)} — ${formatDate(tempEconomicYearEnd)}`, value: i });
    }
    return tempOptionElements;
  }

  const onChange = (val: number): void => {
    props.onChange(val);
  };

  if (!placeholder) {
    placeholder = tl(translations.elements.economicYearPicker.placeholder);
  }
  if (disabled && !showPlaceholderWhenDisabled) {
    placeholder = '';
  }

  return (
    <div className={`EconomicYearPicker ${className} ${type}`}>
      <Form.Item
        label={label ? `${label}${required ? ' *' : ''}` : undefined}
        validateStatus={disabled || !validationMessage ? '' : (validationState || 'success')}
        help={disabled || !validationMessage ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        {optionElements
          && (
            <Select
              className={`Select ${inputClassName} ${disabled ? 'read-only' : ''}`}
              onChange={onChange}
              value={value}
              disabled={disabled}
              placeholder={placeholder}
              dropdownMatchSelectWidth={false}
              dropdownRender={dropdownRender}
              options={optionElements}
              getPopupContainer={(): HTMLElement => {
                if (getPopupContainer) return getPopupContainer({});
                const datePickerElements = document.getElementsByClassName('EconomicYearPicker');
                return datePickerElements && datePickerElements.length ? datePickerElements[datePickerElements.length - 1] as HTMLElement : document.body;
              }
              }
            />
          )
        }
      </Form.Item>
    </div>
  );
}

EconomicYearPicker.defaultProps = {
  dropdownRender: undefined,
  getPopupContainer: undefined,
  type: 'input',
};
