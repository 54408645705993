import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  AccountControllerApi,
  ExtendedAccountBalanceDto,
  PropertyDisplayDto,
  PropertyLegacyControllerApi,
} from 'api/accounting';
import {
  formatDate,
  getDateRangeForCurrentEconomicYear,
  ISO_DATE_FORMAT,
} from 'lib/Utils';

import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import DEFAULT_DATA from '../lib/data';
import { showNotification } from '../lib/Notification';
import { AuthContext } from './AuthContext';
import { LanguageContext } from './LanguageContext';

const PAGE_SIZE = 30;

export const AccountBalancesListContext: any = React.createContext({});

interface PropertyAccountBalance extends PropertyDisplayDto {
  incomeBalance: number,
  expenseBalance: number,
  reserveBalance: number,
  contractAccountsBalance: number,
}

export default function AccountBalancesListProvider({ children }: any) {
  const { tl } = useContext(LanguageContext);
  const defaultAccountBalancesList: PropertyAccountBalance[] = [];

  const [accountBalancesListState, setAccountBalancesListState] = useState(DEFAULT_DATA<PropertyAccountBalance[]>(defaultAccountBalancesList));
  const [selectedProperty, setSelectedProperty] = useState(DEFAULT_DATA<any>(null));
  const [selectedPropertyHrId, setSelectedPropertyHrId] = useState(null);
  const { apiConfiguration } = useContext(AuthContext);
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));
  const accountControllerApi = new AccountControllerApi(apiConfiguration('accounting'));
  const accountBalanceAbortController = useRef<AbortController | undefined>(undefined);

  const [accountBalancesFilterState, setAccountBalancesFilterState] = useState<any>({});

  const updateFilterState = (data: object) => {
    setAccountBalancesFilterState({
      ...accountBalancesFilterState,
      ...data,
    });
  };

  const onSetDefaultFilterFromQueryParams = (searchParams: { [key: string]: any }) => {
    setAccountBalancesFilterState(prev => ({
      ...prev,
      ...searchParams,
    }));
  };

  const onLoadAccountBalancesList = (resetPage: boolean = false) => {
    setAccountBalancesListState(listState => listState.startLoading(resetPage ? false : listState.loaded));

    // if params changed since last initiated fetch then abort the in-progress fetch
    accountBalanceAbortController.current?.abort();
    // create new abort controller
    accountBalanceAbortController.current = new AbortController();
    const { signal } = accountBalanceAbortController.current;

    // load property list without balances to not block the user
    propertyControllerApi.findFilteredPropertiesUsingGET(
      {
        ...accountBalancesFilterState,
        page: resetPage ? 0 : accountBalancesListState.page,
        size: PAGE_SIZE,
      }, { signal },
    )
      .then((response) => {
        setAccountBalancesListState(listState => listState.loadPaged(response.content, resetPage, response.last));
        // lazily load account balances afterwards
        response.content.forEach(prp => onLoadBalanceOfProperty(prp));
      })
      .catch(() => {
        if (signal?.aborted) return;
        setAccountBalancesListState(accountBalancesListState.failed());
        showNotification({
          key: 'loadProperties',
          message: tl(translations.notifications.accountBalancesListContext.propertyLoadError.message),
          type: 'error',
        });
      });
  };

  const onLoadBalanceOfProperty = (prp: PropertyDisplayDto) => {
    const { economicYearStart, economicYearEnd } = getDateRangeForCurrentEconomicYear(prp.economicYearStart, prp.economicYearEnd);
    const promises = [];
    promises.push(onLoadBalanceOfAccountsByRegex(prp.propertyHrId, formatDate(economicYearStart, ISO_DATE_FORMAT), formatDate(economicYearEnd, ISO_DATE_FORMAT), '^[6|8][0-9]*$'));
    promises.push(onLoadBalanceOfAccountsByRegex(prp.propertyHrId, '1970-01-01', formatDate(new Date(), ISO_DATE_FORMAT), '^[33|200][0-9]*$'));
    Promise.all(promises)
      .then((responses) => {
        const accounts = responses.flat();
        const incomeBalance = getSumFromAccountsByCode(accounts, '6');
        const expenseBalance = getSumFromAccountsByCode(accounts, '8');
        const reserveBalance = getSumFromAccountsByCode(accounts, '33');
        const contractAccountsBalance = getSumFromAccountsByCode(accounts, '200');
        setAccountBalancesListState(listState => ({
          ...listState,
          data: listState.data.map(row => (row.propertyHrId === prp.propertyHrId
            ? {
              ...row,
              incomeBalance,
              expenseBalance,
              reserveBalance,
              contractAccountsBalance,
            } : row)),
        }));
      });
  };


  const onLoadBalanceOfAccountsByRegex = (propertyHrId: string, startDate: string, endDate: string, regex: string) => accountControllerApi.getExtendedAccountBalancesUsingGET(
    {
      propertyHrId,
      startDate,
      endDate,
      regex,
    },
  )
    .then(response => Object.values(response))
    .catch((e) => {
      console.error(e);
      showNotification({
        key: 'loadPropertyBalance',
        message: tl(translations.notifications.accountBalancesListContext.accountBalancesLoadError.message),
        type: 'error',
      });
    });

  const getSumFromAccountsByCode = (accounts: ExtendedAccountBalanceDto[], code: string) => accounts.filter(account => account?.accountCode?.startsWith(code))
    .map(acc => acc.normalizedBalanceWithinDateRange)
    .reduce((ps, a) => ps + a, 0);

  const onLoadAccountBalance = (propertyHrId: string | null) => {
    if (!propertyHrId) return;
    if (accountBalancesListState.data.filter((property: any) => property.propertyHrId === propertyHrId).length === 0) {
      backend.get(`${endpointUrls.PROPERTY}/${propertyHrId}/account-balances`, {})
        .then((response: any) => {
          setSelectedProperty(selProp => selProp.load(response));
        })
        .catch(() => {
          setAccountBalancesListState(accountBalancesListState.failed());
          showNotification({
            key: 'loadProperties',
            message: tl(translations.notifications.accountBalancesListContext.propertyLoadError.message),
            type: 'error',
          });
        });
    }
  };

  const getProperty = (propertyHrId: string | null) => {
    if (!propertyHrId) return;
    const propertyList = accountBalancesListState.data;
    const property = propertyList.filter((element: any) => element.propertyHrId === propertyHrId);
    if (property.length > 0) {
      return property[0];
    }
    return null;
  };

  const clearAccountBalancesListState = () => {
    setAccountBalancesListState(() => DEFAULT_DATA<any>(defaultAccountBalancesList));
  };

  const clearAccountBalancesFilterState = () => {
    setAccountBalancesFilterState({});
  };

  /**
   * If propertyHrId changes, we look in the existing list
   * if not we go to the BE to get it and put it in the list
   */
  useEffect(() => {
    if (selectedPropertyHrId) {
      const property = getProperty(selectedPropertyHrId);
      if (!property) {
        onLoadAccountBalance(selectedPropertyHrId);
      } else setSelectedProperty(selProperty => selProperty.load(property));
    }
    return () => setSelectedProperty(selectedProperty.load(null));
  }, [selectedPropertyHrId]);

  return (
    <AccountBalancesListContext.Provider value={{
      ...accountBalancesListState,
      accountBalancesFilterState,
      setAccountBalancesFilterState,
      onSetDefaultFilterFromQueryParams,
      onLoadAccountBalancesList,
      onLoadAccountBalance,
      getProperty,
      setSelectedPropertyHrId,
      selectedProperty,
      updateFilterState,
      clearAccountBalancesListState,
      clearAccountBalancesFilterState,
    }}
    >
      {children}
    </AccountBalancesListContext.Provider>
  );
}
