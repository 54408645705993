import React from 'react';
import MainPageHeader from '../../storybook-components/headers/MainPageHeader/MainPageHeader';
import useSiteMap from '../../services/useSiteMap/useSiteMap';
import SmartTable from '../../elements/SmartTable/SmartTable';
import useSmartTable from '../../elements/SmartTable/useSmartTable';
import { useTransactionImportColumns } from './services/useTransactionImportsColumns';
import { useTransactionImports } from './services/useTransactionImports';
import './TransactionImports.scss';
import { BankConnectionActions } from './components/BankConnectionActions/BankConnectionActions';
import { useFinApiUsers } from './services/useFinApiUsers';

export const TransactionImports: React.FC = () => {
  const {
    transactionImports, load, download, lastPage, loading, sortField, sortOrder, setSortField,
  } = useTransactionImports();

  const { finapiEnabled } = useFinApiUsers();

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'transactionImports',
    navItems: subcategorySwitcherItems.accounting,
  };

  const smartTable = useSmartTable({
    tableName: 'transactionImportList',
    columns: useTransactionImportColumns(download),
    dataSource: transactionImports,
    contentLoading: loading,
    infiniteScrollerProps: {
      hasMoreData: !lastPage,
      loadMoreData: load,
    },
    virtualize: false,
    propSort: {
      field: sortField,
      order: sortOrder,
      onSortChange: (dataKey: string) => setSortField(dataKey),
    },
    rowKey: 'rowId',
  });

  if (typeof finapiEnabled === 'undefined') {
    return (<></>);
  }

  const showXmlUploadList = !finapiEnabled;
  return (
    <div className="TransactionImports page">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={<BankConnectionActions finapiEnabled={finapiEnabled} />}
      />
      {showXmlUploadList && (<SmartTable {...smartTable} />)}
    </div>
  );
};
