import React, {
  useContext, useEffect,
} from 'react';
import { useLocation } from 'react-router';
import './WkaList.scss';
import * as H from 'history';
import Button from 'elements/Buttons/Button/Button';
import { useHistory } from 'react-router-dom';
import useSmartTable from 'elements/SmartTable/useSmartTable';
import { useGlobalSearch } from '../../../components/Header/components/SearchBar/services/useGlobalSearch';
import useWkaList from './services/useWkaList';
import MainPageHeader from '../../../storybook-components/headers/MainPageHeader/MainPageHeader';
import useSiteMap from '../../../services/useSiteMap/useSiteMap';
import { translations } from './translations';
import { LanguageContext } from '../../../contexts/LanguageContext';
import useWkaFilters from './useWkaFilters';
import { useWkaListColumns } from './useWkaListColumns';
import SmartTable from '../../../elements/SmartTable/SmartTable';
import { WKA_CREATE_PATH } from '../routes';

export const WkaList = () => {
  const { tl } = useContext(LanguageContext);
  const location: H.Location<{ wkaId?: number, totalCosts?: number }> = useLocation();
  const history = useHistory();


  const {
    filterState,
    updateFilter,
    updateFilterState,
    updateWkaById,
    tableData,
    wkaList,
    onLoadWkaList,
    setSortField,
    sortState,
  } = useWkaList();

  useEffect(() => {
    if (location.state && location.state!.wkaId && location.state.totalCosts) {
      updateWkaById(location.state.wkaId, location.state.totalCosts);
    }
  }, [location.state]);

  useGlobalSearch({
    key: 'WKA',
    filterProps: {
      availableFilters: useWkaFilters(),
      setFilter: updateFilter,
    },
    queryParamAsFilter: {
      filterState,
      onSetDefaultFilterFromQueryParams: updateFilterState,
    },
  });

  const { subcategorySwitcherItems } = useSiteMap();
  const subcategorySwitcherProps = {
    selectedKey: 'wka',
    navItems: subcategorySwitcherItems.accounting,
  };

  const headerRightSideComponent = (
    <Button
      type="primary"
      onClick={() => history.push(WKA_CREATE_PATH)}
    >
      {tl(translations.addModal.button)}
    </Button>
  );

  const wkaTable = useSmartTable({
    tableName: 'WkaListTable',
    columns: useWkaListColumns(),
    dataSource: tableData ?? [],
    propSort: {
      field: sortState?.field ?? '',
      order: sortState?.order,
      onSortChange: (dataKey: string) => setSortField(dataKey),
    },
    contentLoading: wkaList.loading,
    infiniteScrollerProps: {
      hasMoreData: !wkaList.lastPage,
      loadMoreData: onLoadWkaList,
    },
    onRow: record => ({
      onClick: () => {
        history.push(`${location.pathname}/edit/${record.id}`);
      },
    }),
    rowKey: 'id',
  });

  return (
    <div className="WkaList page">
      <MainPageHeader
        subcategorySwitcherProps={subcategorySwitcherProps}
        rightSideComponent={headerRightSideComponent}
      />
      <div className="WkaListWrapper">
        <SmartTable {...wkaTable} />
      </div>
    </div>
  );
};
