import './PdfPageAssignerHeader.scss';

import React, { useContext } from 'react';

import {
  Col,
  Row,
} from 'antd';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import {
  PagesOrientationType,
} from 'pages/PdfPageAssigner/services/interfaces';

import Icon from '@ant-design/icons';

import { translations } from '../../translations';
import PdfPageAssignerAddFilesButton
  from '../PdfPageAssignerAddFilesButton/PdfPageAssignerAddFilesButton';
import { usePdfPageAssignerHeader } from './usePdfPageAssignerHeader';

const getOrientationButtonClassName = (orientation: PagesOrientationType, viewMode) => (
  `orientation-button${viewMode === orientation ? ' orientation-selected' : ''}`
);

const PdfPageAssignerHeader = () => {
  const { tl } = useContext(LanguageContext);
  const {
    viewMode,
    onChangeViewMode,
    nrAssignedPages,
    nrTotalPages,
    nrAssignedContracts,
    nrTotalContracts,
    propertyAddress,
    onSave,
    onSaveAndClose,
    loading,
  } = usePdfPageAssignerHeader();


  return (
    <Row className="PdfPageAssignerHeader">
      <Col className="orientation-buttons" span={8}>
        <button className={getOrientationButtonClassName(PagesOrientationType.LIST, viewMode)} onClick={() => onChangeViewMode(PagesOrientationType.LIST)}>
          <Icon component={ICONS.listView} />
        </button>
        <button className={getOrientationButtonClassName(PagesOrientationType.GRID, viewMode)} onClick={() => onChangeViewMode(PagesOrientationType.GRID)}>
          <Icon component={ICONS.gridView} />
        </button>
      </Col>


      <Col className="assignment-details" span={8}>
        <div className="main-title">
          {`${tl(translations.pageHeader.labels.assignedPages)}:`}
          <span className="darker">{`${nrAssignedPages} / ${nrTotalPages}`}</span>
          <span className="separator">·</span>
          <span className="darker">{`${nrAssignedContracts} / ${nrTotalContracts}`}</span>
        </div>

        <div className="sub-title">{propertyAddress}</div>
      </Col>


      <Col span={8} className="buttons-container">
        <PdfPageAssignerAddFilesButton buttonType="text" hideIfNoFiles />

        <Button type="default" onClick={() => onSave()} loading={loading}>{tl(translations.pageHeader.buttons.save)}</Button>
        <Button type="primary" onClick={onSaveAndClose} loading={loading}>{tl(translations.pageHeader.buttons.saveAndClose)}</Button>
      </Col>

    </Row>
  );
};

export default PdfPageAssignerHeader;
