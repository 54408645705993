import { ConfigProvider, Table } from 'antd';
import { opsCostTranslation } from 'pages/OpsCostReport/translations';
import SubSectionWrapper from 'storybook-components/sections/SubSectionWrapper/SubSectionWrapper';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import EmptyTableMultilineMessage from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import { isEmpty } from 'lodash';
import React from 'react';
import ExpenseTableSummary from '../components/ExpenseTableSummary';
import { useOtherOpsCostSection as hook } from './useOtherOpsCostsSection';
import DistributionCalculationAlerts from './components/DistributionCalculationAlerts';


type ReturnTypeOfHook = ReturnType<typeof hook>;
type ReturnTypeOfOtherOpsCostsHook = ReturnType<ReturnTypeOfHook['getOtherOpsCostsSectionProps']>


interface OtherOpsCostsSectionProps {
  sectionNumber: number;
  tl: any;
  useOtherOpsCostsSection: ReturnTypeOfOtherOpsCostsHook;
}


const OtherOpsCostsSection = ({
  sectionNumber,
  tl,
  useOtherOpsCostsSection,
}: OtherOpsCostsSectionProps) => {
  const {
    columns,
    accountWithDistributionValueForContract,
    loading,
    totalSummaryData,
    incorrectDirectCosts,
    accountsWithMissingDistributionSets,
    accountDistributions,
    accountsWithZeroValueDistributionSets,
    getAccountDistributionPath,
    getAccountDistributionOverviewPath,
    reportEndDate,
    relevantAccountBalancesExist,
  } = useOtherOpsCostsSection;

  const distributionCalculationAlertsProps = {
    accountDistributions,
    incorrectDirectCosts,
    accountsWithMissingDistributionSets,
    accountsWithZeroValueDistributionSets,
    tl,
    getAccountDistributionPath,
    getAccountDistributionOverviewPath,
    reportEndDate,
    relevantAccountBalancesExist,
  };

  // if the table is empty we need to set the height, otherwise the Empty component will not be visible
  const tableHeight = isEmpty(accountWithDistributionValueForContract) ? { y: 200 } : undefined;

  return (
    <SubSectionWrapper title={`${sectionNumber}.2 ${tl(opsCostTranslation.report.sections.expensesSection.otherOpsCostSubSection.title)}`}>
      <ConfigProvider renderEmpty={() => <EmptyTableMultilineMessage description={tl(opsCostTranslation.report.emptyTable.common)} />}>
        <DistributionCalculationAlerts
          tl={tl}
          distributionCalculationAlertsProps={distributionCalculationAlertsProps}
        />
        <Table
          columns={columns}
          dataSource={accountWithDistributionValueForContract}
          pagination={false}
          scroll={tableHeight}
          rowKey="accountCode"
          loading={{
            indicator: <TableContentLoadingIndicator />,
            spinning: loading,
          }}
          summary={() => (
            <ExpenseTableSummary
              title={tl(opsCostTranslation.report.sections.expensesSection.otherOpsCostSubSection.subtotal)}
              totalSummaryData={totalSummaryData}
              nrTotalColumns={columns.length}
            />
          )}
        />
      </ConfigProvider>
    </SubSectionWrapper>
  );
};

export default OtherOpsCostsSection;
