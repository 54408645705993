import { useContext, useMemo } from 'react';
import { AuthContext } from 'contexts/AuthContext';
import { getEnvVar } from './getEnvVar';

export const useFeatureSettings = () => {
  const { domainId } = useContext(AuthContext);

  const getFeatureSetting = (featureSettingKey: string) => getEnvVar('app.featureSettings', '{}')[featureSettingKey];

  const domainSpecificFeatureSettings = useMemo(() => {
    const wegTenantDisabledDomains = getFeatureSetting('wegTenantDisabledDomains') ?? [];
    const wegOwnedUnitEnabledDomains = getFeatureSetting('wegOwnedUnitDisabledDomains') ?? [];
    const mandateAmendmentConfigEnabledDomains = getFeatureSetting('mandateAmendmentConfigEnabledDomains') ?? [];

    const isWegTenantContractEnabled = !wegTenantDisabledDomains.includes(domainId);
    const isWegOwnedUnitEnabled = !wegOwnedUnitEnabledDomains.includes(domainId);
    const isMandateAmendmentEnabled = mandateAmendmentConfigEnabledDomains.includes(domainId);

    return {
      isWegTenantContractEnabled,
      isWegOwnedUnitEnabled,
      isMandateAmendmentEnabled,
    };
  }, [domainId]);

  const globalFeatureSettings = useMemo(() => {
    const enableSelectingWEGsInOpsCost = getFeatureSetting('enableSelectingWEGsInOpsCost');
    const enableSelectingOpsCostInDK = getFeatureSetting('enableSelectingOpsCostInDK');

    return {
      enableSelectingWEGsInOpsCost,
      enableSelectingOpsCostInDK,
    };
    // leaving `domainId` as dependency to trigger re-calculation on login
  }, [domainId]);

  const featureSettings = {
    ...domainSpecificFeatureSettings,
    ...globalFeatureSettings,
  };

  return featureSettings;
};
