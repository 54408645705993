import { ConfigProvider, Table } from 'antd';
import { opsCostTranslation } from 'pages/OpsCostReport/translations';
import SubSectionWrapper from 'storybook-components/sections/SubSectionWrapper/SubSectionWrapper';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import EmptyTableMultilineMessage from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import { isEmpty } from 'lodash';
import { useDistributionSetSection as hook } from './useDistributionSetSection';

type ReturnTypeOfHook = ReturnType<typeof hook>;
type ReturnTypeOfDistributionSetsHook = ReturnType<ReturnTypeOfHook['getDistributionSetSectionProps']>


interface DistributionSetsSectionProps {
  tl: any;
  useDistributionSetsSection: ReturnTypeOfDistributionSetsHook;
}


const DistributionSetsSection = ({
  tl,
  useDistributionSetsSection,
}: DistributionSetsSectionProps) => {
  const {
    columns,
    distributionSets,
    loading,
  } = useDistributionSetsSection;

  // if the table is empty we need to set the height, otherwise the Empty component will not be visible
  const tableHeight = isEmpty(distributionSets) ? { y: 200 } : undefined;

  return (
    <SubSectionWrapper title={`${tl(opsCostTranslation.report.sections.expensesSection.distributionSetsSubSection.title)}`}>
      <ConfigProvider renderEmpty={() => <EmptyTableMultilineMessage description={tl(opsCostTranslation.report.emptyTable.distributionSets)} />}>
        <Table
          columns={columns}
          dataSource={distributionSets}
          rowKey="name"
          scroll={tableHeight}
          pagination={false}
          loading={{
            indicator: <TableContentLoadingIndicator />,
            spinning: loading,
          }}
        />
      </ConfigProvider>
    </SubSectionWrapper>
  );
};

export default DistributionSetsSection;
