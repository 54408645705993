import React, { useContext, useState } from 'react';
import './ManualExchangeCreation.scss';
import { Prompt, useLocation } from 'react-router';
import { LanguageContext } from 'contexts/LanguageContext';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import { translations } from './translations';
import { SectionWrapper } from '../../storybook-components/sections/SectionWrapper/SectionWrapper';
import SectionMenu, { SectionMenuElement } from '../../components/SectionMenu/SectionMenu';
import ManualExchangeCreationHeaderButtons from './ManualExchangeCreationHeaderButtons';
import ManualExchangeSection1 from './components/ManualExchangeSection1/ManualExchangeSection1';
import { ManualExchangeContextProvider } from './services/ManualExchangeContext';
import ManualExchangeValuesSection from './components/ManualExchangeValuesSection/ManualExchangeValuesSection';
import ManualExchangeOccurrenceSection from './components/ManualExchangeOccurranceSection/ManualExchangeOccurrenceSection';

const EXCHANGE_CREATION_CONTENT_ID = 'exchange-creation-form-content';
export default function ManualExchangeCreation(): JSX.Element {
  const { tl } = useContext(LanguageContext);

  const location = useLocation();
  const [isDirty, setDirty] = useState(false); // TODO: use dirty flag from context

  const menuElements: SectionMenuElement[] = [
    {
      title: tl(translations.creationPage.accountSelectionTitle),
      sectionId: 'exchange-account-selection',
    },
    {
      title: tl(translations.creationPage.occurrenceTitle),
      sectionId: 'exchange-occurrence',
    },
    {
      title: tl(translations.creationPage.valuesTitle),
      sectionId: 'exchange-values',
    },
  ];
  return (
    <ManualExchangeContextProvider>
      <Page className="ManualExchangeCreation">
        <PageHeader
          title={tl(translations.creationPage.title)}
          rightSideComponent={<ManualExchangeCreationHeaderButtons />}
          showLogo
        />
        <PageContent className="page-content">
          <Prompt
            when={isDirty}
            message={loc => (loc.pathname.includes(location.pathname) ? true : tl(translations.creationPage.prompt))}
          />

          <div className="form-content" id={EXCHANGE_CREATION_CONTENT_ID}>
            <SectionWrapper
              sectionNumber={1}
              sectionTitle={tl(translations.creationPage.accountSelectionTitle)}
              sectionId="exchange-account-selection"
            >
              <ManualExchangeSection1 />
            </SectionWrapper>
            <ManualExchangeOccurrenceSection />
            <SectionWrapper
              sectionNumber={3}
              sectionTitle={tl(translations.creationPage.valuesTitle)}
              sectionId="exchange-values"
            >
              <ManualExchangeValuesSection />
            </SectionWrapper>
          </div>
          <SectionMenu
            elements={menuElements}
            scrollTarget={document.getElementById(EXCHANGE_CREATION_CONTENT_ID)}
          />
        </PageContent>
      </Page>
    </ManualExchangeContextProvider>
  );
}
