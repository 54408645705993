import React, { useContext } from 'react';
import Icon from '@ant-design/icons';
import './EmptyResultsHint.scss';
import { ICONS } from '../icons';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translations } from './translations';

interface EmptyResultsHintProps {
  className?: string,
  title?: string,
  subTitle?: string,
  icon?: string,
}


export const EmptyResultsHint: React.FC<EmptyResultsHintProps> = (props) => {
  const {
    className, title, subTitle, icon,
  } = props;

  const { tl } = useContext(LanguageContext);

  return (
    <div
      className={`empty-results ${className ?? ''}`}
    >
      <div className="empty-results-wrapper">
        <div className="circle1" />
        <div className="circle2" />
        <Icon component={icon ? ICONS[icon] : ICONS.magnifyingGlass} />
        <div className="title">
          {title ?? tl(translations.title)}
        </div>
        <div className="sub-title">
          {subTitle ?? tl(translations.subtitle)}
        </div>
      </div>
    </div>
  );
};
