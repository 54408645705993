import { ErrorCodeUsingGETValueEnum } from 'api/accounting';

export const watermarkTranslations = {
  error: {
    uploadWatermarkError: {
      message: {
        en: 'Error while uploading watermark',
        de: 'Fehler beim Hochladen von Briefpapier',
      },
      description: {
        en: 'There was an unexpected error while uploading the selected watermark.',
        de: 'Beim Hochladen des ausgewählten Briefpapiers ist ein unerwarteter Fehler aufgetreten.',
      },
    },
    deleteWatermarkError: {
      message: {
        en: 'Error while deleting watermark.',
        de: 'Fehler beim Löschen des Briefpapiers.',
      },
      description: {
        [ErrorCodeUsingGETValueEnum.DOCUMENT_DOCUMENT_IS_REFERENCED]: {
          en: 'The watermark could not be deleted. It is being used by other processes such as Serial Letter or Owners Meeting Invitation.',
          de: 'Das Briefpapier konnte nicht gelöscht werden. Es wurde in anderen Vorgängen wie Serienbrief oder ETV-Einladung verwendet.',
        },
      },
    },
    loadWatermarkError: {
      en: 'Error while loading watermark.',
      de: 'Fehler beim Laden des Briefpapiers.',
    },
    pdfTooLong: {
      en: 'The uploaded file is invalid. Please upload only a single-page document.',
      de: 'Die hochgeladene Datei ist ungültig. Bitte laden Sie nur ein Dokument mit einer Seite hoch.',
    },
    parseError: {
      en: 'Failed to parse PDF.',
      de: 'Die PDF-Datei konnte nicht verarbeitet werden.',
    },
    saveWatermarkError: {
      message: {
        en: 'Error while saving watermark',
        de: 'Fehler beim Speichern des Briefpapiers',
      },
      description: {
        en: 'There was an unexpected error while saving the selected watermark.',
        de: 'Beim Speichern des ausgewählten Briefpapiers ist ein unerwarteter Fehler aufgetreten.',
      },
    },
  },
  successNotification: {
    saveWatermark: {
      en: 'Watermark saved successfully',
      de: 'Briefpapier erfolgreich gespeichert',
    },
  },
  filename: {
    en: 'Filename',
    de: 'Dateiname',
  },
  name: {
    en: 'Name',
    de: 'Name',
  },
  fileUploadRequirements: {
    en: 'File upload requirements:',
    de: 'Anforderungen für das Hochladen von Dateien',
  },
  pdfFormatFile: {
    en: 'PDF/A-format file.',
    de: 'Datei im PDF/A-Format.',
  },
  singlePageOnly: {
    en: 'Single page only.',
    de: 'Nur eine Seite.',
  },
  followsEpostStandards: {
    en: 'Follows ePost standards.',
    de: 'Folgt ePost-Standards.',
  },
  moreInformationPage: {
    en: 'For more information, feel free to read our ',
    de: 'Für weitere Informationen besuchen Sie bitte unsere ',
  },
  supportPage: {
    en: 'support page.',
    de: 'Support-Seite.',
  },
  replaceFile: {
    en: 'Replace file...',
    de: 'Datei ersetzen...',
  },
  selectFile: {
    en: 'Select file...',
    de: 'Datei aussuchen...',
  },
  delete: {
    en: 'Delete',
    de: 'Löschen',
  },
  noFileYet: {
    en: 'No file yet',
    de: 'Noch keine Datei',
  },
  youCanUploadPdf: {
    en: 'You can upload a PDF/A file',
    de: 'Sie können eine PDF/A-Datei hochladen',
  },
  createTitle: {
    en: 'Upload watermark',
    de: 'Briefpapier hochladen',
  },
  close: {
    en: 'Close',
    de: 'Schließen',
  },
  save: {
    en: 'Save',
    de: 'Speichern',
  },
};
