import { useContext } from 'react';
import { Table } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import { translations } from 'pages/ProfitAndLossReport/translations';
import SubSectionWrapper from 'storybook-components/sections/SubSectionWrapper/SubSectionWrapper';
import ResultTableSubSummary from './ResultTableSubSummary';
import ResultTableSummary from './ResultTableSummary';
import { useVatResultTable } from './useVatResultTable';

const VatResultTable = () => {
  const {
    columns, firstDataSource, firstSum, secondDataSource, secondSum, vatRelevantGermanProperty,
  } = useVatResultTable();
  const { tl } = useContext(LanguageContext);

  if (!vatRelevantGermanProperty) return null;

  return (
    <SubSectionWrapper>
      <Table
        columns={columns}
        dataSource={firstDataSource}
        pagination={false}
        summary={() => (
          <ResultTableSubSummary
            title={tl(translations.report.sections.resultSection.totalCollected)}
            value={firstSum}
            nrTotalColumns={columns.length}
          />
        )}
        rowKey="label"
      />

      <Table
        className="NoHeaderTable"
        columns={columns}
        dataSource={secondDataSource}
        pagination={false}
        summary={() => (
          <ResultTableSummary
            title={tl(translations.report.sections.resultSection.liabilitiesToTaxOffice)}
            value={secondSum}
            nrTotalColumns={columns.length}
          />
        )}
        rowKey="label"
      />
    </SubSectionWrapper>
  );
};

export default VatResultTable;
