import { OwnersMeetingProtocolRecipientExtendedDisplayDtoPresenceEnum } from 'api/accounting';
import { OwnersMeetingProtocolRecipientExtendedDisplayDtoPowerOfAttorneyEnum } from '../../api/accounting';
import { OwnersMeetingProtocolRecipient } from './interfaces';

export const defaultProtocolTexts = {
  meetingDurationProtocolText: 'Die Versammlung wurde vom Versammlungsleiter um {startTime} Uhr eröffnet und um {endTime} Uhr geschlossen.',
  meetingLeaderProtocolText: 'Versammlungsleiter war {meetingLeader}, {meetingLeaderRole} der {managementCompany}.',
  meetingLeaderNoCompanyProtocolText: 'Versammlungsleiter war {meetingLeader}, {meetingLeaderRole}.',

  protocolLeaderProtocolText: 'Die Protokollführung lag bei {minuteTaker}, {minuteTakerRole} der {managementCompany}.',
  protocolLeaderNoCompanyProtocolText: 'Die Protokollführung lag bei {minuteTaker}, {minuteTakerRole}.',
  agendaPointProtocolText: 'Die Tagesordnung ergibt sich aus dem Einladungsschreiben vom {invitationDate}.\n'
      + '\n'
      + 'Die Einladung erfolgte unter Einhaltung der Mindestfrist nach § 24 Abs. 4 WEG. Die Einberufung erfolgte somit ordnungsgemäß.',
  votingRightProtocolTextWithMea: 'Das Stimmrecht der Wohnungseigentümer richtet sich gemäß der Teilungserklärung nach der Höhe des Miteigentumsanteils am Gemeinschaftsgrundstück. Mehrere Eigentümer einer Einheit können nur einheitlich abstimmen. Sofern nicht alle Eigentümer einer Einheit teilnehmen, sind die erschienenen Eigentümer für diese Einheit stimmberechtigt, wenn die nicht erschienenen Miteigentümer der Einheit dem weder schriftlich widersprochen noch anderslautende Vollmachten erteilt haben.',
  votingRightProtocolText: 'Das Stimmrecht der Wohnungseigentümer richtet sich gemäß der Teilungserklärung nach der Anzahl ihrer Einheiten im Objekt. Mehrere Eigentümer einer Einheit können nur einheitlich abstimmen. Sofern nicht alle Eigentümer einer Einheit teilnehmen, sind die erschienenen Eigentümer für diese Einheit stimmberechtigt, wenn die nicht erschienenen Miteigentümer der Einheit dem weder schriftlich widersprochen noch anderslautende Vollmachten erteilt haben.',
  votingRightProtocolTextHeadcount: 'Das Stimmrecht der Wohnungseigentümer richtet sich gemäß der Teilungserklärung nach dem Kopfprinzip. Jeder Wohnungseigentümer hat eine Stimme unabhängig von der Anzahl der Wohnungen. Mehrere Eigentümer einer Einheit können nur einheitlich abstimmen. Sofern nicht alle Eigentümer einer Einheit teilnehmen, sind die erschienenen Eigentümer für diese Einheit stimmberechtigt, wenn die nicht erschienenen Miteigentümer der Einheit dem weder schriftlich widersprochen noch anderslautende Vollmachten erteilt haben.',
  votingRulesProtocolText: 'Die Abstimmung der Beschlüsse wurde {votingScheme} vollzogen.',

  quorumProtocolText: 'Die Versammlung ist beschlussfähig',
};

export const calculateSumBasedOnPresence = (protocolRecipients: OwnersMeetingProtocolRecipient[], key?: keyof OwnersMeetingProtocolRecipient) => {
  if (key) {
    return protocolRecipients.reduce((aggregator: number, protocolRecipient: OwnersMeetingProtocolRecipient) => (
      (protocolRecipient.presence === OwnersMeetingProtocolRecipientExtendedDisplayDtoPresenceEnum.YES
            || (protocolRecipient.presence === OwnersMeetingProtocolRecipientExtendedDisplayDtoPresenceEnum.POWER_OF_ATTORNEY && protocolRecipient.powerOfAttorney && protocolRecipient.powerOfAttorney !== OwnersMeetingProtocolRecipientExtendedDisplayDtoPowerOfAttorneyEnum.NOT_PROVIDED)
      ) && protocolRecipient[key]
        ? aggregator + (protocolRecipient[key] as number) : aggregator
    ), 0);
  }
  return protocolRecipients.reduce((aggregator: number, protocolRecipient: OwnersMeetingProtocolRecipient) => (
    (protocolRecipient.presence === OwnersMeetingProtocolRecipientExtendedDisplayDtoPresenceEnum.YES
          || (protocolRecipient.presence === OwnersMeetingProtocolRecipientExtendedDisplayDtoPresenceEnum.POWER_OF_ATTORNEY && protocolRecipient.powerOfAttorney && protocolRecipient.powerOfAttorney !== OwnersMeetingProtocolRecipientExtendedDisplayDtoPowerOfAttorneyEnum.NOT_PROVIDED)
    )
      ? aggregator + 1 : aggregator
  ), 0);
};

// @ts-ignore
export const calculateTotalSum = (protocolRecipients: OwnersMeetingProtocolRecipient[], key?: string) => protocolRecipients.reduce((aggregator: number, protocolRecipient: OwnersMeetingProtocolRecipient) => (protocolRecipient[key] ? aggregator + protocolRecipient[key] : aggregator), 0);
