import React, { useContext, useRef, useState } from 'react';
import './RichTextEditor.scss';
import { Form } from 'antd';
import moment from 'moment';
import backend, { endpointUrls } from '../../../backend_api';
import { translations } from '../../Translation/translations';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { showNotification } from '../../../lib/Notification';
import { LoadingIndicator } from '../../LoadingIndicator/LoadingIndicator';
import InputProps from '../InputProps';
import { CustomJoditEditor } from './CustomJoditEditor';

export interface RichTextProps extends InputProps<string> {
  buttons?: string
}

export default function RichTextEditor(props: RichTextProps) {
  const { tl, language } = useContext(LanguageContext);
  const editor = useRef(null);
  const {
    value, onChange, label, required, className, buttons, disabled, validationState, validationMessage,
  } = props;
  const [uploading, setUploading] = useState(false);
  const buttonsConfig = buttons || 'source,|,bold,strikethrough,underline,italic,eraser,|,ul,ol,|,outdent,indent,|,align,undo,redo,|,paste,symbol';
  const config = {
    readonly: disabled || false,
    uploader: {
      insertImageAsBase64URI: true,
    },
    buttons: buttonsConfig,
    buttonsMD: buttonsConfig,
    buttonsSM: buttonsConfig,
    buttonsXS: buttonsConfig,
    placeholder: '',
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    defaultActionOnPaste: 'insert_clear_html',
    allowTabNavigation: true,
    tabIndex: 0,
    spellcheck: true,
    language,
  };

  const containsBase64 = (val: string) => {
    const regexp = new RegExp('"data:image\/([a-zA-Z]*);base64,([^"]*)"');
    return val.match(regexp);
  };


  const replaceBase64 = (val: string, base64: string) => {
    base64 = base64.substring(1, base64.length - 1);
    return new Promise((resolve, reject) => {
      fetch(base64)
        .then(res => res.blob())
        .then((blob: Blob) => {
          backend.postFile(`${endpointUrls.DOCUMENT}/upload`, blob, {
            name: `blob-${moment().milliseconds()}`,
            documentType: 'OTHER',
          })
            .then((resp: any) => {
              const result = val.replace(base64, resp.url);
              resolve(result);
            })
            .catch(() => {
              const result = val.replace(base64, '');
              reject(result);
            });
        })
        .catch(() => reject(val));
    });
  };


  const handleChange = (val: any) => {
    const base64 = containsBase64(val);
    if (base64 === null) {
      onChange(val);
    } else {
      setUploading(true);
      replaceBase64(val, base64[0]).then((res: any) => {
        onChange(res);
        setUploading(false);
      }).catch((res) => {
        onChange(res);
        setUploading(false);
        showNotification({
          key: 'imageUploadError',
          message: tl(translations.elements.richTextEditor.imageUploadError),
          type: 'error',
        });
      });
    }
  };

  return (
    <div className={`RichTextEditor ${className || ''} ${disabled ? 'disabled' : ''}`}>
      <Form.Item
        label={`${label || ''}${required ? ' *' : ''}`}
        validateStatus={disabled ? '' : (validationState || 'success')}
        help={disabled ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        {!uploading ? (
        // @ts-ignore
          <CustomJoditEditor value={value} ref={editor} config={config} onChange={handleChange} />
        ) : <LoadingIndicator />
        }
      </Form.Item>
    </div>
  );
}
