import './AgendaItemDetails.scss';

import React, { useContext } from 'react';

import Button from 'elements/Buttons/Button/Button';
import DirtModal from 'elements/Modals/DirtModal/DirtModal';
import { useLocation } from 'react-router';

import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import {
  SectionWrapper,
} from '../../../../../../storybook-components/sections/SectionWrapper/SectionWrapper';
import {
  ownersMeetingInvitationTranslations,
} from '../../../translations/OwnersMeetingInvitationTranslations';
import AgendaItemDetailsContextProvider
  from '../services/AgendaItemDetailsContext';
import { useAgendaItemDetails } from '../services/useAgendaItemDetails';
import AgendaItemDetailsBody from './AgendaItemDetailsBody';

function AgendaItemDetailsView(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const {
    onSaveAgendaItem, onClickClose, onClickSaveAndOpenNext, nextAgendaItem, loading, setSelectedAgendaItem, dirtModalVisible, setDirtModalVisible, goBack,
  } = useAgendaItemDetails();
  const location = useLocation();
  const creationMode = location.pathname.includes('create');
  const isDisabled = location.pathname.includes('view') || loading;

  const headerButtons = (
    <div className="header-buttons">
      <Button
        type="text"
        onClick={onClickClose}
        disabled={loading}
      >
        {tl(ownersMeetingInvitationTranslations.agendaItemDetails.header.close)}
      </Button>
      <Button
        type="ghost"
        onClick={() => onSaveAgendaItem(goBack)}
        disabled={isDisabled}
      >
        {tl(ownersMeetingInvitationTranslations.agendaItemDetails.header.saveAndClose)}
      </Button>
      <Button
        type="primary"
        onClick={() => {
          if (creationMode) {
            // @ts-ignore
            onSaveAgendaItem(() => setSelectedAgendaItem(prev => prev.load({})));
          } else {
            onClickSaveAndOpenNext();
          }
        }}
        disabled={creationMode ? isDisabled : (isDisabled || nextAgendaItem === undefined)}
      >
        {creationMode
          ? tl(ownersMeetingInvitationTranslations.agendaItemDetails.header?.saveAndCreateNew)
          : tl(ownersMeetingInvitationTranslations.agendaItemDetails.header.saveAndOpenNext)}
      </Button>
    </div>
  );

  return (
    <Page className="AgendaItemDetails">
      <PageHeader
        title={`${tl(ownersMeetingInvitationTranslations.agendaItemDetails.title)} `}
        rightSideComponent={headerButtons}
      />
      <DirtModal
        visible={dirtModalVisible}
        onCancel={() => setDirtModalVisible(false)}
        onDiscard={() => {
          setDirtModalVisible(false);
          goBack();
        }}
        onSave={() => {
          onSaveAgendaItem();
          setDirtModalVisible(false);
          goBack();
        }}
        loading={loading}
      />
      <PageContent>
        <SectionWrapper
          sectionId="AgendaItemDetailsBody"
          className="AgendaItemDetailsBody"
        >
          <AgendaItemDetailsBody />
        </SectionWrapper>
      </PageContent>
    </Page>
  );
}

export default function AgendaItemDetails() {
  return (
    <AgendaItemDetailsContextProvider>
      <AgendaItemDetailsView />
    </AgendaItemDetailsContextProvider>
  );
}
