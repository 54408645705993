import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import moment from 'moment';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import DEFAULT_DATA from '../lib/data';
import { LanguageContext } from './LanguageContext';
import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import { NotificationObject, showNotification } from '../lib/Notification';
import {
  DirectDebitControllerApi,
  DirectDebitDto,
  DirectDebitFilterDtoStatesEnum,
  OrderStateDtoStateEnum,
  SepaExecutionSummary,
} from '../api/accounting';
import { AuthContext } from './AuthContext';

const PAGE_SIZE = 30;

export const DirectDebitListContext: any = React.createContext({});

export default function DirectDebitListProvider({ children }: any): JSX.Element {
  const { apiConfiguration } = useContext(AuthContext);
  const directDebitControllerApi = new DirectDebitControllerApi(apiConfiguration('accounting'));
  const { tl } = useContext(LanguageContext);
  const { overlays } = useContext(OverlayContext);

  const [directDebitListState, setDirectDebitListState] = useState(DEFAULT_DATA<DirectDebitDto[]>([]));
  const directDebitListRef = useRef<DirectDebitDto[]>();
  directDebitListRef.current = directDebitListState.data as DirectDebitDto[];
  const defaultFilters = useMemo(() => ({
    states: [DirectDebitFilterDtoStatesEnum.NEW],
    maxExecutionDate: moment().add(5, 'days').toISOString(),
  }), []);
  const [directDebitFilterState, setDirectDebitFilterState] = useState<any>({});
  const [initialFilterUpdate, setInitialFilterUpdate] = useState(true);
  const lastRequestTimestamp = useRef<number | null>(null);

  const defaultDirectDebitSortState = {
    field: 'propertyIdInternal',
    order: 1,
  };

  const [directDebitSortState, setDirectDebitSortState] = useState(DEFAULT_DATA<any>(defaultDirectDebitSortState));
  const [initialSortUpdate, setInitialSortUpdate] = useState(true);
  const [sendAmendMandateInfoToBank, setSendAmendMandateInfoToBank] = useState<boolean>(true);
  // FIXME: Quickfix for double page loading
  const page = useRef<number>(-1);

  const [downloadData, setDownloadData] = useState(DEFAULT_DATA<SepaExecutionSummary>({}));

  useEffect(() => {
    if (overlays.length === 1) {
      onLoadDirectDebitList(true);
    }
  }, [overlays.length]);

  useEffect(() => {
    if (!initialFilterUpdate) {
      onLoadDirectDebitList(true);
    } else {
      setInitialFilterUpdate(false);
    }
  }, [directDebitFilterState]);

  useEffect(() => {
    if (!initialSortUpdate) {
      onLoadDirectDebitList(true);
    } else {
      setInitialSortUpdate(false);
    }
  }, [directDebitSortState]);

  const setSortField = (field: string) => {
    setDirectDebitSortState(sortState => sortState.load({
      field,
      order: sortState.data.field === field ? sortState.data.order * (-1) : 1,
    }));
  };

  const getFilter = () => ({
    ...directDebitFilterState,
    minExecutionDate: directDebitFilterState.minExecutionDate ? moment(directDebitFilterState.minExecutionDate).format('YYYY-MM-DD') : undefined,
    maxExecutionDate: directDebitFilterState.maxExecutionDate ? moment(directDebitFilterState.maxExecutionDate).format('YYYY-MM-DD') : undefined,
  });

  const onLoadDirectDebitList = (resetPage: boolean = false, sort: any = directDebitSortState.data) => {
    // If management company is not specified, set loading to true and wait for update
    if (!directDebitFilterState.managementCompanyId) {
      setDirectDebitListState(prevState => prevState.startLoading());
      return;
    }
    const currentTimestamp = new Date().getTime();
    lastRequestTimestamp.current = currentTimestamp;

    setDirectDebitListState(state => state.startLoading());
    page.current = resetPage ? 0 : page.current + 1;
    backend.get(`${endpointUrls.DIRECT_DEBITS}/filter`,
      {
        ...getFilter(),
        page: page.current,
        size: PAGE_SIZE,
        order: sort.order > 0 ? 'ASC' : 'DESC',
        sort: sort.field,
      })
      .then((response: any) => {
        // do nothing if this is a response for an older request
        if (currentTimestamp !== lastRequestTimestamp.current) return;

        const directDebits = response.content;
        setDirectDebitListState((state) => {
          state.lastPage = response.last;
          return state.load(resetPage ? directDebits : (state.data as DirectDebitDto[]).concat(directDebits));
        });
      })
      .catch((err) => {
        page.current -= 1;
        console.error(err);
        setDirectDebitListState(state => state.failed());
        showNotification({
          key: 'loadDirectDebitListError',
          message: tl(translations.notifications.directDebitListContext.loadError.message),
          description: tl(translations.notifications.directDebitListContext.loadError.description),
          type: 'error',
        });
      });
  };

  const changeState = async (directDebitIds: number[], directDebitState: OrderStateDtoStateEnum) => {
    const successNotification: NotificationObject = {
      key: 'resetSuccess',
      message: tl(translations.notifications.paymentListContext.stateChange.success),
      type: 'success',
    };
    const errorNotification: NotificationObject = {
      key: 'resetError',
      message: tl(translations.notifications.paymentListContext.stateChange.error),
      type: 'error',
    };
    try {
      await directDebitControllerApi.changeStateUsingPATCH({
        ...getFilter(),
        orderIds: directDebitIds,
        orderStateDto: {
          state: directDebitState,
        },
      });
      showNotification(successNotification);
      onLoadDirectDebitList(true);
    } catch (error) {
      showNotification(errorNotification);
    }
  };

  const onSetToNew = async (ids: number[]) => changeState(ids, OrderStateDtoStateEnum.NEW);
  const onDelete = async (ids: number[]) => changeState(ids, OrderStateDtoStateEnum.DELETED);
  const onMarkAsSent = async (ids: number[]) => changeState(ids, OrderStateDtoStateEnum.SENT);

  const onDownloadAllAsEbics = (nrOfTransactions: number, totalAmount: number) => {
    const filename = `${moment().format('YYYY-MM-DD')}-SEPA-Lastschrift-${nrOfTransactions}-${totalAmount}EUR.xml`;
    const filter = getFilter();
    // the backend expects a list so we transform it ad-hoc
    if (filter.propertyIdInternalList) {
      filter.propertyIdInternalList = filter.propertyIdInternalList.toString().split(',');
    }
    downloadEbicsByFilter(filter, nrOfTransactions, totalAmount);
  };

  const downloadEbicsByFilter = (filter, nrOfTransactions: number, totalAmount: number, successCallback?: () => {}) => {
    const filename = `${moment().format('YYYY-MM-DD')}-SEPA-Lastschrift-${nrOfTransactions}-${totalAmount}EUR.xml`;
    backend.getFileUsingPost(`${endpointUrls.SEPA_DIRECT_DEBITS}/export-ebics`, {
      filter,
      sendAmendMandateInfoToBank,
    })
      .then((response: any) => {
        const blob: any = new Blob([response], { type: 'application/xml' });
        FileSaver.saveAs(blob, filename);
        onLoadDirectDebitList(true);
        if (successCallback) {
          successCallback();
        }
      })
      .catch((e: any) => {
        console.error(e);
        showNotification({
          key: 'fileDownloadError',
          message: tl(translations.elements.fileUpload.downloadError.message),
          type: 'error',
        });
      });
  };

  const onDownloadDirectDebitsByIdAsEbics = (directDebitIds: number[], nrOfTransactions: number, totalAmount: number, successCallback: () => {}) => {
    downloadEbicsByFilter({ orderIds: directDebitIds }, nrOfTransactions, totalAmount, successCallback);
  };

  const onRepairEbicsUpload = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setDirectDebitListState(prevState => prevState.startLoading());

      const fileReader = new FileReader();
      fileReader.addEventListener('error', (error) => {
        onUploadError(error);
      });
      fileReader.addEventListener('load', (e: any) => {
        fetch(e.target.result)
          .then((resp) => {
            resp.blob()
              .then((blob) => {
                backend.postFile(`${endpointUrls.SEPA_DIRECT_DEBITS}/repair-ebics`, blob, {})
                  .then(() => {
                    onClearDirectDebitList();
                  })
                  .catch((error) => {
                    onUploadError(error);
                  });
              })
              .catch((error) => {
                onUploadError(error);
              });
          })
          .catch((error) => {
            onUploadError(error);
          });
      });
      fileReader.readAsDataURL(event.target.files[0]);
    }
  };

  const onUploadError = (error: any) => {
    console.error(typeof error);
    onLoadDirectDebitList(true);
    showNotification({
      key: 'uploadRepairEbicsFile',
      message: tl(translations.notifications.paymentListContext.uploadError.message),
      description: tl(translations.notifications.paymentListContext.uploadError.description),
      type: 'error',
    });
  };

  const onClearDirectDebitList = (): void => {
    page.current = -1;
    setDirectDebitListState(DEFAULT_DATA<DirectDebitDto[]>([]));
  };

  const onClearDirectDebitFilterState = (): void => {
    setDirectDebitFilterState(defaultFilters);
  };

  const updateFilterState = (data: object) => {
    setDirectDebitFilterState((state: any) => ({
      ...state,
      ...data,
    }));
  };


  const onLoadEbicsDownloadDataByFilters = (successCallback: () => void) => {
    const filter = getFilter();
    setDownloadData(prevState => prevState.startLoading());
    backend.get(`${endpointUrls.DIRECT_DEBITS}/ebics-download-data-by-filter`, { ...filter })
      .then((response: any) => {
        setDownloadData(prevState => prevState.load(response));
        successCallback();
      })
      .catch((e) => {
        console.error(e);
        if (e.status === 422) {
          showNotification({
            key: 'fileDownloadError',
            message: tl(translations.notifications.paymentListContext.loadDownloadDataError.message),
            description: tl(translations.notifications.paymentListContext.loadDownloadDataError.description),
            type: 'error',
          });
        } else {
          showNotification({
            key: 'fileDownloadError',
            message: tl(translations.notifications.paymentListContext.loadDownloadDataError.message),
            type: 'error',
          });
        }
        setDownloadData(prevState => prevState.failed());
      });
  };

  const onSetDefaultFilterFromQueryParams = (searchParams: { [key: string]: any }) => {
    setDirectDebitFilterState(prev => ({
      ...prev,
      ...searchParams,
    }));
  };

  return (
    <DirectDebitListContext.Provider value={{
      directDebitListState,
      directDebitFilterState,
      setDirectDebitListState,
      setDirectDebitFilterState,
      onSetDefaultFilterFromQueryParams,
      directDebitSortState,
      updateFilterState,
      setSortField,
      onLoadDirectDebitList,
      onClearDirectDebitList,
      onClearDirectDebitFilterState,
      onDownloadAllAsEbics,
      onRepairEbicsUpload,
      onSetToNew,
      onDelete,
      onMarkAsSent,
      sortField: directDebitSortState.data.field,
      sortOrder: directDebitSortState.data.order,
      lastPage: directDebitListState.lastPage,
      defaultFilters,
      onDownloadDirectDebitsByIdAsEbics,
      downloadData,
      onLoadEbicsDownloadDataByFilters,
      sendAmendMandateInfoToBank,
      setSendAmendMandateInfoToBank,
    }}
    >
      {children}
    </DirectDebitListContext.Provider>
  );
}

DirectDebitListProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
