import { FinApiUserControllerApi, FinApiUserPublicDto } from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';

export const useFinApiUsers = () => {
  const { apiConfiguration } = useContext(AuthContext);
  const finApiUserControllerApi = new FinApiUserControllerApi(apiConfiguration('accounting'));

  const [finApiUsers, setFinApiUsers] = useState<FinApiUserPublicDto[] | undefined>();


  useEffect(() => {
    loadFinApiUsers();
  }, []);


  const loadFinApiUsers = async () => {
    finApiUserControllerApi.getUsersUsingGET()
      .then(resp => setFinApiUsers(resp))
      .catch((err) => {
        console.error(err);
        setFinApiUsers([]);
      });
  };

  const finapiEnabled = finApiUsers && !!finApiUsers.length;

  return { finApiUsers, finapiEnabled };
};
