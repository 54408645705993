import { Col, Row } from 'antd';
import SearchBar from 'storybook-components/SearchBar/SearchBar';
import { useDocumentSearchBar } from './useDocumentSearchBar';
import './DocumentSearchBar.scss';

const DocumentSearchBar = () => {
  const { searchInputValue, onChangeSearchValue } = useDocumentSearchBar();

  return (
    <Row className="MessageDocumentsSearchBar">
      <Col span={24}>
        <SearchBar
          searchValue={searchInputValue}
          onSearch={onChangeSearchValue}
        />
      </Col>
    </Row>
  );
};

export default DocumentSearchBar;
