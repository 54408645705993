import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  BankConnectionImportDtoConnectionInterfaceEnum,
  FinApiUserControllerApi,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import _, { isEmpty } from 'lodash';

import { getEnvVar } from 'lib/getEnvVar';
import * as config from '../../../config';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { showNotification } from '../../../lib/Notification';
import { translations } from '../translations';

export const useFinApiBankOptions = () => {
  const { tl } = useContext(LanguageContext);

  const [finApiToken, setFinApiToken] = useState('');
  const [bankOptionsLoading, setBankOptionsLoading] = useState(true);
  const [lastResponseLength, setLastResponseLength] = useState(0);
  const [bankOptions, setBankOptions] = useState([]);
  const [bankConnectionInterfaceOptions, setBankConnectionInterfaceOptions] = useState({});
  const [page, setPage] = useState(1);
  const { apiConfiguration } = useContext(AuthContext);
  const finApiUserControllerApi = new FinApiUserControllerApi(apiConfiguration('accounting'));

  const finapiBaseURL = getEnvVar('finapi.apiBaseUrl', 'https://mocks.app.impower.de/services/finapi');

  const buildFetchOptions = (token: string) => {
    const fetchOptions: any = {
      method: 'GET',
      mode: 'cors',
      redirect: 'follow',
      referrer: 'no-referrer',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    return fetchOptions;
  };

  useEffect(() => {
    finApiUserControllerApi.getUsersUsingGET()
      .then((users) => {
        if (isEmpty(users)) {
          return;
        }

        finApiUserControllerApi.authenticateUsingPOST1({})
          .then(({ token }) => {
            setFinApiToken(token);
          })
          .catch(() => {
            showNotification({
              key: 'finApiAuthError',
              message: tl(translations.notifications.finApiAuthError.message),
              type: 'error',
            });
          });
      });
  }, []);

  useEffect(() => {
    if (!isEmpty(finApiToken) && (page === 1 || lastResponseLength !== 0)) {
      setBankOptionsLoading(true);
      fetch(`${finapiBaseURL}/api/v1/banks?perPage=500&page=${page}`, buildFetchOptions(finApiToken))
        .then((resp) => {
          resp.json().then((json) => {
            setLastResponseLength(json.banks.length);
            setBankOptions((prev) => {
              const additionalBankOptions = json.banks
                .filter(bank => !bank.isTestBank)
                .map((bank) => {
                  setBankConnectionInterfaceOptions((prev) => {
                    prev[bank.id] = [];
                    (bank.interfaces || []).forEach((bankInterface) => {
                      let intf;
                      if (bankInterface.interface === 'XS2A') {
                        intf = { label: 'XS2A - BaFin Standard', value: BankConnectionImportDtoConnectionInterfaceEnum.XS2A };
                      } else if (bankInterface.interface === 'FINTS_SERVER') {
                        intf = { label: 'FINTS_SERVER - Legacy', value: BankConnectionImportDtoConnectionInterfaceEnum.FINTS_SERVER };
                      }
                      if (intf) {
                        if (bankInterface.health !== 100) {
                          intf.label += ` (Funktional: ${bankInterface.health}%)`;
                          console.log(bank.name, intf.label);
                        }
                        prev[bank.id].push(intf);
                      }
                    });
                    return prev;
                  });

                  const blzs = !_.isEmpty(bank.blzs) ? `BLZ: ${bank.blzs.join(', ')}` : '';
                  const bic = !_.isEmpty(bank.bic) ? `; BIC: ${bank.bic}` : '';
                  const isBeta = bank.isBeta ? ' [BETA]' : '';

                  const bankOptionLabel = `${bank.name} (${blzs}${bic}${isBeta})`;
                  return { key: bank.id, value: bank.id, label: bankOptionLabel };
                });
              return prev.concat(additionalBankOptions);
            });
            setPage(prev => (prev + 1));
          })
            .catch(() => {
              showNotification({
                key: 'loadFinApiBankListError',
                message: tl(translations.notifications.bankListImportLoadError.message),
                type: 'error',
              });
            });
        })
        .catch(() => {
          showNotification({
            key: 'loadFinApiBankListError',
            message: tl(translations.notifications.bankListImportLoadError.message),
            type: 'error',
          });
        });
    } else {
      setBankOptionsLoading(false);
    }
  }, [finApiToken, page]);

  return {
    bankOptions,
    bankOptionsLoading,
    bankConnectionInterfaceOptions,
  };
};
