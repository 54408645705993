/* eslint-disable consistent-return */
import { LanguageContext } from 'contexts/LanguageContext';
import { PropertyDistributionKeyListContext } from 'contexts/PropertyDistributionKeyListContext';
import { PropertyListContext } from 'contexts/PropertyListContext';
import _ from 'lodash';
import moment from 'moment';
import {
  useContext, useEffect, useState,
} from 'react';
import {
  Link, useHistory, useLocation, useParams,
} from 'react-router-dom';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import { DISTRIBUTION_FOR_HGA_EP, DISTRIBUTION_FOR_OPS_COST } from 'pages/DistributionKeys/AccountDistributionEditor/services/utils';
import { useFeatureSettings } from 'lib/useFeatureSettings';
import { translations } from '../translations';
import { useLoadPropertyDistributionKeyList } from './useLoadPropertyDistributionKeyList';


export const usePropertyDistirbutionKeys = () => {
  const { tl } = useContext(LanguageContext);
  const propertyListContext: any = useContext(PropertyListContext);
  const location = useLocation();
  const params: any = useParams();
  const { propertyHrId } = params;
  const propertyDistributionKeyListContext: any = useContext(PropertyDistributionKeyListContext);
  const [selectedDistributionKey, setSelectedDistributionKey] = useState<any>();
  const [modalVisible, setModalVisible] = useState(false);
  const { enableSelectingOpsCostInDK } = useFeatureSettings();


  const {
    setSelectedEconomicYear,
    setPropertyId,
    setPropertyHrId,
    onLoadYearsWithDistributionKeys,
    yearsWithDistributionKeys,
    yearsWithDistributionKeysLoaded,
    selectedEconomicYear,
  } = propertyDistributionKeyListContext;

  const { onLoadPropertyDistributionKeys } = useLoadPropertyDistributionKeyList();

  const history = useHistory();
  const { search } = useLocation();
  const { goBack } = useContext(OverlayContext);
  const urlSearchParams = new URLSearchParams(search);
  const economicYear = parseInt(urlSearchParams.get('economicYear'), 10);
  const distributionFor = urlSearchParams.get('distributionFor');


  useEffect(() => {
    const currentYear = moment().get('year');
    if (!yearsWithDistributionKeysLoaded) {
      return;
    }

    if (yearsWithDistributionKeys.length === 0) {
      setSelectedEconomicYear(currentYear);
      return;
    }

    if (economicYear && yearsWithDistributionKeys.includes(economicYear)) {
      setSelectedEconomicYear(economicYear);
    } else if (yearsWithDistributionKeys.includes(currentYear)) {
      setSelectedEconomicYear(currentYear);
    } else {
      setSelectedEconomicYear(_.last(yearsWithDistributionKeys));
    }

    return () => setSelectedEconomicYear(undefined);
  }, [economicYear, yearsWithDistributionKeys, yearsWithDistributionKeysLoaded]);


  useEffect(() => {
    if (propertyHrId) {
      propertyListContext.setSelectedPropertyHrId(propertyHrId);
    }
    return () => {
      propertyListContext.setSelectedPropertyHrId(null);
      propertyListContext.setSelectedProperty(prev => prev.load(null));
    };
  }, [propertyHrId]);

  useEffect(() => {
    if (propertyListContext.selectedProperty.data
      && Object.keys(propertyListContext.selectedProperty.data).length > 0) {
      setPropertyId(propertyListContext.selectedProperty.data.id);
      setPropertyHrId(propertyListContext.selectedProperty.data.propertyHrId);
      onLoadYearsWithDistributionKeys(propertyListContext.selectedProperty.data.id);
    }
  }, [propertyListContext.selectedProperty]);

  const propertyLoaded = !propertyListContext.selectedProperty?.loading && propertyListContext.selectedProperty?.data?.propertyHrId === propertyHrId;

  useEffect(() => {
    if (propertyLoaded && propertyListContext.selectedProperty?.data?.id) {
      onLoadPropertyDistributionKeys(propertyListContext.selectedProperty?.data?.id);
    }
  }, [location?.pathname, selectedEconomicYear, propertyLoaded, propertyListContext.selectedProperty?.data?.id, distributionFor]);

  const onCopy = (record: any) => {
    setModalVisible(true);
    setSelectedDistributionKey(record);
  };

  const setEconomicYear = (value) => {
    setSelectedEconomicYear(value);
    let urlWithParam = `${history.location.pathname}?economicYear=${value}`;
    if (distributionFor) {
      urlWithParam = `${urlWithParam}&distributionFor=${distributionFor}`;
    }
    history.push(urlWithParam);
  };


  const onClose = () => {
    goBack();
  };

  const getPathNameForDistributionFor = (distributionForValue: string) => `${history.location.pathname}?economicYear=${economicYear}&distributionFor=${distributionForValue}`;


  const segmentedOptions = [
    {
      value: DISTRIBUTION_FOR_HGA_EP,
      label: (
        <Link
          className="link-segmented-label"
          to={getPathNameForDistributionFor(DISTRIBUTION_FOR_HGA_EP)}
        >
          {tl(translations.propertyDistributionKey.hga) }
        </Link>),
    },
    {
      value: DISTRIBUTION_FOR_OPS_COST,
      label: (
        <Link
          className="link-segmented-label"
          to={getPathNameForDistributionFor(DISTRIBUTION_FOR_OPS_COST)}
        >
          {tl(translations.propertyDistributionKey.opsCost) }
        </Link>),
    },
  ];

  return {
    selectedDistributionKey,
    modalVisible,
    administrationType: propertyListContext.selectedProperty?.data?.administrationType,
    selectedProperty: propertyListContext.selectedProperty.data,
    selectedEconomicYear,
    yearsWithDistributionKeys,
    setSelectedEconomicYear: setEconomicYear,
    propertyHrId,
    onCopy,
    onClose,
    propertyLoaded,
    setModalVisible,
    segmentedOptions,
    segmentedValue: distributionFor ?? DISTRIBUTION_FOR_HGA_EP,
    isDistributionForOpsCost: distributionFor === DISTRIBUTION_FOR_OPS_COST,
    enableSelectingOpsCostInDK,
  };
};
