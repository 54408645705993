import { ColumnsType } from 'antd/lib/table';
import { ExtendedAccountBalanceDto } from 'api/accounting';
import { LanguageContext } from 'contexts/LanguageContext';
import { formatCurrency } from 'lib/Utils';
import { ProfitAndLossReportContext } from 'pages/ProfitAndLossReport/ProfitAndLossReportEditing/services/ProfitAndLossReportContext';
import { useContext, useMemo } from 'react';
import { translations } from 'pages/ProfitAndLossReport/translations';
import Amount from 'storybook-components/typography/Amount/Amount';


const COLUMN1_WIDTH = '12%';
const AMOUNT_COLUMNS_WIDTH = '13%';

interface OpenTenantAccountDatasource extends ExtendedAccountBalanceDto {
  shouldPay?: number,
  paid?: number,
}
export const useOpenTenantAccountsTable = () => {
  const profitAndLossReportContext = useContext(ProfitAndLossReportContext);
  const { tl } = useContext(LanguageContext);

  if (profitAndLossReportContext === undefined) {
    throw new Error('useOpenTenantAccountsTable must be used within a ProfitAndLossReportContextProvider');
  }

  const { accountBalances, accountBalancesByCounterpartIncome, accountBalancesByCounterpartNotIncome } = profitAndLossReportContext;

  const getParentAccountName = (account: ExtendedAccountBalanceDto) => {
    const parentCode = account.accountCode.match(/^2001\/\d+/)?.[0];
    const parentAccount = accountBalances.data[parentCode];
    return parentAccount?.accountName || '';
  };

  const groupAccountsByCodeMatcher = (accounts: ExtendedAccountBalanceDto[]) => {
    const groupedResult: OpenTenantAccountDatasource[] = [];
    accounts.forEach((account: ExtendedAccountBalanceDto) => {
      const codeMatcher = account.accountCode.replace(/^2001\/\d+/, '2001/x');
      // accountBalancesByCounterpartIncome & accountBalancesByCounterpartNotIncome are only loaded for 2001/x accounts, so initial filtering is not needed
      const balanceByIncomeCounterpart = accountBalancesByCounterpartIncome.data?.find(ab => ab.accountCode === account.accountCode);
      const balanceByNotIncomeCounterpart = accountBalancesByCounterpartNotIncome.data?.find(ab => ab.accountCode === account.accountCode);
      const idx = groupedResult.findIndex(a => a.accountCode === codeMatcher);
      if (idx !== -1) {
        groupedResult[idx].creditAmountWithinDateRange += account.creditAmountWithinDateRange;
        groupedResult[idx].debitAmountWithinDateRange += account.debitAmountWithinDateRange;
        groupedResult[idx].normalizedBalanceWithinDateRange += account.normalizedBalanceWithinDateRange;
        groupedResult[idx].normalizedBalanceBeforeDateRange += account.normalizedBalanceBeforeDateRange;
        groupedResult[idx].normalizedBalanceAtEndOfDateRange += account.normalizedBalanceAtEndOfDateRange;
        groupedResult[idx].shouldPay += balanceByIncomeCounterpart?.balance;
        groupedResult[idx].paid += balanceByNotIncomeCounterpart?.balance;
      } else {
        const parentName = getParentAccountName(account);

        groupedResult.push({
          ...account,
          accountCode: codeMatcher,
          accountName: account.accountName.replace(parentName, ''),
          shouldPay: balanceByIncomeCounterpart?.balance,
          paid: balanceByNotIncomeCounterpart?.balance,
        });
      }
    });
    return groupedResult;
  };

  // Build up the accountGroups for each account and than separate the 2001/X/4 from the rest
  // for now let's only memoize where we have to iterate through accountBalances.data;
  // and memoize the columns/sums only if we run into performance issues
  const accountGroupBalances = useMemo(() => {
    if (!accountBalances.loaded || !accountBalancesByCounterpartIncome.loaded || !accountBalancesByCounterpartNotIncome.loaded) return [];

    const matchingAccountCodes = Object.keys(accountBalances.data)
      .filter(accCode => (
        accCode.match(/^2001\/.*$/)
        && accountBalances.data[accCode].leaf
        // don't filter out the accounts with 0 balance because than the credit and debit of the group will not be correct
      ));

    // only filter out the groups after they are added up
    // because if we filter the accounts separately we might get incorrect debit and credit values for the groups
    const ds = groupAccountsByCodeMatcher(matchingAccountCodes.map(code => accountBalances.data[code]))
      .filter(accountGroup => accountGroup.normalizedBalanceWithinDateRange !== 0
        || accountGroup.normalizedBalanceAtEndOfDateRange !== 0)
      .sort((a, b) => a.accountCode.localeCompare(b.accountCode));

    return ds;
  }, [accountBalances.data, accountBalancesByCounterpartIncome.data, accountBalancesByCounterpartNotIncome.data]);

  const firstDataSource = accountGroupBalances.filter(group => group.accountCode !== '2001/x/4');

  // to only iterate through the firstDataSource once we calculate them all in one go
  const firstSums = firstDataSource.reduce((acc, curr) => ({
    shouldPaySum: acc.shouldPaySum + curr.shouldPay,
    paidSum: acc.paidSum + curr.paid,
    balanceWithinDateRangeSum: acc.balanceWithinDateRangeSum + curr.normalizedBalanceWithinDateRange,
    startBalanceSum: acc.startBalanceSum + curr.normalizedBalanceBeforeDateRange,
    endBalanceSum: acc.endBalanceSum + curr.normalizedBalanceAtEndOfDateRange,
  }), {
    shouldPaySum: 0,
    paidSum: 0,
    balanceWithinDateRangeSum: 0,
    startBalanceSum: 0,
    endBalanceSum: 0,
  });

  const secondDataSource = accountGroupBalances.filter(group => group.accountCode === '2001/x/4');

  // to only iterate through the secondDataSource once we calculate them all in one go
  const secondSums = secondDataSource.reduce((acc, curr) => ({
    shouldPaySum: acc.shouldPaySum + curr.shouldPay,
    paidSum: acc.paidSum + curr.paid,
    balanceWithinDateRangeSum: acc.balanceWithinDateRangeSum + curr.normalizedBalanceWithinDateRange,
    startBalanceSum: acc.startBalanceSum + curr.normalizedBalanceBeforeDateRange,
    endBalanceSum: acc.endBalanceSum + curr.normalizedBalanceAtEndOfDateRange,
  }), {
    // Add the summary of the first table too
    shouldPaySum: firstSums.shouldPaySum,
    paidSum: firstSums.paidSum,
    balanceWithinDateRangeSum: firstSums.balanceWithinDateRangeSum,
    startBalanceSum: firstSums.startBalanceSum,
    endBalanceSum: firstSums.endBalanceSum,
  });

  const formatToCurrencyWithoutSymbol = (num: number) => <Amount>{formatCurrency(num, '-', false)}</Amount>;

  const columns: ColumnsType<OpenTenantAccountDatasource> = [
    {
      title: tl(translations.report.sections.openTenantAccountsSection.columns.accountCode),
      dataIndex: 'accountCode',
      width: COLUMN1_WIDTH,
    },
    {
      title: tl(translations.report.sections.openTenantAccountsSection.columns.accountName),
      dataIndex: 'accountName',
    },
    {
      title: tl(translations.report.sections.openTenantAccountsSection.columns.startBalance),
      dataIndex: 'normalizedBalanceBeforeDateRange',
      className: 'column-align-right no-wrap-td',
      render: formatToCurrencyWithoutSymbol,
      width: AMOUNT_COLUMNS_WIDTH,
    },
    {
      title: tl(translations.report.sections.openTenantAccountsSection.columns.debit),
      dataIndex: 'shouldPay',
      className: 'column-align-right no-wrap-td',
      render: formatToCurrencyWithoutSymbol,
      width: AMOUNT_COLUMNS_WIDTH,
    },
    {
      title: tl(translations.report.sections.openTenantAccountsSection.columns.credit),
      dataIndex: 'paid',
      className: 'column-align-right no-wrap-td',
      render: (num: number) => <Amount>{formatCurrency((-1 * num), '-', false)}</Amount>,
      width: AMOUNT_COLUMNS_WIDTH,
    },
    {
      title: tl(translations.report.sections.openTenantAccountsSection.columns.balanceWithinPeriod),
      dataIndex: 'normalizedBalanceWithinDateRange',
      className: 'column-align-right no-wrap-td multiline-table-header',
      render: formatToCurrencyWithoutSymbol,
      width: AMOUNT_COLUMNS_WIDTH,
    },
    {
      title: tl(translations.report.sections.openTenantAccountsSection.columns.endBalance),
      dataIndex: 'normalizedBalanceAtEndOfDateRange',
      className: 'column-align-right no-wrap-td',
      render: formatToCurrencyWithoutSymbol,
      width: AMOUNT_COLUMNS_WIDTH,
    },
  ];


  return {
    columns,
    firstDataSource,
    firstPaidSum: -1 * firstSums.paidSum,
    firstShouldPaySum: firstSums.shouldPaySum,
    firstBalanceWithinDateRangeSum: firstSums.balanceWithinDateRangeSum,
    firstStartBalanceSum: firstSums.startBalanceSum,
    firstEndBalanceSum: firstSums.endBalanceSum,
    secondDataSource,
    secondPaidSum: -1 * secondSums.paidSum,
    secondShouldPaySum: secondSums.shouldPaySum,
    secondBalanceWithinDateRangeSum: secondSums.balanceWithinDateRangeSum,
    secondStartBalanceSum: secondSums.startBalanceSum,
    secondEndBalanceSum: secondSums.endBalanceSum,
  };
};
