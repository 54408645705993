import React, { useContext } from 'react';
import { SectionWrapper } from '../../../../storybook-components/sections/SectionWrapper/SectionWrapper';
import { economicPlanHouseMoneyTranslations } from '../economicPlanHouseMoneyTranslations';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { HouseMoneyTable } from '../../components/HouseMoneyTable/HouseMoneyTable';
import { useSectionManager } from '../../../../services/SectionManager/useSectionManager';
import { useDirtModalNavigation } from '../services/useDirtModalNavigation';
import Button from '../../../../elements/Buttons/Button/Button';
import { translations } from '../../../../elements/Translation/translations';
import { useSaveUnitFiguresEconomicPlan } from '../services/useSaveUnitFiguresEconomicPlan';

export const HouseMoneyTableSection = () => {
  const { tl } = useContext(LanguageContext);
  const { openSectionId } = useSectionManager();
  const { handleOpenSectionChange } = useDirtModalNavigation();

  const {
    saveAndOpenNextSection,
    canSave,
  } = useSaveUnitFiguresEconomicPlan();

  return (
    <SectionWrapper
      sectionId="EconomicPlanHouseMoneyTable"
      sectionTitle={tl(economicPlanHouseMoneyTranslations.unitAmounts)}
      sectionNumber={2}
      isOpen={openSectionId === 1}
      onClick={() => handleOpenSectionChange(1)}
      footer={(
        <Button type="ghost" onClick={saveAndOpenNextSection} disabled={!canSave}>
          {tl(translations.elements.section.save)}
        </Button>
      )}
    >
      <HouseMoneyTable />
    </SectionWrapper>
  );
};
