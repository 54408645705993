import { Col, Row } from 'antd';
import {
  ContractLegacyControllerApi, GetContractsUsingGETTypeEnum, GetUnitContractsUsingGETContractTypesEnum, UnitContractControllerApi, UnitContractProjectionDto,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';
import { showNotification } from 'lib/Notification';
import { isEmpty } from 'lodash';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useScoreStringSearch } from 'services/search/useScoreStringSearch';
import { contractSelectorTranslations } from './translations';

// either provide contracts directly and do not provide filters for the contracts (OR see below)
interface ContractSelectorWithOptions extends ContractSelectorCommonProps {
  contracts: UnitContractProjectionDto[],
  showVacant?: never,
  propertyId?: never,
  contractFilters?: never,
}

// OR provide filters for gettings the contracts but do not provide the contracts directly (see above)
interface ContractSelectorWithoutOptions extends ContractSelectorCommonProps {
  showVacant: boolean,
  propertyId: number,
  contractFilters?: {
    activeAtDate?: string,
    contractIds?: number[],
  }
  contracts?: never,
}

interface ContractSelectorCommonProps {
  value: number,
  onSelect: (v: UnitContractProjectionDto) => void,
  contractType: 'OWNER' | 'TENANT' | 'PROPERTY_OWNER' | 'ALL',
}

export type ContractSelectorProps = ContractSelectorWithOptions | ContractSelectorWithoutOptions;


const ContractOptionCustomBody = ({ c, vacantLabel }: { c: UnitContractProjectionDto, vacantLabel: string }) => (
  <Row>
    <Col span={8}>{c.unitNrSharingDeclaration || ''}</Col>
    <Col span={16}>{c.isVacant ? vacantLabel : c.mailingContact?.name || ''}</Col>
  </Row>
);

export const useContractSelector = ({
  propertyId,
  contractType,
  showVacant,
  contractFilters,
  onSelect: onSelectProp,
  contracts: contractsProp,
}: ContractSelectorProps) => {
  const { tl } = useContext(LanguageContext);
  const [contracts, setContracts] = useState<DefaultDataInterface<UnitContractProjectionDto[]>>(DEFAULT_DATA([]));
  const [options, setOptions] = useState([]);
  const [searchString, setSearchString] = useState<string>();

  const { apiConfiguration } = useContext(AuthContext);
  const unitContractControllerApi = new UnitContractControllerApi(apiConfiguration('accounting'));
  const contractControllerApi = new ContractLegacyControllerApi(apiConfiguration('accounting'));

  const scoreStringSearch = useScoreStringSearch();

  useEffect(() => {
    if (contractsProp) {
      setContracts(prev => prev.load(contractsProp));
      initializeOptions(contractsProp);
      return;
    }

    if (!propertyId || !contractType) return;

    loadContracts();
  }, [propertyId, contractType, contractFilters]);

  const loadContracts = async () => {
    setContracts(prev => prev.startLoading());
    let propertyOwners;
    let unitContracts;
    switch (contractType) {
      case 'PROPERTY_OWNER':
        propertyOwners = await loadPropertyOwnerContracts();
        setContracts(prev => prev.load(propertyOwners as unknown as UnitContractProjectionDto[]));
        initializeOptions(propertyOwners as unknown as UnitContractProjectionDto[]);
        break;
      case 'OWNER':
      case 'TENANT':
        unitContracts = await loadUnitContracts();
        setContracts(prev => prev.load(unitContracts));
        initializeOptions(unitContracts);
        break;
      case 'ALL':
        propertyOwners = await loadPropertyOwnerContracts();
        unitContracts = await loadUnitContracts();
        setContracts(prev => prev.load([...(propertyOwners as unknown as UnitContractProjectionDto[]), ...unitContracts]));
        initializeOptions([...(propertyOwners as unknown as UnitContractProjectionDto[]), ...unitContracts]);
        break;
      default:
        break;
    }
  };

  const handleLoadContractsError = (err) => {
    console.error(err);
    showNotification({
      key: 'loadContractError',
      type: 'error',
      message: tl(contractSelectorTranslations.loadContractError),
    });
  };

  const loadPropertyOwnerContracts = () => contractControllerApi.getContractsUsingGET({
    propertyId,
    validAtDate: contractFilters?.activeAtDate || undefined,
    contractIds: contractFilters?.contractIds,
    type: [GetContractsUsingGETTypeEnum.PROPERTY_OWNER] as unknown as GetContractsUsingGETTypeEnum,
  })
    .catch(handleLoadContractsError);

  const loadUnitContracts = () => unitContractControllerApi.getUnitContractsUsingGET({
    propertyId,
    atDate: contractFilters?.activeAtDate || undefined,
    unitContractIds: contractFilters?.contractIds,
    contractTypes: contractType !== 'ALL' ? [contractType] as unknown as GetUnitContractsUsingGETContractTypesEnum : undefined,
  })
    .then(unitContracts => unitContracts.filter(c => showVacant || !c.isVacant))
    .catch(handleLoadContractsError);

  const initializeOptions = (initialContracts: UnitContractProjectionDto[]) => {
    setOptions(initialContracts?.map((c) => {
      const unitNumberWithSpace = c.unitNrSharingDeclaration ? `${c.unitNrSharingDeclaration} ` : '';
      const contactName = c.isVacant ? tl(contractSelectorTranslations.vacantName) : (c.mailingContact?.name || '');
      const label = `${unitNumberWithSpace}${contactName}`;

      return {
        label,
        value: c.unitContractId,
        entity: c,
        searchValue: label.toLowerCase(),
        customBody: (<ContractOptionCustomBody c={c} vacantLabel={tl(contractSelectorTranslations.vacantName)} />),
      };
    }));
  };

  const onSearch = (searchStr: string) => {
    setSearchString(searchStr);
  };

  const filteredOptions = useMemo(() => (!isEmpty(searchString) ? scoreStringSearch(options, searchString) : options), [searchString, options]);

  const onSelect = (v: number) => {
    const contract = contracts.data?.find(c => c.unitContractId === v);
    onSelectProp(contract);
  };

  const noData = isEmpty(contracts.data);

  return {
    loading: contracts.loading,
    options: filteredOptions,
    noData,
    onSearch,
    onSelect,
  };
};
