import { OpsCostReportProjectionStatusEnum } from 'api/accounting';
import moment from 'moment';
import { HeaderTagColorKeys } from 'storybook-components/headers/utils';
import { PageHeaderTagColor } from 'storybook-components/layout/PageHeader/PageHeader';

export function getDotColor(state: OpsCostReportProjectionStatusEnum): HeaderTagColorKeys {
  switch (state) {
    case OpsCostReportProjectionStatusEnum.BOOKED:
    case OpsCostReportProjectionStatusEnum.SENT:
      return PageHeaderTagColor.BLUE as HeaderTagColorKeys;
    case OpsCostReportProjectionStatusEnum.DONE:
      return PageHeaderTagColor.GREEN as HeaderTagColorKeys;
    case OpsCostReportProjectionStatusEnum.FAILED:
      return PageHeaderTagColor.RED as HeaderTagColorKeys;
    case OpsCostReportProjectionStatusEnum.IN_GENERATION:
      return PageHeaderTagColor.YELLOW as HeaderTagColorKeys;
    default:
      return PageHeaderTagColor.GRAY as HeaderTagColorKeys;
  }
}

export const headerAndFooterStyling = `
<style>
  header, footer {
    font-family: 'IBM Plex Sans';
    font-size: 9px;
    line-height: 12px;
    color: #595959;
    width: 100%;
  }

  @media (orientation: portrait) {
    header, footer {
      margin-left: 23mm;
      margin-right: 12mm;
    }
  }

  @media (orientation: landscape) {
    header, footer {
      margin-left: 12mm;
      margin-right: 18mm;
    }
  }
</style>
`;


export const getHeader = (headerLeft: string, headerRight: string) => (`
${headerAndFooterStyling}
<header>
  <span style="float: left;">${headerLeft}</span>
  <span style="float: right;">${headerRight}</span>
</header>`
);

// the classnames `pageNumber` and `totalPages` are used to inject values
// https://pptr.dev/next/api/puppeteer.pdfoptions.headertemplate/
export const getFooter = () => (`
${headerAndFooterStyling}
<footer>
  <div style="float: left;">${moment().format('DD.MM.YYYY')}</div>
  <span style="float: right;"><span class='pageNumber'></span>/<span class='totalPages'></span></span>
</footer>`
);

export const wrapHtmlWithBoilerplate = (html:string) => `<!DOCTYPE html>
<html>
  <head>
    <meta charset="UTF-8"/>
    <link rel="stylesheet" type="text/css" media="all" href="style.css"/>
  </head>
  <body 'print-no-header-first-page'>
    ${html}
  </body>
  </html>`;
