import { RouteDefinition } from 'components/RenderRoutes';
import { DISTRIBUTION_COMPONENT, DISTRIBUTION_CREATE_PATH, DISTRIBUTION_EDIT_PATH } from 'pages/Distribution/routes';
import { UNIT_SET_COMPONENT, UNIT_SET_CREATE_PATH, UNIT_SET_EDIT_PATH } from 'pages/UnitSet/routes';
import { PDF_DOCUMENT_ASSIGNER_COMPONENT, PDF_DOCUMENT_ASSIGNER_PATH } from 'pages/PdfPageAssigner/routes';
import { WKA_CREATE_PATH, WKA_EDITOR_COMPONENT, WKA_EDIT_PATH } from 'pages/Wka/routes';
import { lazy } from 'react';

import PropertyDistributionKeys from '../PropertyDistributionKeys/PropertyDistributionKeys';
import { DistributionKeyTableWithProvider } from './DistributionKeyTable';


const DistributionKeyDetails = lazy(() => import('../DistributionKeyDetails/DistributionKeyDetails'));

export const DISTRIBUTION_KEY_DETAILS_COMPONENT = DistributionKeyDetails;
export const DISTRIBUTION_KEY_DETAILS_PATH = '/distribution-keys/:propertyHrId/:economicYear/:accountCode';
export const DISTRBUTION_KEYS_PATH: string = '/distribution-keys';

export const getDistributionKeyDetailsPath = (propertyHrId: string, year: number, accountCode: string) => `${DISTRBUTION_KEYS_PATH}/${propertyHrId}/${year}/${encodeURIComponent(accountCode)}`;
export const getDistributionKeyOverviewPath = (propertyHrId: string) => `${DISTRBUTION_KEYS_PATH}/${propertyHrId}`;

export const DistributionKeyRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: DISTRBUTION_KEYS_PATH,
      component: DistributionKeyTableWithProvider,
      subRoutes: [
        {
          path: `${DISTRBUTION_KEYS_PATH}/:propertyHrId`,
          component: PropertyDistributionKeys,
          type: 'overlay',
          subRoutes: [
            {
              path: DISTRIBUTION_KEY_DETAILS_PATH,
              component: DISTRIBUTION_KEY_DETAILS_COMPONENT,
              type: 'overlay',
              subRoutes: [
                {
                  path: [
                    `${DISTRIBUTION_KEY_DETAILS_PATH}${DISTRIBUTION_CREATE_PATH}`,
                    `${DISTRIBUTION_KEY_DETAILS_PATH}${DISTRIBUTION_EDIT_PATH}`,
                  ],
                  component: DISTRIBUTION_COMPONENT,
                  type: 'overlay',
                },
                {
                  path: [`${DISTRIBUTION_KEY_DETAILS_PATH}${UNIT_SET_CREATE_PATH}`, `${DISTRIBUTION_KEY_DETAILS_PATH}${UNIT_SET_EDIT_PATH}`],
                  component: UNIT_SET_COMPONENT,
                  type: 'overlay',
                },
                {
                  path: `${DISTRIBUTION_KEY_DETAILS_PATH}${WKA_CREATE_PATH}`,
                  component: WKA_EDITOR_COMPONENT,
                  type: 'focus',
                },
                {
                  path: `${DISTRIBUTION_KEY_DETAILS_PATH}${WKA_EDIT_PATH}`,
                  component: WKA_EDITOR_COMPONENT,
                  type: 'focus',
                  subRoutes: [
                    {
                      path: `${DISTRIBUTION_KEY_DETAILS_PATH}${WKA_EDIT_PATH}/${PDF_DOCUMENT_ASSIGNER_PATH}`,
                      component: PDF_DOCUMENT_ASSIGNER_COMPONENT,
                      type: 'overlay',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '*/:propertyHrId/distribution-keys',
      component: PropertyDistributionKeys,
      type: 'overlay',
      width: '90%',
      subRoutes: [
        {
          path: '*/:propertyHrId/distribution-keys/:economicYear/:accountCode',
          component: DISTRIBUTION_KEY_DETAILS_COMPONENT,
          type: 'overlay',
        },
      ],
    },
  ],
};
