import PersonDetails from 'pages/Contacts/pages/PersonDetails/PersonDetails';
import { ContactPage } from 'pages/Contacts/pages/ContactPage/ContactPage';
import { RouteDefinition } from 'components/RenderRoutes';
import CompanyDetails from 'pages/Contacts/pages/CompanyDetails/CompanyDetails';
import PersonList from './pages/PersonList/PersonList';
import CompanyList from './pages/CompanyList/CompanyList';
import PersonEditing from './pages/PersonEditing/PersonEditing';
import CompanyEditing from './pages/CompanyEditing/CompanyEditing';
import { AccountsOverview } from '../Account/AccountsOverview/AccountsOverview';
import AccountBooking from '../Account/AccountBooking/AccountBooking';

export const PERSON_EDITING_ROUTE = '/edit-person/:personId';

export const COMPANY_EDITING_ROUTE = '/edit-company/:companyId';

const viewPersonPath = '*/view-person/:personId';
const viewCompanyPath = '*/view-company/:companyId';

export const getViewPersonRoute = ({ personId }: { personId: string | number }) => viewPersonPath
  .substring(1) // remove '*'
  .replace(':personId', `${personId}`);

export const getViewCompanyRoute = ({ companyId }: { companyId: string | number }) => viewCompanyPath
  .substring(1) // remove '*'
  .replace(':companyId', `${companyId}`);


export const ContactRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/contacts/:contactId',
      component: ContactPage,
      exact: true,
    },
    {
      path: '/persons',
      component: PersonList,
    },
    {
      path: '/companies',
      component: CompanyList,
    },
    {
      path: '*/create-person',
      type: 'focus',
      component: PersonEditing,
    },
    {
      path: '*/create-company',
      type: 'focus',
      component: CompanyEditing,
    },
    {
      path: viewPersonPath,
      type: 'overlay',
      component: PersonDetails,
      subRoutes: [
        // this route is handled in Property/routes
        // leaving the code here so we know that contacts has a Property overlay
        // {
        //   path: '*/view-person/:personId/properties/:propertyHrId/edit',
        //   component: PropertyEditing,
        //   type: 'overlay',
        // },
        {
          path: `${viewPersonPath}/account-balances/:propertyHrId`,
          component: AccountsOverview,
          type: 'overlay',
          subRoutes: [
            {
              path: `${viewPersonPath}/account-balances/:propertyHrId/booking/create`,
              component: AccountBooking,
              type: 'overlay',
            },
          ],
        },
      ],
    },
    {
      path: viewCompanyPath,
      type: 'overlay',
      component: CompanyDetails,
      subRoutes: [
        // this route is handled in Property/routes
        // leaving the code here so we know that contacts has a Property overlay
        // {
        //   path: '*/view-company/:companyId/properties/:propertyHrId/edit',
        //   component: PropertyEditing,
        //   type: 'overlay',
        // },
        {
          path: `${viewCompanyPath}/account-balances/:propertyHrId`,
          component: AccountsOverview,
          type: 'overlay',
          subRoutes: [
            {
              path: `${viewCompanyPath}/account-balances/:propertyHrId/booking/create`,
              component: AccountBooking,
              type: 'overlay',
            },
          ],
        },
      ],
    },
    {
      path: `*${COMPANY_EDITING_ROUTE}`,
      type: 'focus',
      component: CompanyEditing,
    },
    {
      path: `*${PERSON_EDITING_ROUTE}`,
      type: 'focus',
      component: PersonEditing,
    },
  ],
};
