/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateUserDto,
  PageOfUserDto,
  RoleAssignRequestDto,
  UpdateUserDto,
  UserDto,
} from '../models';
import {
    CreateUserDtoFromJSON,
    CreateUserDtoToJSON,
    PageOfUserDtoFromJSON,
    PageOfUserDtoToJSON,
    RoleAssignRequestDtoFromJSON,
    RoleAssignRequestDtoToJSON,
    UpdateUserDtoFromJSON,
    UpdateUserDtoToJSON,
    UserDtoFromJSON,
    UserDtoToJSON,
} from '../models';

export interface AssignRoleUsingPOSTRequest {
    userId: number;
    roleAssignRequest: RoleAssignRequestDto;
}

export interface DeleteUserUsingDELETERequest {
    id: number;
}

export interface GetAllUsingGETRequest {
    emailFilter?: string;
    order?: GetAllUsingGETOrderEnum;
    page?: number;
    size?: number;
    sort?: string;
}

export interface GetByIdUsingGETRequest {
    id: number;
}

export interface InviteUsersUsingPOSTRequest {
    createUserDtos: Array<CreateUserDto>;
}

export interface UpdateUserUsingPATCHRequest {
    id: number;
    updateUserDto: UpdateUserDto;
}

/**
 * 
 */
export class UserControllerApi extends runtime.BaseAPI {

    /**
     * assignRole
     */
    async assignRoleUsingPOSTRaw(requestParameters: AssignRoleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling assignRoleUsingPOST.');
        }

        if (requestParameters.roleAssignRequest === null || requestParameters.roleAssignRequest === undefined) {
            throw new runtime.RequiredError('roleAssignRequest','Required parameter requestParameters.roleAssignRequest was null or undefined when calling assignRoleUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/{userId}/assign-role`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoleAssignRequestDtoToJSON(requestParameters.roleAssignRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * assignRole
     */
    async assignRoleUsingPOST(requestParameters: AssignRoleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.assignRoleUsingPOSTRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for soft deleting user with given id
     */
    async deleteUserUsingDELETERaw(requestParameters: DeleteUserUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserUsingDELETE.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for soft deleting user with given id
     */
    async deleteUserUsingDELETE(requestParameters: DeleteUserUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserUsingDELETERaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint that returns a list of all users belonging to a domain.
     */
    async getAllUsingGETRaw(requestParameters: GetAllUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageOfUserDto>> {
        const queryParameters: any = {};

        if (requestParameters.emailFilter !== undefined) {
            queryParameters['emailFilter'] = requestParameters.emailFilter;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageOfUserDtoFromJSON(jsonValue));
    }

    /**
     * Endpoint that returns a list of all users belonging to a domain.
     */
    async getAllUsingGET(requestParameters: GetAllUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageOfUserDto> {
        const response = await this.getAllUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint that returns a user by id.
     */
    async getByIdUsingGETRaw(requestParameters: GetByIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getByIdUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     * Endpoint that returns a user by id.
     */
    async getByIdUsingGET(requestParameters: GetByIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDto> {
        const response = await this.getByIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint that invites users based on email address and password and assigns admin role to them.
     */
    async inviteUsersUsingPOSTRaw(requestParameters: InviteUsersUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserDto>>> {
        if (requestParameters.createUserDtos === null || requestParameters.createUserDtos === undefined) {
            throw new runtime.RequiredError('createUserDtos','Required parameter requestParameters.createUserDtos was null or undefined when calling inviteUsersUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/invite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createUserDtos.map(CreateUserDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserDtoFromJSON));
    }

    /**
     * Endpoint that invites users based on email address and password and assigns admin role to them.
     */
    async inviteUsersUsingPOST(requestParameters: InviteUsersUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserDto>> {
        const response = await this.inviteUsersUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for resetting user password based on user id
     */
    async updateUserUsingPATCHRaw(requestParameters: UpdateUserUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserUsingPATCH.');
        }

        if (requestParameters.updateUserDto === null || requestParameters.updateUserDto === undefined) {
            throw new runtime.RequiredError('updateUserDto','Required parameter requestParameters.updateUserDto was null or undefined when calling updateUserUsingPATCH.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserDtoToJSON(requestParameters.updateUserDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     * Endpoint for resetting user password based on user id
     */
    async updateUserUsingPATCH(requestParameters: UpdateUserUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDto> {
        const response = await this.updateUserUsingPATCHRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
  * @export
  * @enum {string}
  */
export enum GetAllUsingGETOrderEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}
