import React from 'react';
import { SectionWrapper } from 'storybook-components/sections/SectionWrapper/SectionWrapper';
import { opsCostTranslation } from 'pages/OpsCostReport/translations';
import { ConfigProvider, Table } from 'antd';
import EmptyTableMultilineMessage from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import { isEmpty } from 'lodash';
import { useAnnex35aSection as hook } from './useAnnex35aSection';
import Annex35aSectionTableSummary from './components/Annex35aSectionTableSummary';
import Annex35aSectionTableSummaryTotal from './components/Annex35aSectionTableSummaryTotal';

type ReturnTypeOfHook = ReturnType<typeof hook>;
type ReturnTypeOfAnnex35aSectionHook = ReturnType<ReturnTypeOfHook['getAnnex35aSectionProps']>

interface Annex35aSectionProps {
  tl: any,
  openSectionId: number,
  useAnnex35aSection: ReturnTypeOfAnnex35aSectionHook
}

const Annex35aSection = ({
  tl,
  openSectionId,
  useAnnex35aSection,
}: Annex35aSectionProps) => {
  const {
    dataSources,
    columns,
    components,
    summaryData,
  } = useAnnex35aSection;

  // if the table is empty we need to set the height, otherwise the Empty component will not be visible
  const allTablesEmpty = Object.values(dataSources).every(isEmpty);
  const tableHeight = allTablesEmpty ? { y: 200 } : undefined;

  const showSummary = !allTablesEmpty;

  return (
    <SectionWrapper
      sectionId="Annex35aSection"
      sectionTitle={tl(opsCostTranslation.report.sections.annex35aSection.title)}
      isOpen={openSectionId === 1}
      sectionDisabled={openSectionId !== 1}
      withContentPadding
    >
      <ConfigProvider renderEmpty={() => (
        allTablesEmpty
          ? <EmptyTableMultilineMessage description={tl(opsCostTranslation.report.emptyTable.annex)} />
          : null
      )}
      >
        {/* since these are NoHeaderTables, visually these are only one table (not multiple),
            so we only need to show the message once, therefore we only wrap the first table */}
        <Table
          dataSource={dataSources.householdRelatedServicesData}
          rowKey="accountCode"
          columns={columns}
          components={components}
          pagination={false}
          // only setting height for the first table so that we only see the Empty component once
          scroll={tableHeight}
          summary={showSummary ? (() => Annex35aSectionTableSummary({
            title: tl(opsCostTranslation.report.sections.annex35aSection.summaryLabels.householdRelatedServices),
            laborCostSum: summaryData.householdRelatedServices.laborCost,
            yourShareSum: summaryData.householdRelatedServices.yourShare,
          })) : undefined}
        />
      </ConfigProvider>


      {/**
       * 1. conditional rendering because when printed, if all tables are empty then the
       *    empty tables' borders add up and it looks weird
       * 2. ConfigProvider is added because even though this ConfigProvider is there in ContextProvider.tsx we
       *    need to duplicate it here because this component is used in useOpsCostReportHtmlGenerator.tsx and
       *    when this is rendered by the serviceWorker then it will no longer be wrapped by the ContextProvider
       */}
      {
        !allTablesEmpty && (
          <ConfigProvider renderEmpty={() => null}>
            <Table
              dataSource={dataSources.technicianServiceData}
              rowKey="accountCode"
              columns={columns}
              components={components}
              pagination={false}
              className="NoHeaderTable"
              summary={showSummary ? (() => Annex35aSectionTableSummary({
                title: tl(opsCostTranslation.report.sections.annex35aSection.summaryLabels.technicianService),
                laborCostSum: summaryData.technicianService.laborCost,
                yourShareSum: summaryData.technicianService.yourShare,
              })) : undefined}
            />
            <Table
              dataSource={dataSources.marginalEmploymentData}
              rowKey="accountCode"
              columns={columns}
              components={components}
              pagination={false}
              className="NoHeaderTable"
              summary={showSummary ? (() => Annex35aSectionTableSummary({
                title: tl(opsCostTranslation.report.sections.annex35aSection.summaryLabels.marginalEmployment),
                laborCostSum: summaryData.marginalEmployment.laborCost,
                yourShareSum: summaryData.marginalEmployment.yourShare,
              })) : undefined}
            />
            <Table
              dataSource={dataSources.insurableEmploymentData}
              rowKey="accountCode"
              columns={columns}
              components={components}
              pagination={false}
              className="NoHeaderTable"
              summary={showSummary ? (() => Annex35aSectionTableSummary({
                title: tl(opsCostTranslation.report.sections.annex35aSection.summaryLabels.insurableEmployment),
                laborCostSum: summaryData.insurableEmployment.laborCost,
                yourShareSum: summaryData.insurableEmployment.yourShare,
              })) : undefined}
            />
            <Table
              dataSource={[]}
              columns={columns}
              components={components}
              pagination={false}
              className="NoHeaderTable"
              summary={showSummary ? (() => Annex35aSectionTableSummaryTotal({
                title: tl(opsCostTranslation.report.sections.annex35aSection.summaryLabels.total),
                laborCostSum: summaryData.total.laborCost,
                yourShareSum: summaryData.total.yourShare,
              })) : undefined}
            />
          </ConfigProvider>
        )
      }
    </SectionWrapper>
  );
};

export default Annex35aSection;
