import { Table } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import React, { useContext } from 'react';
import { translations } from 'pages/ProfitAndLossReport/translations';
import SubSectionWrapper from 'storybook-components/sections/SubSectionWrapper/SubSectionWrapper';
import { useExpenseTable } from './useExpenseTable';
import ExpenseTableSummary from './ExpenseTableSummary';


const ExpenseTable = ({ sectionNumber }: { sectionNumber: number }) => {
  const {
    vatRelevantGermanProperty, columns, applicableExpenseWkaDataSource, applicableExpenseWkaSum, applicableExpenseWkaNetSum,
    applicableExpenseWkaVatSum, applicableExpenseWkaVatEligibilitySum, applicableExpenseWkaVatEligibilityPercentageTotal,
    otherApplicableExpenseDataSource, otherApplicableExpenseSum, otherApplicableExpenseNetSum, otherApplicableExpenseVatSum,
    otherApplicableExpenseVatEligibilitySum, otherApplicableExpenseVatEligibilityPercentageTotal, notApplicableExpenseDataSource,
    notApplicableExpenseSum, notApplicableExpenseNetSum, notApplicableExpenseVatSum, notApplicableExpenseVatEligibilitySum,
    notApplicableExpenseVatEligibilityPercentageTotal, totalColumns, totalDataSource, totalSum, totalNet, totalVat, totalVatEligibility,
    totalVateligibilityPercentage,
  } = useExpenseTable();
  const { tl } = useContext(LanguageContext);

  return (
    <div className="ExpenseTable">
      <SubSectionWrapper title={`${sectionNumber}.1 ${tl(translations.report.sections.expenseSection.applicableExpensesWka)}`}>
        <Table
          columns={columns}
          dataSource={applicableExpenseWkaDataSource}
          pagination={false}
          summary={() => (
            <ExpenseTableSummary
              title={tl(translations.report.sections.expenseSection.subtotal)}
              totalGross={applicableExpenseWkaSum}
              totalNet={applicableExpenseWkaNetSum}
              totalVat={applicableExpenseWkaVatSum}
              totalVatEligibility={applicableExpenseWkaVatEligibilitySum}
              totalVatEligibilityPercentage={applicableExpenseWkaVatEligibilityPercentageTotal}
              displayVatColumns={vatRelevantGermanProperty}
              nrTotalColumns={columns.length}
            />
          )}
          rowKey="accountCode"
        />
      </SubSectionWrapper>
      <SubSectionWrapper title={`${sectionNumber}.2 ${tl(translations.report.sections.expenseSection.otherApplicableExpenses)}`}>
        <Table
          columns={columns}
          dataSource={otherApplicableExpenseDataSource}
          pagination={false}
          summary={() => (
            <ExpenseTableSummary
              title={tl(translations.report.sections.expenseSection.subtotal)}
              totalGross={otherApplicableExpenseSum}
              totalNet={otherApplicableExpenseNetSum}
              totalVat={otherApplicableExpenseVatSum}
              totalVatEligibility={otherApplicableExpenseVatEligibilitySum}
              totalVatEligibilityPercentage={otherApplicableExpenseVatEligibilityPercentageTotal}
              displayVatColumns={vatRelevantGermanProperty}
              nrTotalColumns={columns.length}
            />
          )}
          rowKey="accountCode"
        />
      </SubSectionWrapper>
      <SubSectionWrapper title={`${sectionNumber}.3 ${tl(translations.report.sections.expenseSection.notApplicableExpenses)}`}>
        <Table
          columns={columns}
          dataSource={notApplicableExpenseDataSource}
          pagination={false}
          summary={() => (
            <ExpenseTableSummary
              title={tl(translations.report.sections.expenseSection.subtotal)}
              totalGross={notApplicableExpenseSum}
              totalNet={notApplicableExpenseNetSum}
              totalVat={notApplicableExpenseVatSum}
              totalVatEligibility={notApplicableExpenseVatEligibilitySum}
              totalVatEligibilityPercentage={notApplicableExpenseVatEligibilityPercentageTotal}
              displayVatColumns={vatRelevantGermanProperty}
              nrTotalColumns={columns.length}
            />
          )}
          rowKey="accountCode"
        />
      </SubSectionWrapper>
      <SubSectionWrapper title={`${sectionNumber}.4 ${tl(translations.report.sections.expenseSection.totalOfExpenses)}`}>
        <Table
          columns={totalColumns}
          dataSource={totalDataSource}
          pagination={false}
          summary={() => (
            <ExpenseTableSummary
              title={tl(translations.report.sections.expenseSection.totalOfExpensesSummary)}
              totalGross={totalSum}
              totalNet={totalNet}
              totalVat={totalVat}
              totalVatEligibility={totalVatEligibility}
              totalVatEligibilityPercentage={totalVateligibilityPercentage}
              nrTotalColumns={totalColumns.length}
              displayVatColumns={vatRelevantGermanProperty}
            />
          )}
          rowKey="label"
        />
      </SubSectionWrapper>
    </div>
  );
};

export default ExpenseTable;
