import { AuthContext } from 'contexts/AuthContext';
import { useContext } from 'react';

export interface AnalyticsDashboard {
  // type: string, for now metabase only
  title: { en: string, de: string },
  path?: string,
  resource: string,
  resourceId: number,
}
export interface AnalyticsGroup {
  title: { en: string, de: string },
  pages: AnalyticsDashboard[];
}

export const useAnalyticsDomainDataList = () => {
  const analyticsGroups:AnalyticsGroup[] = [
    {
      title: { en: 'Property stats', de: 'Objektstatistiken' },
      pages: [
        {
          title: { en: 'Offboarding report', de: 'Anzahl der abgegebenen WEGs' },
          resource: 'question',
          resourceId: 751,
        },
        {
          title: { en: 'Contract ending report', de: 'Anzahl der auslaufenden Verträge' },
          resource: 'question',
          resourceId: 755,
        },
        {
          title: { en: 'HMS status by property manager', de: 'Abrechnungsstatus nach Objektbetreuer' },
          resource: 'question',
          resourceId: 758,
        },
        {
          title: { en: 'Property unit stats', de: 'Bestandsliste' },
          resource: 'question',
          resourceId: 754,
        },
        {
          title: { en: 'Address list', de: 'Kontaktliste' },
          resource: 'question',
          resourceId: 673,
        },
        {
          title: { en: 'Geographical overview', de: 'Geografische Analyse' },
          resource: 'question',
          resourceId: 749,
        },
      ],
    },
    {
      title: { en: 'Accounting stats', de: 'Buchhaltungsstatistiken' },
      pages: [
        {
          title: { en: 'Revenue per unit', de: 'Honoraranalyse' },
          resource: 'question',
          resourceId: 753,
        },
        {
          title: { en: 'Service company domain summary', de: 'Dienstleister Gesamtanalyse ' },
          resource: 'question',
          resourceId: 757,
        },
        {
          title: { en: 'Service company stats', de: 'Dienstleister Einzelanalyse ' },
          resource: 'question',
          resourceId: 752,
        },
        {
          title: { en: 'Account analysis across Properties', de: 'WEG-übergreifende Kontenanalyse' },
          resource: 'question',
          resourceId: 750,
        },
        {
          title: { en: 'Open unit owner balances', de: 'Offene Eigentümersalden' },
          resource: 'question',
          resourceId: 756,
        },
        {
          title: { en: 'Overview of rents', de: 'Mieterliste' },
          resource: 'question',
          resourceId: 914,
        },
        {
          title: { en: 'List of bookings', de: 'Buchungsliste' },
          resource: 'question',
          resourceId: 1011,
        },
        {
          title: { en: 'VAT list', de: 'Umsatzsteuerliste' },
          resource: 'question',
          resourceId: 1014,
        },
        {
          title: { en: 'Direct Debit Mandates', de: 'Lastschriftmandate' },
          resource: 'question',
          resourceId: 1173,
        },
      ],
    },
  ];

  return {
    analyticsDomainData: analyticsGroups.map((group) => {
      group.pages.forEach((page) => { page.path = `/analytics/${page.resource}/${page.resourceId}`; });
      return group;
    }),
  };
};
