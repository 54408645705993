import { RouteDefinition } from '../../components/RenderRoutes';
import { TransactionImports } from './TransactionImports';

export const TransactionImportRoutes: RouteDefinition = {
  subRoutes: [
    {
      path: '/transaction-imports',
      component: TransactionImports,
    },
  ],
};
