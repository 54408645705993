/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ManagementCompanySettingsPublicDto,
  ManagementCompanySettingsUpdateDto,
} from '../models';
import {
    ManagementCompanySettingsPublicDtoFromJSON,
    ManagementCompanySettingsPublicDtoToJSON,
    ManagementCompanySettingsUpdateDtoFromJSON,
    ManagementCompanySettingsUpdateDtoToJSON,
} from '../models';

export interface GetManagementCompanySettingsUsingGETRequest {
    managementCompanyContactId: number;
}

export interface PatchManagementCompanySettingsUsingPATCHRequest {
    managementCompanyContactId: number;
    update: ManagementCompanySettingsUpdateDto;
}

/**
 * 
 */
export class ManagementCompanySettingsControllerApi extends runtime.BaseAPI {

    /**
     * getAllSettings
     */
    async getAllSettingsUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ManagementCompanySettingsPublicDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ManagementCompanySettingsPublicDtoFromJSON));
    }

    /**
     * getAllSettings
     */
    async getAllSettingsUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ManagementCompanySettingsPublicDto>> {
        const response = await this.getAllSettingsUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getManagementCompanySettings
     */
    async getManagementCompanySettingsUsingGETRaw(requestParameters: GetManagementCompanySettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementCompanySettingsPublicDto>> {
        if (requestParameters.managementCompanyContactId === null || requestParameters.managementCompanyContactId === undefined) {
            throw new runtime.RequiredError('managementCompanyContactId','Required parameter requestParameters.managementCompanyContactId was null or undefined when calling getManagementCompanySettingsUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/settings/{managementCompanyContactId}`.replace(`{${"managementCompanyContactId"}}`, encodeURIComponent(String(requestParameters.managementCompanyContactId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementCompanySettingsPublicDtoFromJSON(jsonValue));
    }

    /**
     * getManagementCompanySettings
     */
    async getManagementCompanySettingsUsingGET(requestParameters: GetManagementCompanySettingsUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementCompanySettingsPublicDto> {
        const response = await this.getManagementCompanySettingsUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * patchManagementCompanySettings
     */
    async patchManagementCompanySettingsUsingPATCHRaw(requestParameters: PatchManagementCompanySettingsUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ManagementCompanySettingsPublicDto>> {
        if (requestParameters.managementCompanyContactId === null || requestParameters.managementCompanyContactId === undefined) {
            throw new runtime.RequiredError('managementCompanyContactId','Required parameter requestParameters.managementCompanyContactId was null or undefined when calling patchManagementCompanySettingsUsingPATCH.');
        }

        if (requestParameters.update === null || requestParameters.update === undefined) {
            throw new runtime.RequiredError('update','Required parameter requestParameters.update was null or undefined when calling patchManagementCompanySettingsUsingPATCH.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/settings/{managementCompanyContactId}`.replace(`{${"managementCompanyContactId"}}`, encodeURIComponent(String(requestParameters.managementCompanyContactId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ManagementCompanySettingsUpdateDtoToJSON(requestParameters.update),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ManagementCompanySettingsPublicDtoFromJSON(jsonValue));
    }

    /**
     * patchManagementCompanySettings
     */
    async patchManagementCompanySettingsUsingPATCH(requestParameters: PatchManagementCompanySettingsUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ManagementCompanySettingsPublicDto> {
        const response = await this.patchManagementCompanySettingsUsingPATCHRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
