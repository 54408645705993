import React, {
  useContext,
} from 'react';
import { AccountDistributionCopyModal } from 'pages/DistributionKeys/AccountDistributionEditor/components/AccountDistributionCopyModal/AccountDistributionCopyModal';
import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import PropertyInfo from 'elements/PropertyInfo/PropertyInfo';
import { Segmented } from 'antd';
import Button from 'elements/Buttons/Button/Button';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translations } from './translations';
import './PropertyDistributionKeys.scss';
import { EconomicYearPickerWithModalOption } from './modal/batch/EconomicYearPickerWithModalOption';
import { IndividualDistributionKeyCopyModal } from './modal/individual/IndividualDistributionKeyCopyModal';
import IndividualDistributionKeyCopyModalContextProvider from './modal/individual/context/IndividualDistributionKeyCopyModalContext';
import { PropertyDistributionKeysTable } from './PropertyDistributionKeysTable';
import { usePropertyDistirbutionKeys } from './services/usePropertyDistributionKeys';

export default function PropertyDistributionKeys(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const {
    selectedProperty,
    selectedDistributionKey,
    modalVisible,
    administrationType,
    selectedEconomicYear,
    yearsWithDistributionKeys,
    setSelectedEconomicYear,
    propertyHrId,
    onCopy,
    propertyLoaded,
    setModalVisible,
    segmentedOptions,
    segmentedValue,
    onClose,
    enableSelectingOpsCostInDK,
  } = usePropertyDistirbutionKeys();

  const economicYearPicker = (
    <EconomicYearPickerWithModalOption
      selectedEconomicYear={selectedEconomicYear}
      setSelectedEconomicYear={setSelectedEconomicYear}
      yearsWithDistributionKeys={yearsWithDistributionKeys}
    />
  );
  return (
    <Page className="Property-distribution-keys">
      <PageHeader
        title={tl(translations.propertyDistributionKey.title)}
        subtitle={<PropertyInfo property={selectedProperty} />}
        rightSideComponent={(
          <Button
            type="text"
            onClick={onClose}
          >
            {tl(translations.propertyDistributionKey.close)}
          </Button>
        )}
      />

      <PageContent>
        <div className="subheader">
          {administrationType === 'WEG' && enableSelectingOpsCostInDK
            ? (
              <div className="SegmentedAndEconomicYearPicker">
                <Segmented
                  options={segmentedOptions}
                  value={segmentedValue}
                />
                {economicYearPicker}
              </div>
            )
            : (
              <div className="EconomicYearPicker">
                {economicYearPicker}
              </div>
            )
          }
        </div>
        {propertyLoaded && (
          <PropertyDistributionKeysTable onCopy={onCopy} />
        )}
        {administrationType === 'MV' || administrationType === 'SEV' || administrationType === 'SEV_MV'
          ? (
            <AccountDistributionCopyModal
              sourceADK={selectedDistributionKey}
              economicYear={selectedEconomicYear}
              modalVisible={modalVisible}
              closeModal={() => setModalVisible(false)}
              propertyHrId={propertyHrId}
            />
          )
          : (
            <IndividualDistributionKeyCopyModalContextProvider>
              <IndividualDistributionKeyCopyModal
                sourceADK={selectedDistributionKey}
                sourceEconomicYear={selectedEconomicYear}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                yearsWithDistributionKeys={yearsWithDistributionKeys}
              />
            </IndividualDistributionKeyCopyModalContextProvider>
          )
        }
      </PageContent>
    </Page>
  );
}
