import { Table } from 'antd';
import { TableSummaryIconCell } from 'elements/TableSummaryIconCell/TableSummaryIconCell';
import { formatCurrency } from 'lib/Utils';
import { SummaryAmounts } from 'pages/OpsCostReport/OpsCostReportEditing/services/interfaces';
import Amount from 'storybook-components/typography/Amount/Amount';

interface Props {
    title: string,
    summaryData: SummaryAmounts,
    nrTotalColumns: number,
    isContractVatRelevant,
  }

const YourPrepaymentsTableSummary = ({
  title, summaryData, nrTotalColumns, isContractVatRelevant,
} : Props) => (
  <Table.Summary>
    <Table.Summary.Row>
      <TableSummaryIconCell colSpan={isContractVatRelevant ? nrTotalColumns - 3 : nrTotalColumns - 1} content={title} />

      {isContractVatRelevant && (
        <>
          <Table.Summary.Cell className="column-align-right no-wrap" index={1} colSpan={1}>
            <Amount>{formatCurrency(summaryData.net, '-', false)}</Amount>
          </Table.Summary.Cell>

          <Table.Summary.Cell className="column-align-right no-wrap" index={1} colSpan={1}>
            <Amount>{formatCurrency(summaryData.vat, '-', false)}</Amount>
          </Table.Summary.Cell>
        </>
      )}

      <Table.Summary.Cell className="column-align-right no-wrap" index={1} colSpan={1}>
        <Amount>{formatCurrency(summaryData.gross, '-', false)}</Amount>
      </Table.Summary.Cell>

    </Table.Summary.Row>
  </Table.Summary>
);

export default YourPrepaymentsTableSummary;
