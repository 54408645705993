import { useFeatureSettings } from 'lib/useFeatureSettings';
import { useRef, useState } from 'react';

export interface useModalProps {
  onOk: Function
}

export function useModal(props: useModalProps) {
  const { onOk } = props;
  const [visible, setVisible] = useState(false);
  const onOkPropsRef = useRef();
  const { enableSelectingOpsCostInDK } = useFeatureSettings();

  const showModal = (onOkProps?: any) => {
    setVisible(true);
    onOkPropsRef.current = onOkProps;
  };

  const onClickOk = () => {
    setVisible(false);
    onOk(onOkPropsRef.current);
  };

  const onClickCancel = () => {
    setVisible(false);
  };

  return {
    visible,
    showModal,
    onClickOk,
    onClickCancel,
    enableSelectingOpsCostInDK,
  };
}
