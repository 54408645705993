import { ConfigProvider, Table } from 'antd';
import { opsCostTranslation } from 'pages/OpsCostReport/translations';
import SubSectionWrapper from 'storybook-components/sections/SubSectionWrapper/SubSectionWrapper';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import EmptyTableMultilineMessage from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import { isEmpty } from 'lodash';
import { useTotalExpensesSection as hook } from './useTotalExpensesSection';
import ExpenseTableSummary from '../components/ExpenseTableSummary';


type ReturnTypeOfHook = ReturnType<typeof hook>;
type ReturnTypeOfTotalExpensesHook = ReturnType<ReturnTypeOfHook['getTotalExpensesSectionProps']>


interface OtherOpsCostsSectionProps {
  sectionNumber: number;
  tl: any;
  useTotalExpensesSection: ReturnTypeOfTotalExpensesHook;
}


const TotalExpensesSection = ({
  sectionNumber,
  tl,
  useTotalExpensesSection,
}: OtherOpsCostsSectionProps) => {
  const {
    columns,
    totalExpensesDataSource,
    loading,
    totalSummaryData,
  } = useTotalExpensesSection;

  // if the table is empty we need to set the height, otherwise the Empty component will not be visible
  const tableHeight = isEmpty(totalExpensesDataSource) ? { y: 200 } : undefined;


  return (
    <SubSectionWrapper title={`${sectionNumber}.3 ${tl(opsCostTranslation.report.sections.expensesSection.totalExpensesSubSection.title)}`}>
      <ConfigProvider renderEmpty={() => <EmptyTableMultilineMessage description={tl(opsCostTranslation.report.emptyTable.common)} />}>
        <Table
          columns={columns}
          dataSource={totalExpensesDataSource}
          rowKey="position"
          pagination={false}
          scroll={tableHeight}
          loading={{
            indicator: <TableContentLoadingIndicator />,
            spinning: loading,
          }}
          summary={() => (
            <ExpenseTableSummary
              title={tl(opsCostTranslation.report.sections.expensesSection.totalExpensesSubSection.total)}
              totalSummaryData={totalSummaryData}
              nrTotalColumns={columns.length}
            />
          )}
        />
      </ConfigProvider>
    </SubSectionWrapper>
  );
};

export default TotalExpensesSection;
