import React, { useContext } from 'react';
import './ManualExchangeOccurrenceSection.scss';
import { Table } from 'antd';
import { translations } from '../../translations';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { useOccurrences } from '../../services/useOccurrences';
import AdditionalEntityButton from '../../../../elements/Buttons/AdditionalEntityButton/AdditionalEntityButton';
import { SectionWrapper } from '../../../../storybook-components/sections/SectionWrapper/SectionWrapper';
import { useOccurrenceListColumns } from '../../services/useOccurrenceListColumns';

export default function ManualExchangeOccurrenceSection(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const { addOccurrence, occurrenceValues } = useOccurrences();

  const tableColumns = useOccurrenceListColumns();

  return (
    <SectionWrapper
      className="ManualExchangeOccurrenceSectionWrapper"
      sectionTitle={tl(translations.creationPage.occurrenceTitle)}
      sectionId="exchange-occurrence"
      sectionNumber={2}
      sectionOpenSuffix={(
        <AdditionalEntityButton
          className="add-entity"
          onClick={() => addOccurrence()}
          label={tl(translations.creationPage.sections.occurrence.add)}
        />
      )}
    >
      <div className="ManualExchangeOccurrenceSection form">
        <Table
          className="OccurrenceListTable"
          dataSource={occurrenceValues}
          columns={tableColumns}
          pagination={false}
        />
      </div>
    </SectionWrapper>
  );
}
