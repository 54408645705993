/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ResponseEntity,
  RoleDto,
  RoleEpo,
} from '../models';
import {
    ResponseEntityFromJSON,
    ResponseEntityToJSON,
    RoleDtoFromJSON,
    RoleDtoToJSON,
    RoleEpoFromJSON,
    RoleEpoToJSON,
} from '../models';

export interface CreateRoleUsingPOSTRequest {
    roleEpo: RoleEpo;
}

export interface UpdateRoleUsingPUTRequest {
    roleName: string;
    roleEpo: RoleEpo;
}

/**
 * 
 */
export class RoleControllerApi extends runtime.BaseAPI {

    /**
     * createRole
     */
    async createRoleUsingPOSTRaw(requestParameters: CreateRoleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseEntity>> {
        if (requestParameters.roleEpo === null || requestParameters.roleEpo === undefined) {
            throw new runtime.RequiredError('roleEpo','Required parameter requestParameters.roleEpo was null or undefined when calling createRoleUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/roles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoleEpoToJSON(requestParameters.roleEpo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseEntityFromJSON(jsonValue));
    }

    /**
     * createRole
     */
    async createRoleUsingPOST(requestParameters: CreateRoleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseEntity> {
        const response = await this.createRoleUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getRoles
     */
    async getRolesUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RoleDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleDtoFromJSON));
    }

    /**
     * getRoles
     */
    async getRolesUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RoleDto>> {
        const response = await this.getRolesUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * updateRole
     */
    async updateRoleUsingPUTRaw(requestParameters: UpdateRoleUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseEntity>> {
        if (requestParameters.roleName === null || requestParameters.roleName === undefined) {
            throw new runtime.RequiredError('roleName','Required parameter requestParameters.roleName was null or undefined when calling updateRoleUsingPUT.');
        }

        if (requestParameters.roleEpo === null || requestParameters.roleEpo === undefined) {
            throw new runtime.RequiredError('roleEpo','Required parameter requestParameters.roleEpo was null or undefined when calling updateRoleUsingPUT.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // JWT authentication
        }

        const response = await this.request({
            path: `/api/v1/users/roles/{roleName}`.replace(`{${"roleName"}}`, encodeURIComponent(String(requestParameters.roleName))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RoleEpoToJSON(requestParameters.roleEpo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseEntityFromJSON(jsonValue));
    }

    /**
     * updateRole
     */
    async updateRoleUsingPUT(requestParameters: UpdateRoleUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseEntity> {
        const response = await this.updateRoleUsingPUTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
