import React, {
  useContext, useEffect, useRef, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useDownloadEbics } from 'pages/PaymentList/services/useDownloadEbics';
import moment from 'moment';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';
import DEFAULT_DATA from '../lib/data';
import { LanguageContext } from './LanguageContext';
import backend, { endpointUrls } from '../backend_api';
import { translations } from '../elements/Translation/translations';
import { NotificationObject, showNotification } from '../lib/Notification';
import {
  OrderStateDtoStateEnum, PaymentControllerApi, PaymentDto, PaymentDtoPaymentStateEnum,
} from '../api/accounting';
import { AuthContext } from './AuthContext';

const PAGE_SIZE = 30;

export const PaymentListContext: any = React.createContext({});

export default function PaymentListProvider({ children }: any): JSX.Element {
  const { apiConfiguration } = useContext(AuthContext);
  const { overlays } = useContext(OverlayContext);
  const paymentControllerApi = new PaymentControllerApi(apiConfiguration('accounting'));
  const { tl } = useContext(LanguageContext);

  const defaultPaymentListState = {
    paymentList: [],
    lastPage: false,
  };

  const [paymentListState, setPaymentListState] = useState(DEFAULT_DATA<any>(defaultPaymentListState));
  const paymentListRef: any = useRef();

  const page = useRef<number>(-1);
  paymentListRef.current = paymentListState;

  const defaultFilters = useMemo(() => ({
    paymentStates: [PaymentDtoPaymentStateEnum.NEW],
    maxExecutionDate: moment().add(5, 'days').toISOString(),
  }), []);

  const [paymentFilterState, setPaymentFilterState] = useState<any>({});
  const [initialFilterUpdate, setInitialFilterUpdate] = useState(true);

  const defaultPaymentSortState = {
    field: 'propertyIdInternal',
    order: 0,
  };

  const [paymentSortState, setPaymentSortState] = useState(DEFAULT_DATA<any>(defaultPaymentSortState));
  const paymentSortRef: any = useRef();
  paymentSortRef.current = paymentSortState;
  const [initialSortUpdate, setInitialSortUpdate] = useState(true);
  const [excludedIbans, setExcludedIbans] = useState<string[]>([]);

  const [onDownloadAllEbics, onDownloadEbicsFileForPaymentIdList, loadingEbics,
    onLoadEbicsDownloadDataByFilters, onLoadEbicsDownloadDataByPaymentIds, downloadData] = useDownloadEbics();
  const lastRequestTimestamp = useRef<number | null>(null);

  useEffect(() => {
    if (overlays.length === 1) {
      onLoadPaymentList(true);
    }
  }, [overlays.length]);

  useEffect(() => {
    if (!initialFilterUpdate) {
      onLoadPaymentList(true);
    } else {
      setInitialFilterUpdate(false);
    }
  }, [paymentFilterState]);

  useEffect(() => {
    if (!initialSortUpdate) {
      onLoadPaymentList(true);
    } else {
      setInitialSortUpdate(false);
    }
  }, [paymentSortState]);

  const updatePaymentListState = (data: any) => {
    setPaymentListState((prevState: any) => prevState.load({
      ...prevState.data,
      ...data,
    }));
  };

  const updatePaymentFilterState = (data: { [key: string]: any }) => {
    setPaymentFilterState((prevState: any) => ({
      ...prevState,
      ...data,
    }));
  };

  const updatePaymentSortState = (sort: any) => {
    setPaymentSortState(paymentSortState.load({
      ...paymentSortRef.current.data,
      ...sort,
    }));
  };

  const setSortField = (field: string) => {
    const order = paymentSortRef.current.data.field === field ? paymentSortRef.current.data.order * (-1) : 1;
    updatePaymentSortState({
      field,
      order,
    });
  };

  const onLoadPaymentList = (resetPage: boolean = false, sort: any = paymentSortState.data) => {
    // If management company is not specified, set loading to true and wait for update
    if (!paymentFilterState.managementCompanyId) {
      setPaymentListState(prevState => prevState.startLoading());
      return;
    }

    const currentTimestamp = new Date().getTime();
    lastRequestTimestamp.current = currentTimestamp;

    if (resetPage || paymentListRef.current.data.paymentList.length === 0) {
      setPaymentListState(prevState => prevState.startLoading());
    }
    page.current = resetPage ? 0 : page.current + 1;
    backend.get(`${endpointUrls.PAYMENT}/filter`,
      {
        ...paymentFilterState,
        page: resetPage ? 0 : page.current,
        size: PAGE_SIZE,
        order: sort.order > 0 ? 'ASC' : 'DESC',
        sort: sort.field,
      })
      .then((response: any) => {
      // do nothing if this is a response for an older request
        if (currentTimestamp !== lastRequestTimestamp.current) return;

        const payments = response.content;
        setPaymentListState((prevState: any) => prevState.load({
          paymentList: resetPage ? payments : prevState.data.paymentList.concat(payments),
          lastPage: response.last,
        }));
      })
      .catch((err) => {
        page.current -= 1;
        console.error(err);

        setPaymentListState((prevState: any) => ({
          ...paymentListState.failed(),
          data: {
            ...prevState.data,
            lastPage: true,
          },
        }));
        showNotification({
          key: 'loadPaymentListError',
          message: tl(translations.notifications.paymentListContext.loadError.message),
          description: tl(translations.notifications.paymentListContext.loadError.description),
          type: 'error',
        });
      });
  };

  const updatePaymentStatesInList = (paymentIds: Array<number>, paymentState: OrderStateDtoStateEnum) => {
    setPaymentListState(prevState => prevState.load({
      ...prevState.data,
      paymentList: prevState.data.paymentList.map((payment: PaymentDto) => ({
        ...payment,
        paymentState: paymentIds.includes(payment.id!) ? paymentState : payment.paymentState,
      })),
    }));
  };

  const changeState = async (paymentIds: number[], paymentState: OrderStateDtoStateEnum) => {
    const successNotification: NotificationObject = {
      key: 'resetSuccess',
      message: tl(translations.notifications.paymentListContext.stateChange.success),
      type: 'success',
    };
    const errorNotification: NotificationObject = {
      key: 'resetError',
      message: tl(translations.notifications.paymentListContext.stateChange.error),
      type: 'error',
    };
    try {
      await paymentControllerApi.changeStateUsingPATCH1({
        managementCompanyId: paymentFilterState.managementCompanyId,
        orderIds: paymentIds,
        paymentStateDto: {
          state: paymentState,
        },
      });
      showNotification(successNotification);
      updatePaymentStatesInList(paymentIds, paymentState);
    } catch (error) {
      showNotification(errorNotification);
    }
  };

  const onSetToNew = async (paymentIds: number[]) => {
    await changeState(paymentIds, OrderStateDtoStateEnum.NEW);
  };
  const onDelete = async (paymentIds: number[]) => {
    await changeState(paymentIds, OrderStateDtoStateEnum.DELETED);
  };
  const onMarkAsSent = async (paymentIds: number[]) => {
    await changeState(paymentIds, OrderStateDtoStateEnum.SENT);
  };

  const onDownloadAllAsEbics = () => onDownloadAllEbics(paymentFilterState, excludedIbans, onClearPaymentList);

  const onDownloadPaymentsByIdAsEbics = (paymentIds: number[], successCallback: () => void) => onDownloadEbicsFileForPaymentIdList(paymentIds, excludedIbans,
    () => {
      onLoadPaymentList(true);
      successCallback();
    });

  const onLoadDownloadDataByFilters = (onSuccess: () => void) => onLoadEbicsDownloadDataByFilters(paymentFilterState, (ibans: string[]) => {
    setExcludedIbans(ibans);
    onSuccess();
  });

  const onLoadDownloadDataByIds = (paymentIds: number[], onSuccess: () => void) => onLoadEbicsDownloadDataByPaymentIds(paymentIds, (ibans: string[]) => {
    setExcludedIbans(ibans);
    onSuccess();
  });

  const onRepairEbicsUpload = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setPaymentListState(prevState => prevState.startLoading());

      const fileReader = new FileReader();
      fileReader.addEventListener('error', (error) => {
        onUploadError(error);
      });
      fileReader.addEventListener('load', (e: any) => {
        fetch(e.target.result)
          .then((resp) => {
            resp.blob()
              .then((blob) => {
                backend.postFile(`${endpointUrls.PAYMENT}/repair-ebics`, blob, {})
                  .then(() => {
                    onClearPaymentList();
                  })
                  .catch((error) => {
                    onUploadError(error);
                  });
              })
              .catch((error) => {
                onUploadError(error);
              });
          })
          .catch((error) => {
            onUploadError(error);
          });
      });
      fileReader.readAsDataURL(event.target.files[0]);
    }
  };

  const onUploadError = (error: any) => {
    console.error(typeof error);
    onLoadPaymentList(true);
    showNotification({
      key: 'uploadRepairEbicsFile',
      message: tl(translations.notifications.paymentListContext.uploadError.message),
      description: tl(translations.notifications.paymentListContext.uploadError.description),
      type: 'error',
    });
  };

  const onClearPaymentList = (): void => {
    page.current = -1;
    updatePaymentListState({
      paymentList: [],
      lastPage: false,
    });
  };

  const onClearFilterState = (): void => {
    setPaymentFilterState(defaultFilters);
  };

  return (
    <PaymentListContext.Provider value={{
      ...paymentListRef.current,
      paymentFilterState,
      setPaymentFilterState,
      updatePaymentListState,
      updatePaymentFilterState,
      setSortField,
      onLoadPaymentList,
      onClearPaymentList,
      onClearFilterState,
      onDownloadAllAsEbics,
      onDownloadPaymentsByIdAsEbics,
      onRepairEbicsUpload,
      onLoadDownloadDataByFilters,
      onLoadDownloadDataByIds,
      downloadData,
      onSetToNew,
      onDelete,
      onMarkAsSent,
      loadingEbics,
      paymentSortState,
      paymentListLoading: paymentListState.loading,
      sortField: paymentSortRef.current.data.field,
      sortOrder: paymentSortRef.current.data.order,
      lastPage: paymentListRef.current.data.lastPage,
      defaultFilters,
      excludedIbans,
      setExcludedIbans,
    }}
    >
      {children}
    </PaymentListContext.Provider>
  );
}

PaymentListProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
