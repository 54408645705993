import {
  useContext,
  useMemo,
} from 'react';

import {
  Configuration,
  DocumentCreateDtoAssignedToEnum,
  DocumentCreateDtoSourceTypeEnum,
  DocumentLegacyControllerApi,
  DocumentLegacyDto,
  DocumentSplitDto,
} from 'api/document';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import {
  useSimpleDirtModal,
} from 'elements/Modals/DirtModal/SimpleDirtModal/useSimpleDirtModal';
import { showNotification } from 'lib/Notification';
import {
  groupBy,
  isEmpty,
  isEqual,
  isNil,
  uniq,
} from 'lodash';
import {
  CurrentAssignment,
  PagesOrientationType,
} from 'pages/PdfPageAssigner/services/interfaces';
import {
  PdfPageAssignerCurrentAssignmentsContext,
  PdfPageAssignerDataContext,
  PdfPageAssignerSelectedPagesContext,
  PdfPageAssignerUpdatersContext,
} from 'pages/PdfPageAssigner/services/PdfPageAssignerContext';
import {
  useLoadAssignmentDocuments,
} from 'pages/PdfPageAssigner/services/useLoadAssignmentDocuments';
import {
  useLocation,
  useParams,
} from 'react-router';
import { OverlayContext } from 'services/OverlayContext/OverlayContext';

import { translations } from '../../translations';

export const usePdfPageAssignerHeader = () => {
  const { tl } = useContext(LanguageContext);
  const pdfPageAssignerDataContext = useContext(PdfPageAssignerDataContext);
  const pdfPageAssignerSelectedPagesContext = useContext(PdfPageAssignerSelectedPagesContext);
  const currentAssignments = useContext(PdfPageAssignerCurrentAssignmentsContext);
  const pdfPageAssignerUpdatersContext = useContext(PdfPageAssignerUpdatersContext);
  const { goBack } = useContext(OverlayContext);
  const { apiConfiguration } = useContext(AuthContext);
  const documentControllerApi = new DocumentLegacyControllerApi(apiConfiguration('document') as unknown as Configuration);

  const { propertyId, sourceId, sourceType } = useParams<{ [key: string]: string }>();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const overviewNamePrefix = queryParams.get('overviewNamePrefix');


  if (pdfPageAssignerDataContext === undefined || currentAssignments === undefined || pdfPageAssignerUpdatersContext === undefined || pdfPageAssignerSelectedPagesContext === undefined) {
    throw new Error('usePdfPageAssignerHeader must be used within a PdfPageAssignerContextProvider');
  }


  const {
    viewMode,
    contractsWithinDateRange,
    loadedPdfsData,
    property,
    existingAssignments,
    loading,
    originalDocuments,
  } = pdfPageAssignerDataContext;
  const { setViewMode, setLoading, setShowWarningForSelectedPages } = pdfPageAssignerUpdatersContext;

  const { loadAssignmentDocuments } = useLoadAssignmentDocuments();

  const { clearDirty } = useSimpleDirtModal();


  const onChangeViewMode = (vm: PagesOrientationType) => {
    setViewMode(vm);
  };

  const nrAssignedPages = currentAssignments.length;
  const nrTotalContracts = contractsWithinDateRange.data.length;
  const propertyAddress = property.data?.addressConcatenation ?? '';
  const selectedPages = pdfPageAssignerSelectedPagesContext;

  const nrTotalPages = useMemo(
    () => loadedPdfsData.reduce((acc, pdfData) => (acc + pdfData.nrPages), 0),
    [loadedPdfsData],
  );

  const nrAssignedContracts = uniq(
    currentAssignments.map(ca => `${ca.unitId}-${ca.contractId}`)
      .filter(unitAndContractId => unitAndContractId !== 'undefined-undefined'), // if assigned to property then unitId === undefined && contractId === undefined
  ).length;


  const onSave = async (cb?: () => void) => {
    setLoading(true);

    // group currentAssignments by: originalDocumentId & unitId & contractId
    const currentAssignmentsGrouped = groupBy(currentAssignments, (ca => `${ca.originalDocumentId}-${ca.unitId}-${ca.contractId}`));
    const existingAssignmentsGrouped = groupBy(existingAssignments, (ca => `${ca.originalDocumentId}-${ca.unitId}-${ca.contractId}`));

    const keysOfCurrentAssignmentsGrouped = Object.keys(currentAssignmentsGrouped); // set A
    const keysOfExistingAssignmentsGrouped = Object.keys(existingAssignmentsGrouped); // set B

    // A \ B (mathematical Set difference)
    // filtering for current assignments that are not existing assignments (so they are new assignments)
    const assignmentsToCreate = keysOfCurrentAssignmentsGrouped.filter(k => !keysOfExistingAssignmentsGrouped.includes(k));

    // B \ A (mathematical Set difference)
    // filtering for existing assignments that are not found in the current assignments (so they must be deleted)
    const assignmentsToDelete = keysOfExistingAssignmentsGrouped.filter(k => !keysOfCurrentAssignmentsGrouped.includes(k));

    // A ∩ B (mathematical Set intersection)
    const commonAssignmentKeys = keysOfCurrentAssignmentsGrouped.filter(k => keysOfExistingAssignmentsGrouped.includes(k));

    commonAssignmentKeys.forEach((commonKey) => {
      const pagesForCurrentAssignments = currentAssignmentsGrouped[commonKey].map(ca => ca.page);
      const pagesForExistingAssignments = existingAssignmentsGrouped[commonKey].map(ca => ca.pages).flat();

      if (isEqual(pagesForCurrentAssignments, pagesForExistingAssignments)) {
        // nothing changed, do nothing
        return;
      }

      // re-create the assignment because the pages assigned to this entity have changed
      assignmentsToCreate.push(commonKey);
      assignmentsToDelete.push(commonKey);
    });

    const documentIdsToDelete = assignmentsToDelete.map(key => existingAssignmentsGrouped[key])
      .map(assignmentsForThisKey => (assignmentsForThisKey.map(({ assignmentDocumentId }) => assignmentDocumentId)))
      .flat();

    if (!isEmpty(documentIdsToDelete)) {
      try {
        await documentControllerApi.deleteDocumentUsingDELETE({ documentIds: documentIdsToDelete });
      } catch (e) {
        showNotification({
          message: tl(translations.notifications.submitError.canRetry),
          type: 'error',
        });
        console.log(e);
        setLoading(false);

        // if delete failed then we shouldn't attempt the split creation,
        // as we might get duplicate assignments to the same unit/contract/property.
        // and we can allow the user to try again, since no mutation happened yet on the BE
        return;
      }
    }


    if (!isEmpty(selectedPages)) {
      showNotification({
        message: tl(translations.notifications.submitError.noAssigned),
        type: 'warning',
      });
      setShowWarningForSelectedPages(true);
    } else {
      setShowWarningForSelectedPages(false);
    }

    // calculate splits from assignmentsToCreate.map(key => currentAssignmentsGrouped[key])
    const assignmentsMappedToDocumentSplitDto = assignmentsToCreate.map((key) => {
      const assignments = currentAssignmentsGrouped[key];
      const pages = assignments.map(({ page }) => page);

      return {
        originalDocumentId: assignments[0].originalDocumentId,
        splits: createDocumentSplitDto(assignments, pages),
      };
    });


    // group the above by originalDocumentId because splitDocumentUsingPOST expects the data to be sent this way
    const splitsGroupedByOriginalDocumentId = groupBy(assignmentsMappedToDocumentSplitDto, ({ originalDocumentId }) => originalDocumentId);
    const requests = Object.keys(splitsGroupedByOriginalDocumentId).map((key) => {
      const originalDocumentId = parseInt(key, 10);
      const splitsForThisDocumentId = splitsGroupedByOriginalDocumentId[key].map(({ splits }) => splits).flat();

      return documentControllerApi.splitDocumentUsingPOST({
        documentToSplitId: originalDocumentId,
        splits: splitsForThisDocumentId,
      });
    });

    Promise.allSettled(requests)
      .then((results) => {
        const newlyCreatedAssignments = [] as DocumentLegacyDto[];
        const failedToCreateAssignments = [];

        results.forEach((res) => {
          if (res.status === 'fulfilled') {
            newlyCreatedAssignments.push(...res.value);
          } else {
            failedToCreateAssignments.push(res);
          }
        });


        const someSplitRequestsFailed = !isEmpty(failedToCreateAssignments);
        const allSplitRequestsFailed = someSplitRequestsFailed && isEmpty(newlyCreatedAssignments);
        const noDocumentsToDelete = isEmpty(documentIdsToDelete);

        if (allSplitRequestsFailed && noDocumentsToDelete) {
          showNotification({
            message: tl(translations.notifications.submitError.canRetry),
            type: 'error',
          });
          setLoading(false);
          // if all split attempts failed AND no documents have been deleted
          // then we can allow the user to try submit again because no data has been mutated on the BE
          // so the UI is up-to-date
          return;
        }


        // if a document has been deleted and not all split requests have been successful
        // then we re-fetch the data because the UI is no longer up-to-date
        if (someSplitRequestsFailed) {
          showNotification({
            message: tl(translations.notifications.submitError.splitFailed),
            type: 'error',
          });
        } else if (!isEmpty(newlyCreatedAssignments) || !isEmpty(documentIdsToDelete)) {
          clearDirty();
          showNotification({
            message: tl(translations.notifications.submitSuccess),
            type: 'success',
          });
        }

        if (!isEmpty(newlyCreatedAssignments) || !isEmpty(documentIdsToDelete)) {
          loadAssignmentDocuments();
        }

        setLoading(false);
        cb?.();
      });
  };

  const onSaveAndClose = () => {
    onSave(() => goBack());
  };


  const createDocumentSplitDto = (assignments: CurrentAssignment[], pages: number[]): DocumentSplitDto => {
    const { contractId, unitId, originalDocumentId } = assignments[0];

    const assignedTo = (isNil(contractId) && isNil(unitId))
      ? DocumentCreateDtoAssignedToEnum.PROPERTY
      : DocumentCreateDtoAssignedToEnum.UNIT;

    const documentName = originalDocuments.data.find(doc => doc.id === originalDocumentId)?.name;

    const splitDto: DocumentSplitDto = {
      document: {
        name: assignedTo === DocumentCreateDtoAssignedToEnum.PROPERTY ? [overviewNamePrefix, documentName].filter(Boolean).join(' ') : documentName,
        sourceType: sourceType as DocumentCreateDtoSourceTypeEnum,
        sourceId: parseInt(sourceId, 10),
        contractId,
        unitId,
        propertyId: parseInt(propertyId, 10),
        assignedTo,
        metaData: JSON.stringify({ originalDocumentId, originalDocumentPages: pages }),
      },
      pages,
    };

    return splitDto;
  };


  return {
    viewMode,
    loading,
    onChangeViewMode,
    nrAssignedPages,
    nrTotalPages,
    nrAssignedContracts,
    nrTotalContracts,
    propertyAddress,
    onSave,
    onSaveAndClose,
  };
};
