import React, { useContext } from 'react';
import { LanguageContext } from 'contexts/LanguageContext';
import { ColumnsType } from 'antd/lib/table/interface';
import { useDragNDropTableColumns } from 'storybook-components/table/TableDragNDropSorting/useDragNDropTableColumns';
import Amount from 'storybook-components/typography/Amount/Amount';
import { AgendaItemDto } from '../../../../../../api/accounting';
import { ownersMeetingInvitationTranslations } from '../../../translations/OwnersMeetingInvitationTranslations';
import AgendaItemTableOptionsMenu from '../components/AgendaItemTableOptionsMenu';

export const useAgendaItemsTableColumns = () => {
  const { tl } = useContext(LanguageContext);

  const columns: ColumnsType<AgendaItemDto> = [
    {
      title: tl(ownersMeetingInvitationTranslations.agendaItemsTable.table.topicNr),
      dataIndex: 'topicNumber',
      width: '14%',
      render: (amount: number) => (
        <Amount>{amount}</Amount>
      ),
    },
    {
      title: tl(ownersMeetingInvitationTranslations.agendaItemsTable.table.title),
      dataIndex: 'title',
      width: '80%',
    },
    {
      title: '',
      dataIndex: 'optionsMenu',
      width: '6%',
      render: (_, record) => <AgendaItemTableOptionsMenu record={record} />,
    },
  ];

  return {
    columns: useDragNDropTableColumns(columns),
  };
};
