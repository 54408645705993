import {
  AccountBalanceDto,
  AccountDistributionKeyDto, ExtendedAccountBalanceDto, PropertyDisplayDto, UnitContractProjectionDto, UnitProjectionDto,
} from 'api/accounting';
import { createContext, useState } from 'react';
import moment from 'moment';
import { ValidateStatuses } from 'lib/Utils';
import DEFAULT_DATA, { DefaultDataInterface } from 'lib/data';


interface Props {
  children: React.ReactNode
}

export interface ReportCreationData {
  property?: PropertyDisplayDto,
  startDate?: moment.Moment,
  endDate?: moment.Moment,
  documentName?: string,
  isDocumentNameTouched?: boolean,
}

export interface PNLValidationError {
  state: typeof ValidateStatuses[number],
  message: string,
}

export type PNLValidationErrors = Partial<
  Record<keyof ReportCreationData, PNLValidationError>
>;


export interface PnlAccountBalances {
  [key: string]: ExtendedAccountBalanceDto;
}

export interface PnlAccountBalanceWithVatPercentage {
  vatPercentage: number;
  accountBalances: PnlAccountBalances;
  ignoreIfNoBalance: boolean;
}

export const ProfitAndLossReportContext = createContext<| {
  reportData: ReportCreationData,
  setReportData: React.Dispatch<React.SetStateAction<ReportCreationData>>,
  validationErrors: PNLValidationErrors,
  setValidationErrors: React.Dispatch<React.SetStateAction<PNLValidationErrors>>,
  accountBalances: DefaultDataInterface<PnlAccountBalances>,
  setAccountBalances: React.Dispatch<React.SetStateAction<DefaultDataInterface<PnlAccountBalances>>>,
  accountBalancesByCounterpartIncome: DefaultDataInterface<AccountBalanceDto[]>,
  setAccountBalancesByCounterpartIncome: React.Dispatch<React.SetStateAction<DefaultDataInterface<AccountBalanceDto[]>>>,
  accountBalancesByCounterpartNotIncome: DefaultDataInterface<AccountBalanceDto[]>,
  setAccountBalancesByCounterpartNotIncome: React.Dispatch<React.SetStateAction<DefaultDataInterface<AccountBalanceDto[]>>>,
  distributionKeys: DefaultDataInterface<AccountDistributionKeyDto[]>,
  setDistributionKeys: React.Dispatch<React.SetStateAction<DefaultDataInterface<AccountDistributionKeyDto[]>>>,
  units: DefaultDataInterface<UnitProjectionDto[]>,
  setUnits: React.Dispatch<React.SetStateAction<DefaultDataInterface<UnitProjectionDto[]>>>,
  unitContracts: DefaultDataInterface<UnitContractProjectionDto[]>,
  setUnitContracts: React.Dispatch<React.SetStateAction<DefaultDataInterface<UnitContractProjectionDto[]>>>,
  simulationAccountBalances: DefaultDataInterface<PnlAccountBalances>,
  setSimulationAccountBalances: React.Dispatch<React.SetStateAction<DefaultDataInterface<PnlAccountBalances>>>,
  pnLCompatibleProperties: DefaultDataInterface<PropertyDisplayDto[]>
  setPnLCompatibleProperties: React.Dispatch<React.SetStateAction<DefaultDataInterface<PropertyDisplayDto[]>>>,
  incomeAccountBalancesByVatPercentage: DefaultDataInterface<PnlAccountBalanceWithVatPercentage[]>, 
  setIncomeAccountBalancesByVatPercentage: React.Dispatch<React.SetStateAction<DefaultDataInterface<PnlAccountBalanceWithVatPercentage[]>>>;
} | undefined>(undefined);


const ProfitAndLossReportContextProvider = ({ children }: Props) => {
  const [reportData, setReportData] = useState<ReportCreationData>({});
  const [validationErrors, setValidationErrors] = useState<PNLValidationErrors>({});
  const [accountBalances, setAccountBalances] = useState<DefaultDataInterface<PnlAccountBalances>>(DEFAULT_DATA({}));
  const [accountBalancesByCounterpartIncome, setAccountBalancesByCounterpartIncome] = useState<DefaultDataInterface<AccountBalanceDto[]>>(DEFAULT_DATA([]));
  const [accountBalancesByCounterpartNotIncome, setAccountBalancesByCounterpartNotIncome] = useState<DefaultDataInterface<AccountBalanceDto[]>>(DEFAULT_DATA([]));
  const [distributionKeys, setDistributionKeys] = useState<DefaultDataInterface<AccountDistributionKeyDto[]>>(DEFAULT_DATA([]));
  const [units, setUnits] = useState<DefaultDataInterface<UnitProjectionDto[]>>(DEFAULT_DATA([]));
  const [unitContracts, setUnitContracts] = useState<DefaultDataInterface<UnitContractProjectionDto[]>>(DEFAULT_DATA([]));
  const [simulationAccountBalances, setSimulationAccountBalances] = useState<DefaultDataInterface<PnlAccountBalances>>(DEFAULT_DATA({}));
  const [pnLCompatibleProperties, setPnLCompatibleProperties] = useState(DEFAULT_DATA<PropertyDisplayDto[]>([]));
  const [incomeAccountBalancesByVatPercentage, setIncomeAccountBalancesByVatPercentage] = useState<DefaultDataInterface<PnlAccountBalanceWithVatPercentage[]>>(DEFAULT_DATA([]));

  const reportDataContextValue = {
    reportData,
    setReportData,
    validationErrors,
    setValidationErrors,
    accountBalances,
    setAccountBalances,
    accountBalancesByCounterpartIncome,
    setAccountBalancesByCounterpartIncome,
    accountBalancesByCounterpartNotIncome,
    setAccountBalancesByCounterpartNotIncome,
    distributionKeys,
    setDistributionKeys,
    units,
    setUnits,
    unitContracts,
    setUnitContracts,
    simulationAccountBalances,
    setSimulationAccountBalances,
    pnLCompatibleProperties,
    setPnLCompatibleProperties,
    incomeAccountBalancesByVatPercentage,
    setIncomeAccountBalancesByVatPercentage
  };

  return (
    <ProfitAndLossReportContext.Provider value={reportDataContextValue}>
      {children}
    </ProfitAndLossReportContext.Provider>
  );
};

export default ProfitAndLossReportContextProvider;
