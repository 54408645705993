import React, { ChangeEvent, useState } from 'react';
import { Form, Input } from 'antd';
import './CurrencyInputWithPrefix.scss';
import { useDebounceEffect } from 'ahooks';
import InputProps from '../InputProps';
import { floatToFormattedString, formatCurrencyString, formattedStringToFloat } from '../../../lib/Utils';


interface CurrencyInputWithPrefixProps extends InputProps<number> {
  inputClassName?: string,
  prefixComponent: JSX.Element,
  autoFormat: boolean,
}

const CURRENCY = '€';
const VALID_NUMBER_REGEX = /^[\d.]*(,\d{0,2})?$/;

const CurrencyInputWithPrefix = ({
  value,
  inputClassName,
  prefixComponent,
  validationMessage,
  disabled,
  label,
  required,
  validationState,
  onChange,
  autoFormat,
  autoFocus,
}: CurrencyInputWithPrefixProps) => {
  const [stringValue, setStringValue] = useState(floatToFormattedString(value));

  useDebounceEffect(() => {
    if (isDefined(value) && autoFormat && value !== formattedStringToFloat(stringValue)) {
      setStringValue(floatToFormattedString(value));
    }
  }, [value], { wait: 200 });

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (VALID_NUMBER_REGEX.test(e.target.value)) {
      setStringValue(formatCurrencyString(e.target.value));
      onChange(formattedStringToFloat(formatCurrencyString(e.target.value)));
    }
  };

  const isDefined = (v: any) => value !== null && v !== undefined && !Number.isNaN(v);

  function formatStringValue() {
    setStringValue(floatToFormattedString(formattedStringToFloat(stringValue)));
  }

  return (
    <div className={`NumberInput ${inputClassName ?? ''}`}>
      <Form.Item
        label={`${label}${required ? ' *' : ''}`}
        validateStatus={disabled ? '' : (validationState || 'success')}
        help={disabled ? '' : (
          <span className="validation-message">
            {validationMessage}
          </span>
        )}
      >
        <Input
          className={`${disabled ? 'disabled' : ''}`}
          inputMode="decimal"
          disabled={disabled}
          value={stringValue}
          readOnly={disabled}
          onChange={onChangeInput}
          suffix={CURRENCY}
          prefix={prefixComponent}
          onFocus={e => e.target.select()}
          onClick={e => e.currentTarget.select()}
          onBlur={() => formatStringValue()}
          autoFocus={autoFocus}
        />
      </Form.Item>
    </div>
  );
};

export default CurrencyInputWithPrefix;


CurrencyInputWithPrefix.defaultProps = {
  inputClassName: '',
};
