import { ConfigProvider, Table } from 'antd';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import { SectionWrapper } from 'storybook-components/sections/SectionWrapper/SectionWrapper';
import EmptyTableMultilineMessage from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import { isEmpty } from 'lodash';
import { opsCostTranslation } from '../../../translations';
import YourPrepaymentsTableSummary from './components/YourPrepaymentTableSummary';
import { useYourPrepaymentsSection as hook } from './useYourPrepaymentsSection';


type ReturnTypeOfHook = ReturnType<typeof hook>;
type ReturnTypeOfYourPrepaymentsHook = ReturnType<ReturnTypeOfHook['getYourPrepaymentsSectionProps']>


interface YourPrepaymentsSectionProps {
  tl: any,
  openSectionId: number,
  useYourPrepaymentsSection: ReturnTypeOfYourPrepaymentsHook,
}


const YourPrepaymentsSection = ({
  tl,
  useYourPrepaymentsSection,
  openSectionId,
}: YourPrepaymentsSectionProps) => {
  const {
    columns, prepaymentsDataSource, prepaymentsTotalSum, loading, isContractVatRelevant,
  } = useYourPrepaymentsSection;

  // if the table is empty we need to set the height, otherwise the Empty component will not be visible
  const tableHeight = isEmpty(prepaymentsDataSource) ? { y: 200 } : undefined;

  return (
    <SectionWrapper
      sectionId="YourPrepaymentsSection"
      className="YourPrepaymentsSection"
      sectionNumber={3}
      sectionTitle={tl(opsCostTranslation.report.sections.yourPrepaymentsSection.titel)}
      isOpen={openSectionId === 1}
      sectionDisabled={openSectionId !== 1}
    >
      <ConfigProvider renderEmpty={() => <EmptyTableMultilineMessage description={tl(opsCostTranslation.report.emptyTable.yourPayments)} />}>
        <Table
          columns={columns}
          dataSource={prepaymentsDataSource}
          rowKey="index"
          pagination={false}
          scroll={tableHeight}
          loading={{
            indicator: <TableContentLoadingIndicator />,
            spinning: loading,
          }}
          summary={() => (
            <YourPrepaymentsTableSummary
              title={tl(opsCostTranslation.report.sections.yourPrepaymentsSection.columns.total)}
              summaryData={prepaymentsTotalSum}
              nrTotalColumns={columns.length}
              isContractVatRelevant={isContractVatRelevant}
            />
          )}
        />
      </ConfigProvider>
    </SectionWrapper>
  );
};

export default YourPrepaymentsSection;
