import SearchBar from 'storybook-components/SearchBar/SearchBar';
import { useRecipientSearchBar } from './useRecipientSearchBar';

const RecipientSearchBar = () => {
  const { searchInputValue, onChangeSearchValue } = useRecipientSearchBar();

  return (
    <SearchBar
      searchValue={searchInputValue}
      onSearch={onChangeSearchValue}
    />
  );
};

export default RecipientSearchBar;
