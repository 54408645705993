import { getColorClassFromColor } from 'theming';
import './Label.scss';
import { CustomLabelTheme } from './types/CustomTheme';

export interface LabelProps {
  label: string;
  testId: string;
  customTheme?: CustomLabelTheme;
}

export default function Label({ label, testId, customTheme }: LabelProps): JSX.Element {
  return (
    <label className={`Label ${getColorClassFromColor(customTheme?.color)}`} data-testid={testId} title={label}>{label}</label>
  )
}