import { useContext } from 'react';

import { Alert } from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import DeleteButton from 'elements/FormElements/FormSection/DeleteButton';
import ProgressBar from 'storybook-components/feedback/ProgressBar/ProgressBar';
import {
  SectionWrapper,
} from 'storybook-components/sections/SectionWrapper/SectionWrapper';

import {
  FileUploadButton,
} from '../components/FileUploadButton/FileUploadButton';
import { translations } from './translations';
import { useResolutionRecordsCsv } from './useResolutionRecordImportCsv';

export const ResolutionRecordImportSection = () => {
  const { tl } = useContext(LanguageContext);
  const {
    loadCsvFile, resolutionRecords, validationIssues, importRRs, importFinished, deleteAllImported, percentage, importStarted,
  } = useResolutionRecordsCsv();

  const showImport = validationIssues.length < 1 && resolutionRecords.length > 0;
  return (
    <SectionWrapper
      sectionNumber={7}
      sectionTitle={tl(translations.title)}
      sectionId="resolution-record-import"
    >
      {!importStarted ? (
        <>
          <FileUploadButton
            label={tl(translations.upload)}
            accept="txt/csv"
            onChange={loadCsvFile}
          />
          {resolutionRecords.length > 0
            ? <Alert message={`${resolutionRecords.length} ${tl(translations.validations.success)}`} type="success" />
            : <Alert message={tl(translations.description)} />
          }
          {validationIssues.length > 0 && (
            <Alert
              message={tl(translations.validations.errors)}
              type="error"
              description={(
                <ul>
                  {
                    validationIssues.map(issue => (
                      <li key={issue.row + issue.message}>
                        {`${issue.row + 1}: ${issue.message}`}
                      </li>
                    ))
                  }
                </ul>
              )}
            />
          )}
          {showImport && (<Button onClick={importRRs} type="primary">{tl(translations.import)}</Button>)}
        </>
      ) : (
        <>
          <ProgressBar
            title={tl(translations.import.title)}
            description={tl(translations.import.description)}
            data={[{
              label: tl(translations.title),
              percentage,
            }]}
          />
          {importFinished && (<DeleteButton onClick={deleteAllImported} />)}
        </>
      )}
    </SectionWrapper>
  );
};
