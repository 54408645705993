import * as React from 'react';
import RcInputNumber from 'rc-input-number';

import Icon from '@ant-design/icons';


import { ConfigConsumer, ConfigConsumerProps } from 'antd/lib/config-provider';
import { ICONS } from '../../../components/icons';


type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
// omitting this attrs because they conflicts with the ones defined in InputNumberProps
export type OmitAttrs = 'onInput' | 'defaultValue' | 'onInput' | 'onChange';

export interface InputNumberProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, OmitAttrs> {
  prefixCls?: string;
  min?: number;
  max?: number;
  value?: number;
  step?: number;
  tabIndex?: number;
  onChange?: (value: number) => void;
  disabled?: boolean;
  readOnly?: boolean;
  formatter?: (value: number | string | undefined) => string;
  parser?: (displayValue: string | undefined) => number;
  decimalSeparator?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
  name?: string;
  id?: string;
  precision?: number;
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  defaultValue?: number,
}

const InputNumber = React.forwardRef<unknown, InputNumberProps>((props) => {
  const renderInputNumber = ({ getPrefixCls }: ConfigConsumerProps) => {
    const {
      className,
      prefixCls: customizePrefixCls,
      readOnly,
      defaultValue,
      ...others
    } = props;
    const prefixCls = getPrefixCls('input-number', customizePrefixCls);
    const upIcon = <Icon component={ICONS.increment} />;
    const downIcon = <Icon component={ICONS.decrement} />;

    const onChange = (value: number) => {
      if (others.onChange && typeof value !== 'string') others.onChange(value);
    };
    return (
      <RcInputNumber
        className={`InputNumber ${className}`}
        upHandler={upIcon}
        defaultValue={defaultValue}
        downHandler={downIcon}
        prefixCls={prefixCls}
        readOnly={readOnly}
        {...others}
        // @ts-ignore
        onChange={onChange}
      />
    );
  };

  return <ConfigConsumer>{renderInputNumber}</ConfigConsumer>;
});

InputNumber.defaultProps = {
  step: 1,
};

export default InputNumber;
