import './AgendaItemDetails.scss';

import React, { useContext } from 'react';

import Button from 'elements/Buttons/Button/Button';
import DirtModal from 'elements/Modals/DirtModal/DirtModal';
import { useLocation } from 'react-router';
import {
  SectionWrapper,
} from 'storybook-components/sections/SectionWrapper/SectionWrapper';

import Page from 'storybook-components/layout/Page/Page';
import PageContent from 'storybook-components/layout/PageContent/PageContent';
import PageHeader from 'storybook-components/layout/PageHeader/PageHeader';
import { LanguageContext } from '../../../../../../../contexts/LanguageContext';
import AgendaItemVoteContextProvider
  from '../../OwnersMeetingProtocolVoting/AgendaItemVoteContext';
import AgendaItemVotingResult
  from '../../OwnersMeetingProtocolVoting/AgendaItemVotingResult';
import AgendaItemDetailsContextProvider
  from '../services/AgendaItemDetailsContext';
import { useAgendaItemDetails } from '../services/useAgendaItemDetails';
import {
  translations as ownersMeetingProtocolTranslations,
} from '../translations';
import AgendaItemDetailsBody from './AgendaItemDetailsBody';

function AgendaItemDetails(): JSX.Element {
  const { tl } = useContext(LanguageContext);
  const {
    selectedAgendaItem,
    onSaveAgendaItem,
    onClickSaveAndOpenNext,
    nextAgendaItem,
    loading,
    setSelectedAgendaItem,
    dirtModalVisible,
    goBack,
    setDirtModalVisible,
    isDisabled,
    onClickClose,
  } = useAgendaItemDetails();


  const location = useLocation();
  const creationMode = location.pathname.includes('create');

  const headerButtons = (
    <div className="header-buttons">
      <Button
        type="text"
        onClick={onClickClose}
        disabled={loading}
      >
        {tl(ownersMeetingProtocolTranslations.agendaItemDetails?.header.close)}
      </Button>
      <Button
        type="ghost"
        onClick={() => onSaveAgendaItem(goBack)}
        disabled={isDisabled}
        loading={loading}
      >
        {tl(ownersMeetingProtocolTranslations.agendaItemDetails?.header.saveAndClose)}
      </Button>
      <Button
        type="primary"
        onClick={() => {
          if (creationMode) {
            // @ts-ignore
            onSaveAgendaItem(() => setSelectedAgendaItem(prev => prev.load({})));
          } else {
            onClickSaveAndOpenNext();
          }
        }}
        loading={loading}
        disabled={creationMode ? isDisabled : (isDisabled || nextAgendaItem === undefined)}
      >
        {creationMode
          ? tl(ownersMeetingProtocolTranslations.agendaItemDetails?.header?.saveAndCreateNew)
          : tl(ownersMeetingProtocolTranslations.agendaItemDetails?.header.saveAndOpenNext)}
      </Button>
    </div>
  );

  return (
    <Page className="AgendaItemDetails">
      <PageHeader
        title={`${tl(ownersMeetingProtocolTranslations.detailsTitle)} ${selectedAgendaItem?.data?.topicNumber ?? ''}: ${selectedAgendaItem?.data?.title ?? ''}`}
        rightSideComponent={headerButtons}
      />
      <DirtModal
        visible={dirtModalVisible}
        onCancel={() => setDirtModalVisible(false)}
        onDiscard={() => {
          setDirtModalVisible(false);
          goBack();
        }}
        onSave={() => {
          onSaveAgendaItem();
          setDirtModalVisible(false);
          goBack();
        }}
        loading={loading}
      />
      <PageContent className="page-content">
        <SectionWrapper
          sectionId="AgendaItemDetailsBody"
          className="AgendaItemDetailsBody"
          sectionNumber={1}
          sectionTitle={tl(ownersMeetingProtocolTranslations.sections.details)}
        >
          <AgendaItemDetailsBody />
        </SectionWrapper>
        <SectionWrapper
          sectionId="AgendaItemVoteBody"
          className="AgendaItemVoteBody"
          sectionNumber={2}
          sectionTitle={tl(ownersMeetingProtocolTranslations.sections.vote)}
        >
          <AgendaItemVotingResult />
        </SectionWrapper>
      </PageContent>
    </Page>
  );
}

export default () => (
  <AgendaItemDetailsContextProvider>
    <AgendaItemVoteContextProvider>
      <AgendaItemDetails />
    </AgendaItemVoteContextProvider>
  </AgendaItemDetailsContextProvider>
);
