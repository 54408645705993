import YourPrepaymentsSection from 'pages/OpsCostReport/OpsCostReportEditing/components/YourPrepaymentsSection/YourPrepaymentsSection';
import YourSettlementSection from 'pages/OpsCostReport/OpsCostReportEditing/components/YourSettlementSection/YourSettlementSection';
import { renderToString } from 'react-dom/server';
import PageBreakWrapper from 'pages/ProfitAndLossReport/ProfitAndLossReportEditing/components/PageBreakWrapper/PageBreakWrapper';
import { OpsCostReportDocumentCover } from '../components/OpsCostReportEditorForm/components/OpsCostReportDocumnetCover/OpsCostReportDocumentCover';
import { HtmlAndDetailsForContract } from './interfaces';
import ExpensesSection from '../components/OpsCostReportEditorForm/ExpensesSection/ExpensesSection';
import OtherOpsCostsSection from '../components/OpsCostReportEditorForm/ExpensesSection/OtherOpsCostsSection/OtherOpsCostsSection';
import TotalExpensesSection from '../components/OpsCostReportEditorForm/ExpensesSection/TotalExpensesSection/TotalExpensesSection';
import DistributionSetsSection from '../components/OpsCostReportEditorForm/ExpensesSection/DistributionSetsSection/DistributionSetsSection';
import HeatingCostSettlementSection from '../components/OpsCostReportEditorForm/ExpensesSection/HeatingCostSettlementSection/HeatingCostSettlementSection';
import Annex35aSection from '../components/Annex35aSection/Annex35aSection';
import BankAccountInformationSection from '../components/OpsCostReportDocumentNonSectionElements/BankAccountInformationSection';

export const useOpsCostReportHtmlGenerator = (eventData) => {
  // OPS_COST_REPORT template render logic

  const { data, language, sourceId } = eventData;


  const tl = (obj) => {
    try {
      return obj[language];
    } catch (error) {
      return 'tl-err';
    }
  };

  const getSectionsForOneContract = (props: any) => {
    const {
      getYourSettlementSectionProps,
      getHeatingCostSettlementSectionProps,
      getYourPrepaymentsSectionProps,
      getReportDocumnetCoverProps,
      getOtherOpsCostsSectionProps,
      getDistributionSetSectionProps,
      getTotalExpensesSectionProps,
      getAnnex35aSectionProps,
      getOpsCostReportNonSectionElementsData,
      openSectionId,
      tenantContract,
    } = props;
    const reportDocumentCoverSectionProps = getReportDocumnetCoverProps(tenantContract.unitContractId);
    const yourPrepaymentsSectionProps = getYourPrepaymentsSectionProps(tenantContract.unitContractId);
    const heatingCostSettlementSectionProps = getHeatingCostSettlementSectionProps(tenantContract.unitContractId);
    const otherOpsCostsSectionProps = getOtherOpsCostsSectionProps(tenantContract.unitContractId);
    const totalExpensesSectionProps = getTotalExpensesSectionProps(heatingCostSettlementSectionProps.heatingCostSectionTotal, otherOpsCostsSectionProps.totalSummaryData, tenantContract.unitContractId, false);
    const yourSettlementSectionProps = getYourSettlementSectionProps(yourPrepaymentsSectionProps.prepaymentsTotalSum, totalExpensesSectionProps.totalSummaryData, tenantContract.unitContractId, false);
    const distributionSetsProps = getDistributionSetSectionProps(tenantContract.unitContractId);
    const annex35aSectionProps = getAnnex35aSectionProps(tenantContract.unitContractId);
    const { recipientAddressLines } = reportDocumentCoverSectionProps;
    const contractAddress = recipientAddressLines.join(' ');

    const { totalSummaryData } = yourSettlementSectionProps;
    const reportDocumentCoverSection = (
      <OpsCostReportDocumentCover
        useReportDocumentCover={reportDocumentCoverSectionProps}
      />
    );
    const opsCostReportNonSectionElementsData = getOpsCostReportNonSectionElementsData();

    const settlementSection = (
      <YourSettlementSection
        tl={tl}
        openSectionId={openSectionId}
        useYourSettlementSection={yourSettlementSectionProps}
      />
    );

    const bankAccountInformationSection = (
      <BankAccountInformationSection
        tl={tl}
        useOpsCostReportNonSectionElementsData={opsCostReportNonSectionElementsData}
        contractBankAccountInformation={{
          iban: tenantContract.bankAccountIban,
          accountHolderName: tenantContract.bankAccountHolderName,
        }}
        settlementTotal={totalSummaryData.gross}
      />
    );

    const expensesSection = (
      <ExpensesSection
        tl={tl}
        openSectionId={openSectionId}
      >
        <DistributionSetsSection tl={tl} useDistributionSetsSection={distributionSetsProps} />
        <HeatingCostSettlementSection sectionNumber={2} tl={tl} useHeatingCostSettlementSection={heatingCostSettlementSectionProps} />
        <OtherOpsCostsSection sectionNumber={2} tl={tl} useOtherOpsCostsSection={otherOpsCostsSectionProps} />
        <TotalExpensesSection tl={tl} useTotalExpensesSection={totalExpensesSectionProps} sectionNumber={2} />
      </ExpensesSection>
    );

    const prepaymentsSection = (
      <YourPrepaymentsSection openSectionId={openSectionId} tl={tl} useYourPrepaymentsSection={yourPrepaymentsSectionProps} />
    );

    const annexSection = (
      <PageBreakWrapper breakBefore>
        <Annex35aSection openSectionId={openSectionId} tl={tl} useAnnex35aSection={annex35aSectionProps} />
      </PageBreakWrapper>
    );

    return {
      settlementSection,
      expensesSection,
      prepaymentsSection,
      yourSettlementSectionProps,
      tenantContract,
      reportDocumentCoverSection,
      annexSection,
      bankAccountInformationSection,
      contractAddress,
    };
  };

  const sectionsSeparateForEachContract: HtmlAndDetailsForContract[] = data.reduce((list, props) => {
    const {
      settlementSection, expensesSection, prepaymentsSection, tenantContract, reportDocumentCoverSection, bankAccountInformationSection, annexSection, yourSettlementSectionProps, contractAddress,
    } = getSectionsForOneContract(props);
    const sections = (
      <>
        {reportDocumentCoverSection}
        {settlementSection}
        {bankAccountInformationSection}
        {expensesSection}
        {prepaymentsSection}
        {annexSection}
      </>
    );
    const numberOfContractsWithTheSameName: HtmlAndDetailsForContract[] = list?.filter(section => section.contractName === tenantContract?.contractName);
    const sectionsForContract: HtmlAndDetailsForContract = {
      contractId: tenantContract?.unitContractId,
      contractName: tenantContract?.mailingContact?.name,
      index: numberOfContractsWithTheSameName?.length,
      unitId: tenantContract.unitId,
      unitNrSharingDeclaration: tenantContract?.unitNrSharingDeclaration,
      html: renderToString(sections),
      amount: yourSettlementSectionProps.totalSummaryData.gross,
      contractAddress,
    };
    list.push(sectionsForContract);
    return list;
  }, []);

  return { separateHtmlForEachContractWithContractDetails: sectionsSeparateForEachContract, sourceId };
};
