import { useContext } from 'react';
import { Column } from 'elements/SmartTable/SmartTable';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translations } from '../translations';
import BooleanCell from '../../../elements/SmartTable/components/BooleanCell/BooleanCell';

export const usePropertyDistributionKeyListColumns = (isWeg:boolean, isForOpsCost:boolean): (Column)[] => {
  const { tl } = useContext(LanguageContext);

  return [
    {
      title: tl(translations.propertyDistributionKey.table.headers.accountCode),
      dataKey: 'accountCode',
      defaultWidth: isWeg ? '11%' : '20%',
    },
    {
      title: tl(translations.propertyDistributionKey.table.headers.accountName),
      dataKey: 'accountName',
      defaultWidth: isWeg ? '30%' : '40%',
    },
    {
      title: tl(translations.propertyDistributionKey.table.headers.apply),
      dataKey: 'apply',
      defaultWidth: isWeg ? '8%' : '20%',
      render: BooleanCell,
    },
    ...(isWeg && !isForOpsCost
      ? [{
        title: tl(translations.propertyDistributionKey.table.headers.ecoPlan),
        dataKey: 'economicPlan',
        defaultWidth: '15%',
      }, {
        title: tl(translations.propertyDistributionKey.table.headers.settlement),
        dataKey: 'houseMoneySettlement',
        defaultWidth: '15%',
      }] : []),
    ...(isWeg && isForOpsCost
      ? [{
        title: tl(translations.propertyDistributionKey.table.headers.opsCost),
        dataKey: 'opsCost',
        render: (_, record) => (
          <>
            {record.opsCost}
            {record.fromHga
          && (
            <span className="hga-label">
              {` (${tl(translations.propertyDistributionKey.hgaLabel)})`}
            </span>
          )}
          </>
        ),
        defaultWidth: '20%',
      }] : []),
    {
      title: tl(translations.propertyDistributionKey.table.headers.unitsExcluded),
      dataKey: 'unitsExcluded',
      defaultWidth: isWeg ? '13%' : '15%',
      render: BooleanCell,
      defaultVisible: isWeg,
    },
    {
      title: tl(translations.propertyDistributionKey.table.headers.wkaRelevant),
      dataKey: 'wkaRelevant',
      render: BooleanCell,
    },
  ];
};
