/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
    /**
     * 
     * @type {Date}
     * @memberof RoleDto
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof RoleDto
     */
    domainId?: number;
    /**
     * 
     * @type {number}
     * @memberof RoleDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleDto
     */
    permissions?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof RoleDto
     */
    updated?: Date;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    updatedBy?: string;
}

/**
 * Check if a given object implements the RoleDto interface.
 */
export function instanceOfRoleDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RoleDtoFromJSON(json: any): RoleDto {
    return RoleDtoFromJSONTyped(json, false);
}

export function RoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'domainId': !exists(json, 'domainId') ? undefined : json['domainId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function RoleDtoToJSON(value?: RoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'createdBy': value.createdBy,
        'description': value.description,
        'domainId': value.domainId,
        'id': value.id,
        'name': value.name,
        'permissions': value.permissions,
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'updatedBy': value.updatedBy,
    };
}

