import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import { Col, Row } from 'antd';
import { AgendaItemDtoAgendaItemTypeEnum } from 'api/accounting';
import RichTextEditor from 'elements/Inputs/RichTextEditor/RichTextEditor';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import './AgendaItemDetails.scss';
import SelectInput from '../../../../../../elements/Inputs/SelectInput/SelectInput';
import { OptionInterface } from '../../../../../../elements/Inputs/SmartSearch/SmartSearch';
import TextInput from '../../../../../../elements/Inputs/TextInput/TextInput';
import SubSectionWrapper from '../../../../../../storybook-components/sections/SubSectionWrapper/SubSectionWrapper';
import { ownersMeetingInvitationTranslations } from '../../../translations/OwnersMeetingInvitationTranslations';
import { useAgendaItemDetails } from '../services/useAgendaItemDetails';


const AgendaItemDetailsBody = () => {
  const { tl } = useContext(LanguageContext);

  const {
    selectedAgendaItem, onChange, onChangeTemplate, templateAgendaItems, loading,
  } = useAgendaItemDetails();
  const templateAgendaOptions: OptionInterface[] = templateAgendaItems.map(item => ({
    label: item.label,
    value: item.label,
  }));

  const location = useLocation();
  const isDisabled = location.pathname.includes('view') || loading;
  const isDiscussionTopic = selectedAgendaItem?.data?.agendaItemType === AgendaItemDtoAgendaItemTypeEnum.DISCUSSION_TOPIC;

  const currentTemplate = templateAgendaItems.find(item => item?.value?.resolutionRecordText === (selectedAgendaItem?.data?.resolutionRecordText)?.replace(/<[^>]*>?/gm, ''))?.value?.title ?? undefined;
  return (
    <SubSectionWrapper title={tl(ownersMeetingInvitationTranslations.agendaItemDetails.topDetailsTitle)}>
      <Row gutter={[40, 20]}>
        <Col span={12}>
          <SelectInput
            value={selectedAgendaItem?.data?.agendaItemType || AgendaItemDtoAgendaItemTypeEnum.PROPOSAL}
            label={tl(ownersMeetingInvitationTranslations.agendaItemDetails?.typeField)}
            dropdownMatchSelectWidth={false}
            options={[
              {
                value: AgendaItemDtoAgendaItemTypeEnum.PROPOSAL,
                label: tl(ownersMeetingInvitationTranslations.agendaItemDetails?.typeOptions?.proposal),
              },
              {
                value: AgendaItemDtoAgendaItemTypeEnum.DISCUSSION_TOPIC,
                label: tl(ownersMeetingInvitationTranslations.agendaItemDetails?.typeOptions?.discussionTopic),
              },

            ]}
            onChange={(val: string) => { onChange('agendaItemType', val); }}
            required
            disabled={isDisabled}
          />
        </Col>
        <Col span={12}>
          <SelectInput
            value={currentTemplate}
            label={tl(ownersMeetingInvitationTranslations.editPage.sections.agendaSection.fields.templateSelector)}
            placeholder={tl(ownersMeetingInvitationTranslations.agendaItemDetails?.placeholder)}
            dropdownMatchSelectWidth={false}
            options={templateAgendaOptions}
            onChange={(value) => {
              onChangeTemplate(value);
            }}
            disabled={isDisabled}
          />
        </Col>
        <Col span={24}>
          <TextInput
            value={selectedAgendaItem?.data?.title || ''}
            label={tl(ownersMeetingInvitationTranslations.editPage.sections.agendaSection.fields.title)}
            onChange={(val: string) => { onChange('title', val); }}
            required
            disabled={isDisabled}
          />
        </Col>
        <Col span={24}>
          <RichTextEditor
            value={selectedAgendaItem?.data?.comment || ' '}
            onChange={(val: string) => { onChange('comment', val); }}
            label={tl(ownersMeetingInvitationTranslations.editPage.sections.agendaSection.fields.description)}
            className="input"
            disabled={isDisabled}
          />
        </Col>
        <Col span={24}>
          <RichTextEditor
            value={isDiscussionTopic ? ' ' : (selectedAgendaItem?.data?.resolutionRecordText || ' ')}
            onChange={(val: string) => { onChange('resolutionRecordText', val); }}
            label={tl(ownersMeetingInvitationTranslations.editPage.sections.agendaSection.fields.resolutionRecordText)}
            className="input"
            disabled={isDisabled || isDiscussionTopic}
          />
        </Col>
      </Row>
    </SubSectionWrapper>
  );
};

export default AgendaItemDetailsBody;
