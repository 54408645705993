import { opsCostTranslation } from 'pages/OpsCostReport/translations';
import { useOpsCostReportNonSectionElementsData as hook } from './useOpsCostReportNonSectionElementsData';
import './BankAccountInformationSection.scss';

type ReturnTypeOfHook = ReturnType<typeof hook>;
type ReturnTypeOfOpsCostReportNonSectionElementsDataHook = ReturnType<ReturnTypeOfHook['getOpsCostReportNonSectionElementsData']>

interface contractBankAccountInformation {
  iban: string,
  accountHolderName: string,
}

interface BankAccountInformationSectionProps {
  tl: any,
  useOpsCostReportNonSectionElementsData: ReturnTypeOfOpsCostReportNonSectionElementsDataHook,
  contractBankAccountInformation: contractBankAccountInformation,
  settlementTotal: number
}

const BankAccountInformationSection = ({
  tl,
  useOpsCostReportNonSectionElementsData,
  contractBankAccountInformation,
  settlementTotal,
}: BankAccountInformationSectionProps) => {
  const { propertyBankAccount, managemenyCompanyName } = useOpsCostReportNonSectionElementsData;

  const additionalPaymentSection = (
    <p>
      {tl(opsCostTranslation.opsCostReportDocument.paymentInformation.additionalPaymentWithoutMandateInformation)}
      <br />

      <b>
        {tl(opsCostTranslation.opsCostReportDocument.paymentInformation.ibanLabel)}
      </b>
      {` ${propertyBankAccount?.data?.iban}`}
      <br />

      <b>
        {tl(opsCostTranslation.opsCostReportDocument.paymentInformation.accountHolderLabel)}
      </b>
      {` ${propertyBankAccount?.data?.accountHolderName}`}
      <br />

      {tl(opsCostTranslation.opsCostReportDocument.paymentInformation.additionalPaymentWithMandateInformation)}
    </p>
  );

  const payoutSection = (
    <p className="payment-info">
      {contractBankAccountInformation.iban && contractBankAccountInformation.accountHolderName
        ? (
          <>
            {tl(opsCostTranslation.opsCostReportDocument.paymentInformation.payoutWithBankAccountInformation)}
            <br />

            <b>
              {tl(opsCostTranslation.opsCostReportDocument.paymentInformation.ibanLabel)}
            </b>
            {` ${contractBankAccountInformation.iban}`}
            <br />

            <b>
              {tl(opsCostTranslation.opsCostReportDocument.paymentInformation.accountHolderLabel)}
            </b>
            {` ${contractBankAccountInformation.accountHolderName}`}
          </>
        )
        : (
          <>
            {tl(opsCostTranslation.opsCostReportDocument.paymentInformation.payoutWithoutBankAccountInformation)}
          </>
        )
      }
    </p>
  );

  return (
    <div className="PaymentInfoSection">
      { settlementTotal > 0
        ? additionalPaymentSection
        : payoutSection
      }
      <p>
        {tl(opsCostTranslation.opsCostReportDocument.regards)}
        <br />
        {managemenyCompanyName}
      </p>
    </div>
  );
};
export default BankAccountInformationSection;
