import { InvoiceLegacyControllerApi, InvoiceLegacyDto, PageOfInvoiceLegacyProjection } from 'api/accounting';
import { useContext, useReducer, useRef } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';

type InvoiceIdList = {
  [id: string]: InvoiceLegacyDto
}

type InvoiceListAction =
  { type: 'reset' } |
  { type: 'loadList', data: InvoiceLegacyDto[] }

export function useInvoiceIdList() {
  function invoiceReducer(state: InvoiceIdList, action: InvoiceListAction) {
    switch (action.type) {
    case 'reset':
      return {};
    case 'loadList':
      return {
        ...state,
        ...action.data.reduce((acc, i) => {
          acc[i.id] = i;
          return acc;
        }, {}),
      };
    default:
      return state;
    }
  }

  const [state, dispatch] = useReducer(invoiceReducer, {});
  const requested = useRef<number[]>([]);
  const { apiConfiguration } = useContext(AuthContext);
  const invoiceControllerApi = new InvoiceLegacyControllerApi(apiConfiguration('accounting'));

  const onLoadInvoicesByIds = async (invoiceIds: number[]) => {
    invoiceIds = invoiceIds.filter(id => requested.current.indexOf(id) === -1);
    if (!invoiceIds?.length) return;

    requested.current.push(...invoiceIds);
    invoiceControllerApi.findFilteredInvoicesUsingGET({ invoiceIds, size: 99999 })
      .then((response: PageOfInvoiceLegacyProjection) => {
        dispatch({ type: 'loadList', data: response?.content as unknown as InvoiceLegacyDto[] });
      })
      .catch(() => {
        // NOOP
        // OK since we don't expect all ids to exist
      });
  };

  return {
    invoiceList: state,
    dispatch,
    onLoadInvoicesByIds,
  };
}
