import useSmartTable from 'elements/SmartTable/useSmartTable';
import { LanguageContext } from 'contexts/LanguageContext';
import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import { usePropertyDistributionKeysTable } from './usePropertyDistributionKeysTable';
import { translations } from '../translations';
import { usePropertyDistributionKeyListColumns } from './usePropertyDistributionKeyListColumns';


export const useGetPropertyDistributionKeySmartTableProps = (isWeg:boolean, isForOpsCost:boolean, dataSource: any[], tableName: string, tableKey:string, onCopy: (record: any) => void) => {
  const { tl } = useContext(LanguageContext);
  const location = useLocation();
  const history = useHistory();
  const {
    changeWkaRelevantFlagInList,
    selectedEconomicYear,
    setSelectedEconomicYear,
    propertyDistributionKeyListState,
  } = usePropertyDistributionKeysTable();


  const actionsMenu = (isForOpsCostVal: boolean) => ([{
    label: tl(translations.propertyDistributionKey.table.headers.wkaRelevant),
    onAction: (record: any) => {
      changeWkaRelevantFlagInList(record.accountCode, tableKey, record.economicYear, record.wkaRelevant, record.propertyId);
    },
    actionSupported: (record: any) => !!record.economicPlan && !record.wkaRelevant && !isForOpsCostVal,
  },
  {
    label: tl(translations.propertyDistributionKey.table.headers.notWkaRelevant),
    onAction: (record: any) => {
      changeWkaRelevantFlagInList(record.accountCode, tableKey, record.economicYear, record.wkaRelevant, record.propertyId);
    },
    actionSupported: (record: any) => !!record.economicPlan && record.wkaRelevant && !isForOpsCostVal,
  },
  {
    label: tl(translations.propertyDistributionKey.individualCopyModal.title),
    onAction: onCopy,
    actionSupported: (record: any) => record.id,
  }]);

  const hasOneChildren = (ds: any) => {
    let hasChildren = false;
    ds?.forEach((data: any) => {
      if (data.children) {
        hasChildren = true;
      }
    });
    return hasChildren;
  };

  const propertyDistributionKeysTable = (isForOpsCostValue:boolean) => {
    const rowClassName = !hasOneChildren(dataSource) ? 'no-children' : '';
    const columns = usePropertyDistributionKeyListColumns(isWeg, isForOpsCostValue);

    return {
      tableName: `${tableName}${isForOpsCostValue}`,
      columns,
      rowClassName: () => rowClassName,
      dataSource,
      onRow: (record: any) => {
        if (!record.children) {
          return {
            onClick: () => {
              setSelectedEconomicYear(selectedEconomicYear);
              let pathName = `${location.pathname}/${selectedEconomicYear}/${encodeURIComponent(record.accountCode)}`;
              if (isForOpsCostValue) {
                pathName = `${pathName}?distributionFor=OpsCost`;
              }
              history.push(pathName);
            },
          };
        }
        return {};
      },
      contentLoading: propertyDistributionKeyListState.loading,
      verticallyScrollable: false,
      rowKey: 'accountCode',
      actionsMenu: actionsMenu(isForOpsCostValue),
      virtualize: false,
    };
  };

  /* must do this becauase otherwise the columns will be the same  when the segmented value is changed,
   don't want to change the logic in the useSmartTable */
  const smartTablePropsForOpsCost = useSmartTable(propertyDistributionKeysTable(true));
  const smartTableProps = useSmartTable(propertyDistributionKeysTable(false));

  if (isForOpsCost) {
    return smartTablePropsForOpsCost;
  }

  return smartTableProps;
};
