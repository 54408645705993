import { SectionWrapper } from 'storybook-components/sections/SectionWrapper/SectionWrapper';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import { opsCostTranslation } from 'pages/OpsCostReport/translations';
import './YourSettlementSection.scss';
import { ConfigProvider, Table } from 'antd';
import EmptyTableMultilineMessage from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import { isEmpty } from 'lodash';
import YourSettlementTableSummary from './components/YourSettlementTableSummary';
import { useYourSettlementSection as hook } from './useYourSettlementSection';

type ReturnTypeOfHook = ReturnType<typeof hook>;
type ReturnTypeOfYourSettlementHook = ReturnType<ReturnTypeOfHook['getYourSettlementSectionProps']>

interface YourSettlementSectionProps {
  tl: any,
  openSectionId: number,
  useYourSettlementSection: ReturnTypeOfYourSettlementHook
}


const YourSettlementSection = ({
  tl,
  openSectionId,
  useYourSettlementSection,
}: YourSettlementSectionProps) => {
  const {
    columns,
    settlementDataSource,
    totalSummaryData,
    loading,
  } = useYourSettlementSection;

  // if the table is empty we need to set the height, otherwise the Empty component will not be visible
  const tableHeight = isEmpty(settlementDataSource) ? { y: 200 } : undefined;

  return (
    <SectionWrapper
      sectionId="YourSettlementSection"
      className="YourSettlementSection"
      sectionNumber={1}
      sectionTitle={tl(opsCostTranslation.report.sections.yourSettlementSection.yourSettlementSectionTitle)}
      isOpen={openSectionId === 1}
      sectionDisabled={openSectionId !== 1}
    >
      <ConfigProvider renderEmpty={() => <EmptyTableMultilineMessage description={tl(opsCostTranslation.report.emptyTable.common)} />}>
        <Table
          columns={columns}
          dataSource={settlementDataSource}
          rowKey="label"
          pagination={false}
          scroll={tableHeight}
          loading={{
            indicator: <TableContentLoadingIndicator />,
            spinning: loading,
          }}
          summary={() => (
            <YourSettlementTableSummary
              title={tl(opsCostTranslation.report.sections.yourSettlementSection.rowLabels.total)}
              totalSummaryData={totalSummaryData}
            />
          )}
        />
      </ConfigProvider>
    </SectionWrapper>
  );
};


export default YourSettlementSection;
