import './PortalLink.scss';

import { useContext } from 'react';

import { Tag } from 'antd';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import Button from 'elements/Buttons/Button/Button';
import moment from 'moment';
import {
  INTEGRATION_APPS,
  TICKET_COLUMN_TYPE,
} from 'storybook-components/TicketIntegrationSection/interfaces';

import Icon from '@ant-design/icons';

import { translations } from './translations';

interface PortalLinkProps {
  type: TICKET_COLUMN_TYPE,
  timestamp: Date,
  portal: INTEGRATION_APPS,
  link: string
}

export const PortalLink = ({
  type,
  timestamp,
  portal,
  link,
}: PortalLinkProps) => {
  const { tl } = useContext(LanguageContext);

  const lastSyncDate = timestamp
    ? moment(timestamp).format('DD.MM.YYYY HH:mm:ss') : '—';
  const lastSyncText = `${tl(translations.lastSync)} ${lastSyncDate}`;
  const entity = type === TICKET_COLUMN_TYPE.PROPERTY ? tl(translations.property) : tl(translations.contact[portal]);
  const label = `${portal} ${entity}`;
  return (
    <div className="PortalLink">
      <Tag className="sync-text">
        {lastSyncText}
      </Tag>
      <Button
        target="_blank"
        href={link}
        disabled={!link}
      >
        {label}
        <Icon component={ICONS.openExternal} />
      </Button>
    </div>
  );
};
