import React from 'react';
import './SectionWrapper.scss';


interface SectionProps {
  className?: string,
  sectionNumber?: number,
  sectionTitle?: string,
  sectionSuffix?: React.ReactNode,
  sectionOpenSuffix?: React.ReactNode,
  sectionClosedSuffix?: React.ReactNode,
  sectionId: string,
  children?: React.ReactNode,
  isOpen?: boolean,
  sectionDisabled?: boolean,
  onClick?: (sectionId?: string) => void,
  footer?: React.ReactNode,
  withContentPadding?: boolean,
  sectionIcon?: React.ReactNode,
  /**
   * If false, then the content will be rendered even when the section is closed,
   * but the content will be hidden. True by default.
   */
  destroyOnClose?: boolean,
}

export const SectionWrapper = ({
  className,
  sectionNumber,
  sectionTitle,
  sectionSuffix,
  sectionOpenSuffix,
  sectionClosedSuffix,
  sectionId,
  children,
  isOpen: isOpenProp,
  sectionDisabled,
  onClick: onClickProp,
  footer,
  withContentPadding: withContentPaddingProp,
  sectionIcon,
  destroyOnClose,
}: SectionProps): JSX.Element => {
  const withContentPadding = withContentPaddingProp ?? (sectionNumber !== undefined || sectionIcon !== undefined);
  const isOpen = isOpenProp || !sectionTitle;
  const shouldRenderSectionContent = isOpen || !destroyOnClose;
  const sectionClassName = `SectionWrapper ${sectionDisabled ? 'SectionWrapperDisabled' : ''} ${className}`;
  const sectionWrapperClassName = `section-wrapper-content ${isOpen ? 'section-wrapper-open' : ''}`;
  const sectionContentClassName = `section-content ${
    !sectionTitle ? 'section-content-without-title' : ''
  } ${
    withContentPadding ? 'section-content-with-padding' : ''
  } ${
    !isOpen && shouldRenderSectionContent ? 'section-content-hidden' : ''
  }`;

  const onClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!sectionDisabled) {
      onClickProp?.(sectionId);
    }
  };


  let suffix;
  if (sectionSuffix !== undefined) {
    suffix = sectionSuffix;
  } else if (sectionOpenSuffix && isOpen) {
    suffix = sectionOpenSuffix;
  } else if (sectionClosedSuffix && !isOpen) {
    suffix = sectionClosedSuffix;
  }


  return (
    <div className={sectionClassName} id={sectionId}>
      <div className={sectionWrapperClassName}>

        {sectionTitle && (
          <div className="section-header" onClick={onClickProp && onClick} role="button" tabIndex={0}>
            <div className="section-title-wrapper">
              {sectionNumber !== undefined || sectionIcon !== undefined ? (
                <div className="section-number-wrapper">
                  <div className="section-number">{sectionNumber ?? sectionIcon}</div>
                </div>
              ) : (
                withContentPadding && (<div className="section-number-placeholder" />)
              )}
              <div className="section-title">{sectionTitle}</div>
            </div>
            {suffix}
          </div>
        )}

        {shouldRenderSectionContent && <div className={sectionContentClassName}>{children}</div>}

        {isOpen && footer && (
          <div className="section-footer">
            {footer}
          </div>
        )}
      </div>
    </div>
  );
};

SectionWrapper.defaultProps = {
  className: '',
  children: undefined,
  sectionTitle: undefined,
  sectionNumber: undefined,
  sectionSuffix: undefined,
  sectionOpenSuffix: undefined,
  sectionClosedSuffix: undefined,
  isOpen: true,
  sectionDisabled: false,
  onClick: undefined,
  footer: undefined,
  withContentPadding: undefined,
  sectionIcon: undefined,
  destroyOnClose: true,
};
