import { ContactLegacyDtoTypeEnum } from 'api/accounting';
import { getViewCompanyRoute, getViewPersonRoute } from 'pages/Contacts';
import { getContractEditingRoute, getPropertyEditingRoute, getPropertyOwnerContractEditingRoute } from 'pages/Property/routes';
import { useGetContactById } from 'services/useGetContactById';
import { RecipientDatasourceItem } from '../../interfaces';

export const useContractContactCell = (record: RecipientDatasourceItem) => {
  const { contact } = useGetContactById(record.value?.contactId);

  const urlParams = {
    propertyHrId: record?.propertyHrId,
    unitDbId: record?.unitId,
    contractDbId: record?.contractId,
  };

  const getUrl = () => {
    if (record.children) {
      if (record.unitId) {
        return getContractEditingRoute(urlParams);
      }
      // property owner
      return getPropertyOwnerContractEditingRoute(urlParams);
    }

    if (contact?.type === ContactLegacyDtoTypeEnum.PERSON) {
      return getViewPersonRoute({ personId: contact?.id });
    }

    if (contact?.type === ContactLegacyDtoTypeEnum.COMPANY) {
      return getViewCompanyRoute({ companyId: contact?.id });
    }
    // fallback to proeprty editing
    return getPropertyEditingRoute(record?.propertyHrId);
  };

  const url = getUrl();

  return {
    url,
  };
};
